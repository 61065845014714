/* eslint-disable no-unused-vars */
export enum Availability {
  Disponivel = 'Disponível',
  Indisponivel = 'Indisponível',
}

export enum Status {
  Folga = 'Folga',
  Trabalhando = 'Trabalhando',
  Ferias = 'Férias',
  Afastado = 'Afastado',
  EmTreinamento = 'Em treinamento',
  SemStatus = 'N/A',
}

export enum TrainingType {
  Regulatorio = 'Regulatório',
  Procedimento = 'Procedimento',
}

export enum Realization {
  Formacao = 'Formação',
  Reciclagem = 'Reciclagem',
}

export enum RedmineStatus {
  SolicitacaoAprovada = 'SOLICITAÇÃO APROVADA',
  SolicitacaoEmAnalise = 'SOLICITAÇÃO EM ANÁLISE',
  SolicitacaoRejeitada = 'SOLICITAÇÃO REJEITADA',
  SolicitacaoCancelada = 'SOLICITAÇÃO CANCELADA',
  SolicitacaoExpirada = 'SOLICITAÇÃO EXPIRADA',
}

export const RedmineStatusLabels = [
  { label: 'Aprovada', value: RedmineStatus.SolicitacaoAprovada },
  { label: 'Pendente', value: RedmineStatus.SolicitacaoEmAnalise },
  { label: 'Rejeitada', value: RedmineStatus.SolicitacaoRejeitada },
  { label: 'Cancelada', value: RedmineStatus.SolicitacaoCancelada },
  { label: 'Expirada', value: RedmineStatus.SolicitacaoExpirada }
]

export const ExternalLocation = 'EXTERNO';
