import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { turnstileAccessFilterAtom } from '@/state/TurnstileAccessFilter.atom';
import { removeTimeZone } from '@/utils/formatDate';

import api from '../../services/apiSgft';
import LightTooltip from '../atoms/LightTooltip';

export const TurnstileAccessDownloadButton = () => {
  const [loading, setLoading] = useState(false);
  const filteredData = useRecoilValue(turnstileAccessFilterAtom);

  async function download() {
    setLoading(true);
    try {
      const response = await api.get('/extraction/turnstile-access', {
        params: {
          type: filteredData.type,
          date: removeTimeZone(new Date()),
          management: filteredData.management,
          workstation: filteredData.workstation,
          board: filteredData.board,
          jobTitle: filteredData.jobTitle,
          coordination: filteredData.coordination,
          employee: filteredData.employee,
          training: filteredData.training,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        `Bloqueios de Catraca - SGFT ${format(
          removeTimeZone(new Date()),
          'dd-MM-yyyy',
          { locale: ptBR },
        )}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="cursor-pointer">
      <LightTooltip title="Extrair Bloqueios">
        <IconButton onClick={download} disabled={loading}>
          {loading ? (
            <CgSpinner
              size={30}
              color="#c1c1c1"
              className="h-full animate-spin"
            />
          ) : (
            <MdOutlineFileDownload />
          )}
        </IconButton>
      </LightTooltip>
    </div>
  );
};
