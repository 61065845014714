import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import LightTooltip from "./LightTooltip";
import TableData from "./TableData";

interface TextBoxInterface {
  items: { trainingFileId: number; name: string; trainingPlanningId: number }[];
}

export const TextBox = ({ items }: TextBoxInterface) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex items-center h-12">
      <TooltipProvider skipDelayDuration={100} delayDuration={200}>
        <Tooltip>
          <TooltipTrigger type="button">
            <TableData
              tableValue={"Em treinamento"}
              className="flex h-full max-w-full items-end justify-start overflow-hidden text-ellipsis text-ellipsis whitespace-nowrap text-[12px] font-normal text-[#4A4A4A]"
            ></TableData>
          </TooltipTrigger>
          <TooltipContent className="z-[1000]">
            <TooltipArrow />
            <div className="mt-2 w-full rounded bg-white shadow-lg">
              {items.map((item, index) => (
                <LightTooltip title={item.name} arrow placement="top-end">
                  <div
                    key={index}
                    onClick={() => {
                      navigate(
                        `/home/ficheiros/detalhamento/${item.trainingFileId}`,
                      );
                      searchParams.set("turma", `${item.trainingPlanningId}`);
                      setSearchParams(searchParams);
                    }}
                    className="block max-w-[30rem] cursor-pointer overflow-hidden truncate text-ellipsis px-2 py-1 text-[#2558c6]"
                  >
                    {item.name}
                  </div>
                </LightTooltip>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
