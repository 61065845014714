import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { toast } from 'react-toastify';

import {
  eadAllowedStatuses,
  Modality,
  modalityOptions,
  PartModality,
  partModalityOptions,
  presencialAllowedStatuses,
  StatusTrainingControl,
} from '@/constants/TrainingConstants';
import {
  FormSchema,
  TrainingControlForm,
  UpdateTrainingControlParams,
  verifyFormStatus,
} from '@/constants/TrainingControlConstants';
import api from '@/services/apiSgft';
import { TrainingControl } from '@/types/TrainingControl';
import { TrainingControlItem } from '@/types/TrainingControlGrouped';
import { removeTimeZone } from '@/utils/formatDate';

import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import { Form } from '../ui/form';

interface Props {
  trainingControl: TrainingControl | TrainingControlItem;
  onAbort: () => void;
}

export default function TrainingControlCard({
  trainingControl,
  onAbort,
}: Props) {
  const queryClient = useQueryClient();
  const { trainingModality, onlineCompletionDate, presencialCompletionDate } =
    trainingControl;

  const completionDate =
    trainingModality === Modality.Presencial ||
    trainingModality === Modality.Semipresencial
      ? presencialCompletionDate
      : onlineCompletionDate;

  const form = useForm<TrainingControlForm>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      justification: '',
    },
  });

  const modalityAvailableOptions = modalityOptions.filter((option) =>
    trainingControl.training.modalities.includes(option.value),
  );

  const modalityWatch = form.watch('modality');
  const partModalityWatch = form.watch('partModality');
  const isSemiPresencial = modalityWatch === Modality.Semipresencial;
  const statusesOptions =
    modalityWatch === Modality.Presencial
      ? presencialAllowedStatuses
      : modalityWatch === Modality.Online ||
          modalityWatch === Modality.SomenteLeitura
        ? eadAllowedStatuses
        : modalityWatch === Modality.Semipresencial &&
            partModalityWatch === PartModality.Presencial
          ? presencialAllowedStatuses
          : eadAllowedStatuses;

  const completionStatusWatch = form.watch('completionStatus');
  const dateFieldTitle =
    completionStatusWatch === StatusTrainingControl.NaoAplicavel
      ? 'Data de registro'
      : 'Data de conclusão';
  const shouldShowAlert =
    completionStatusWatch === StatusTrainingControl.NaoAplicavel;
  const shouldShowJustificationField =
    completionStatusWatch === StatusTrainingControl.NaoAplicavel ||
    completionStatusWatch === StatusTrainingControl.Convalidado ||
    completionStatusWatch === StatusTrainingControl.Invalidado;
  const shouldNotShowDateField =
    !completionStatusWatch ||
    completionStatusWatch === StatusTrainingControl.AguardandoLiberacao ||
    completionStatusWatch === StatusTrainingControl.NaoPlanejado;

  const onSubmit = async (data: TrainingControlForm) => {
    const presencialCompletionStatus =
      data.modality === Modality.Presencial ||
      (data.modality === Modality.Semipresencial &&
        data.partModality === PartModality.Presencial)
        ? data.completionStatus
        : undefined;
    const onlineCompletionStatus =
      data.modality === Modality.Online ||
      data.modality === Modality.SomenteLeitura ||
      (data.modality === Modality.Semipresencial &&
        data.partModality === PartModality.Online)
        ? data.completionStatus
        : undefined;
    const presencialCompletionDate =
      data.modality === Modality.Presencial ||
      (data.modality === Modality.Semipresencial &&
        data.partModality === PartModality.Presencial)
        ? data.completionDate
        : undefined;
    const onlineCompletionDate =
      data.modality === Modality.Online ||
      data.modality === Modality.SomenteLeitura ||
      (data.modality === Modality.Semipresencial &&
        data.partModality === PartModality.Online)
        ? data.completionDate
        : undefined;
    const requestData: UpdateTrainingControlParams = {
      trainingModality: data.modality,
      presencialCompletionStatus,
      onlineCompletionStatus,
      presencialCompletionDate: shouldNotShowDateField
        ? undefined
        : presencialCompletionDate?.toISOString().split('T')[0],
      onlineCompletionDate: shouldNotShowDateField
        ? undefined
        : onlineCompletionDate?.toISOString().split('T')[0],
    };

    if (shouldShowJustificationField) {
      requestData.justification = data.justification;
    }

    if (!shouldNotShowDateField) {
      requestData.registerDate = data.registerDate?.toISOString().split('T')[0];
    }

    try {
      await api.patch(`/training-control/${trainingControl.id}`, requestData);
      toast.success('Controle de treinamento atualizado com sucesso!', {
        theme: 'colored',
      });
      queryClient.invalidateQueries({
        queryKey: ['training-control'],
      });
      queryClient.invalidateQueries({
        queryKey: ['employee-trainings'],
      });
    } catch (e: any) {
      const errorMessage =
        e?.response?.status === 400
          ? e?.response?.data?.message
          : 'Não foi possível atualizar o controle!';
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      onAbort();
    }
  };

  const completionDateWatch = form.watch('completionDate');
  const registerDateWatch = form.watch('registerDate');
  const justificationWatch = form.watch('justification');

  const disabled = !verifyFormStatus(
    modalityWatch,
    partModalityWatch,
    completionStatusWatch,
    justificationWatch,
    registerDateWatch,
    completionDateWatch,
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-4">
          <ControlledSimpleSelect
            title="Modalidade"
            required
            name="modality"
            width="16rem"
            values={modalityAvailableOptions}
            control={form.control}
            fieldError={form.formState.errors.modality}
            errorMessage="A modalidade é obrigatória*"
          />
          {isSemiPresencial && (
            <ControlledSimpleSelect
              title="Parte"
              required
              name="partModality"
              width="16rem"
              values={partModalityOptions}
              control={form.control}
            />
          )}

          <div className={isSemiPresencial ? 'col-span-1' : 'col-span-2'}>
            <ControlledSimpleSelect
              title="Situação"
              required
              name="completionStatus"
              width="16rem"
              values={statusesOptions}
              control={form.control}
              fieldError={form.formState.errors.completionStatus}
              errorMessage="A situação é obrigatória*"
            />
          </div>

          {!shouldNotShowDateField && (
            <ControlledCalendar
              title="Data de inscrição"
              required
              name="registerDate"
              width="16rem"
              control={form.control}
              actions={[]}
            />
          )}
          {!shouldNotShowDateField && (
            <ControlledCalendar
              title={dateFieldTitle}
              required
              name="completionDate"
              width="16rem"
              control={form.control}
              minDate={
                completionDate !== '-'
                  ? removeTimeZone(new Date(completionDate))
                  : undefined
              }
              actions={[]}
            />
          )}

          <div className="col-span-3">
            {shouldShowJustificationField && (
              <UncontrolledInputText
                title="Justificativa"
                required
                fullWidth
                {...form.register('justification')}
              />
            )}
          </div>
          <div className="col-span-3">
            {shouldShowAlert && (
              <div className="col-span-4 flex items-center gap-2 font-graphie">
                <GoAlertFill size={20} color="#FEDD00" />
                <span className="text-xs/none">
                  Ao alterar a situação para Não Aplicável, a linha será
                  removida da tela de controle de treinamentos.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 flex justify-between">
          <Button
            type="button"
            className="mr-5 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
            onClick={() => onAbort()}
          >
            Cancelar
          </Button>
          <Button type="submit" className="mb-5 min-w-36" disabled={disabled}>
            Salvar
          </Button>
        </div>
      </form>
    </Form>
  );
}
