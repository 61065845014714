import { MouseEvent, useState } from 'react';
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { JobTitle } from '@/types/JobTitle';
import { removeTimeZone } from '@/utils/formatDate';

import { ServiceOrderModal } from '../molecules/ServiceOrderModal';
import JobTitleHistoryModal from '../organisms/JobTitleHistoryModal';
import JobTitleModal from '../organisms/JobTitleModal';
import LightTooltip from './LightTooltip';
import SimpleMenu from './Menu';
import TableCellDate from './TableCellDate';

interface JobTitleLineProps {
  jobTitle: JobTitle;
  exportChecked?: boolean;
  selectedJobTitleIds: number[];
  setSelectedJobTitleIds: (ids: number[]) => void;
  selectedAllChecked?: boolean;
  setSelectedAllChecked: (checked: boolean) => void;
}

export const JobTitleLine = ({
  jobTitle,
  exportChecked,
  selectedJobTitleIds,
  setSelectedJobTitleIds,
  selectedAllChecked,
  setSelectedAllChecked,
}: JobTitleLineProps) => {
  const [isServiceOrderModal, setIsServiceOrderModal] = useState(false);
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [isJobTitleHistoryModalOpen, setIsJobTitleHistoryModalOpen] =
    useState(false);
  const isChecked =
    selectedJobTitleIds.includes(jobTitle.id) || selectedAllChecked;

  const handleCheckboxChange = () => {
    if (isChecked) {
      setSelectedJobTitleIds(
        selectedJobTitleIds.filter((id) => id !== jobTitle.id),
      );

      if (selectedAllChecked) {
        setSelectedAllChecked(false);
      }
    } else {
      setSelectedJobTitleIds([...selectedJobTitleIds, jobTitle.id]);
    }
  };
  const handleOpenServiceOrderModal = () => {
    setIsServiceOrderModal(true);
  };
  const handleCloseServiceOrderModal = () => {
    setIsServiceOrderModal(false);
  };
  const handlePrint = async (jobDescription: JobTitle) => {
    try {
      const response = await api.post(
        'job-title/pdf-generation',
        { jobTitleId: jobDescription.id },
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName =
        jobTitle.seniorityLevel.name && jobTitle.seniorityLevel.name !== 'N/A'
          ? `Descrição de Cargo[${jobTitle.id}] - ${jobTitle.generalInformationJobTitle.name.toUpperCase()} ${jobTitle.seniorityLevel.name.toUpperCase()}`
          : `Descrição de Cargo[${jobTitle.id}] - ${jobTitle.generalInformationJobTitle.name.toUpperCase()}`;

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch {
      toast.error('Não foi possível efetuar o download', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const options = [
    {
      name: 'Consultar Versões',
      onClick: () => {
        setIsJobTitleHistoryModalOpen(true);
      },
      disabled: false,
    },
    {
      name: 'Exportar Descrição do Cargo',
      onClick: () => {
        handlePrint(jobTitle);
      },
      disabled: false,
    },
    {
      name: 'Exportar Ordem de Serviço',
      onClick: handleOpenServiceOrderModal,
      disabled: false,
    },
  ];

  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-[#F5F7FF] px-2 py-1 transition-all duration-500 ease-in-out">
      <div className="flex w-full items-center gap-2">
        {exportChecked && (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="h-4 w-4 cursor-pointer accent-primary"
          />
        )}
        <LightTooltip
          title={`${jobTitle?.generalInformationJobTitle?.name} ${jobTitle?.seniorityLevel?.name}`}
          open={tooltipEnabled}
          onClose={() => setTooltipEnabled(false)}
          arrow
          placement="top-end"
        >
          <div
            className="cursor-pointer overflow-hidden text-ellipsis text-[14px] font-medium uppercase text-[#4a4a4a]"
            onMouseEnter={handleShouldShow}
            onClick={() => setIsJobTitleModalOpen(true)}
          >
            <p className="text-primary underline">
              {`${jobTitle?.generalInformationJobTitle?.name} ${
                jobTitle?.seniorityLevel?.name &&
                jobTitle?.seniorityLevel?.name !== 'N/A'
                  ? jobTitle?.seniorityLevel?.name
                  : ''
              }`}
            </p>
          </div>
        </LightTooltip>
      </div>

      {!jobTitle.active && (
        <div className="ml-auto w-[4rem] rounded-xl  bg-[#d9d9d9] p-2 text-center text-[12px] font-medium	text-[#5a5a5a]">
          INATIVO
        </div>
      )}

      <div className="flex w-[40%] items-center justify-end text-[11px] text-[#848484]">
        <div className="font-medium">
          Versão: {jobTitle.generalInformationJobTitle?.version}
        </div>
        <div className="mx-2">●</div>
        <div className="mr-1">Última edição:</div>
        <div className="mr-1 font-medium">
          <TableCellDate
            className="text-[10px] font-normal text-[#848484]"
            date={removeTimeZone(new Date(jobTitle.updatedAt))}
          />
        </div>
        <SimpleMenu
          options={options}
          upDotsButton
          upDotsButtonColor="action"
          disabled={!jobTitle.generalInformationJobTitle.active}
        />
      </div>

      {isJobTitleModalOpen && (
        <JobTitleModal
          modalIsOpen={isJobTitleModalOpen}
          handleClose={() => setIsJobTitleModalOpen(false)}
          jobTitle={jobTitle}
        />
      )}

      {isJobTitleHistoryModalOpen && (
        <JobTitleHistoryModal
          jobTitle={jobTitle}
          modalIsOpen={isJobTitleHistoryModalOpen}
          handleClose={() => setIsJobTitleHistoryModalOpen(false)}
        />
      )}

      {isServiceOrderModal && (
        <ServiceOrderModal
          isOpen={isServiceOrderModal}
          handleClose={handleCloseServiceOrderModal}
          jobTitle={jobTitle}
        />
      )}
    </div>
  );
};
