import { ChangeEvent, memo, useCallback, useMemo, useRef } from 'react';
import { BsClipboard2CheckFill, BsLockFill } from 'react-icons/bs';

import { ProcedureTrainingMatrix } from '@/types/Training';

import Check from '../../assets/icons/check.svg';
import Checkbox from '../atoms/Checkbox';
import LightTooltip from '../atoms/LightTooltip';

interface ProcedureTrainingMatrixCellProps {
  trainingId: string;
  isEditMode: boolean;
  rowIndex: number;
  isCritical: boolean;
  totalWorkload: number | null;
  trainingsMatrixData: ProcedureTrainingMatrix;
  modifiedCheckboxes: Map<string, boolean>;
  canSave?: boolean;
  isResponsible?: boolean;
  onChangeCallback: (checked: boolean) => void;
  positionGap: number;
  jobTitleId: number;
  coordinationId: number;
  workStationId: number;
  modifiedBlockCheckboxes: Map<string, boolean>;
  onBlockChangeCallback: (checked: boolean) => void;
}

// eslint-disable-next-line react/display-name
const ProcedureTrainingMatrixCell = memo(
  ({
    isEditMode,
    rowIndex,
    trainingId,
    totalWorkload,
    isCritical,
    trainingsMatrixData,
    modifiedCheckboxes,
    canSave,
    isResponsible,
    onChangeCallback,
    positionGap,
    jobTitleId,
    coordinationId,
    workStationId,
    modifiedBlockCheckboxes,
    onBlockChangeCallback,
  }: ProcedureTrainingMatrixCellProps) => {
    const checkboxRef = useRef(null);
    const blockCheckboxRef = useRef(null);
    const positionIndex = rowIndex - positionGap;

    const isChecked = useMemo(() => {
      if (
        positionIndex >= 0 &&
        trainingsMatrixData?.matrix &&
        trainingsMatrixData?.matrix.length > positionIndex
      ) {
        const key = `${jobTitleId}-${coordinationId}-${workStationId}-${trainingId}`;
        if (modifiedCheckboxes.has(key)) {
          return modifiedCheckboxes.get(key) || false;
        }
        return trainingsMatrixData?.matrix[positionIndex].trainingChecks[
          trainingId
        ];
      }
      return true;
    }, [
      modifiedCheckboxes,
      trainingId,
      trainingsMatrixData?.matrix,
      positionIndex,
      jobTitleId,
      coordinationId,
      workStationId,
    ]);

    const isBlockChecked = useMemo(() => {
      if (
        positionIndex >= 0 &&
        trainingsMatrixData?.matrix &&
        trainingsMatrixData?.matrix.length > positionIndex
      ) {
        const key = `${jobTitleId}-${coordinationId}-${workStationId}-${trainingId}`;
        if (modifiedBlockCheckboxes.has(key)) {
          return modifiedBlockCheckboxes.get(key) || false;
        }
        return trainingsMatrixData?.matrix[positionIndex].blockChecks[
          trainingId
        ];
      }
      return true;
    }, [
      modifiedBlockCheckboxes,
      trainingId,
      trainingsMatrixData?.matrix,
      positionIndex,
      jobTitleId,
      coordinationId,
      workStationId,
    ]);

    const handleCheckboxChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        onChangeCallback(event.target?.checked);
      },
      [onChangeCallback],
    );

    const handleBlockCheckboxChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        onBlockChangeCallback(event.target?.checked);
      },
      [onBlockChangeCallback],
    );

    if (rowIndex === 0) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${isCritical ? 'SIM' : 'NÃO'}`}</div>
        </div>
      );
    } else if (rowIndex === 1) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${totalWorkload || ''}`}</div>
        </div>
      );
    } else if (rowIndex === 2) {
      return <div></div>;
    } else {
      return (
        <div className="flex items-center justify-center gap-4">
          {isEditMode ? (
            <div className="flex items-center gap-4">
              <div className="flex min-w-[64px] items-center justify-center gap-2">
                <Checkbox
                  className={`${isBlockChecked && 'cursor-not-allowed opacity-50'}`}
                  checked={isChecked}
                  defaultChecked={isChecked}
                  ref={checkboxRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleCheckboxChange(e);
                  }}
                  disabled={!canSave && !isResponsible && isBlockChecked}
                  size="large"
                  variant="blue"
                />
                <BsClipboard2CheckFill size={18} color="#00d692" />
              </div>

              <div className="flex min-w-[64px] items-center justify-center gap-2">
                <Checkbox
                  className={`${!isChecked && 'cursor-not-allowed opacity-50'}`}
                  checked={isBlockChecked}
                  defaultChecked={isBlockChecked}
                  ref={blockCheckboxRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleBlockCheckboxChange(e);
                  }}
                  disabled={!canSave && !isResponsible && !isChecked}
                  size="large"
                  variant="blue"
                />
                <LightTooltip
                  title="Sem este treinamento, o acesso pela catraca será bloqueado."
                  arrow
                >
                  <div>
                    <BsLockFill size={18} color="#f4503a" />
                  </div>
                </LightTooltip>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <div className="flex min-w-[64px] items-center justify-center gap-2">
                {isChecked && (
                  <>
                    <img src={Check} className="size-6 flex-none" />
                    <BsClipboard2CheckFill size={18} color="#00d692" />
                  </>
                )}
              </div>

              <div className="flex min-w-[64px] items-center justify-center gap-2">
                {isBlockChecked && (
                  <>
                    <img src={Check} className="size-6 flex-none" />
                    <BsLockFill size={18} color="#f4503a" />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  },
);

export default ProcedureTrainingMatrixCell;

