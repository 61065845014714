import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface ProcedureTrainingFilters {
  jobTitleIds: number[];
  trainingNames: string[];
  workStationIds: number[];
  coordinationIds: number[];
  management: string[];
  locations: string[];
}

const numberArrayChecker = r.array(r.number()) as r.Checker<number[]>;
const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

const procedureTrainingFiltersAtom = atom<ProcedureTrainingFilters>({
  key: 'procedureTrainingFilters',
  default: {
    jobTitleIds: [],
    trainingNames: [],
    workStationIds: [],
    coordinationIds: [],
    management: [],
    locations: [],
  },

  effects: [
    urlSyncEffect<ProcedureTrainingFilters>({
      refine: r.object({
        jobTitleIds: numberArrayChecker,
        trainingNames: stringArrayChecker,
        workStationIds: numberArrayChecker,
        coordinationIds: numberArrayChecker,
        management: stringArrayChecker,
        locations: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});

export { procedureTrainingFiltersAtom };
