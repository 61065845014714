/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { queryClient } from '@/App';
import { RequirementCategoryEnum } from '@/constants/enums/RequirementCategoryEnum';
import { RequirementClassEnum } from '@/constants/enums/RequirementClassEnum';
import {
  EducationalRequirementsSchema,
  JobTitleFormSchema,
  OtherRequirementsSchema,
  ProfessionalRequirementsSchema,
} from '@/constants/formSchema/JobtitleFormSchema';
import api from '@/services/apiSgft';
import { GroupedJobTitle, JobTitle } from '@/types/JobTitle';
import { GeneralInformationJobTitleCreationData } from '@/types/JobTitleCreationData';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import SearchSelect from '../atoms/SearchSelect';
import Spinner from '../atoms/Spinner';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import { RegisterJobTitle } from './RegisterJobTitle';

interface RegisterGeneralInformationJobTitleModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: GroupedJobTitle;
  type: 'create' | 'edit';
}

const getRequirements = (jobTitle: JobTitle) => {
  const filterRequirements = (
    requirementClass: RequirementClassEnum,
    category: RequirementCategoryEnum,
  ) =>
    jobTitle.jobTitleRequirements
      .filter(
        (it) =>
          it.requirementClass.name === requirementClass &&
          it.requirement.requirementCategory.name === category,
      )
      .map((it) => it.requirement.name);

  const categories = {
    educationalRequirements: [
      RequirementCategoryEnum.undergraduate,
      RequirementCategoryEnum.levelOfEducation,
      RequirementCategoryEnum.postgraduate,
      RequirementCategoryEnum.technicalEducation,
    ],
    professionalRequirements: [
      RequirementCategoryEnum.softwares,
      RequirementCategoryEnum.activeProfessionalRecords,
      RequirementCategoryEnum.knowledge,
      RequirementCategoryEnum.otherCourses,
      RequirementCategoryEnum.certifications,
    ],
    otherRequirements: [
      RequirementCategoryEnum.languages,
      RequirementCategoryEnum.other,
    ],
  };

  const categoryKeys = Object.keys(RequirementCategoryEnum).reduce(
    (acc, key) => {
      const value =
        RequirementCategoryEnum[key as keyof typeof RequirementCategoryEnum];
      acc[value] = key;
      return acc;
    },
    {} as Record<string, string>,
  );

  const buildRequirements = (requirementClass: RequirementClassEnum) => {
    const result: Record<string, Record<string, string[]>> = {};

    Object.entries(categories).forEach(([key, subcategories]) => {
      result[key] = subcategories.reduce(
        (acc, subcategory) => {
          const subcategoryKey = categoryKeys[subcategory] || subcategory;
          acc[subcategoryKey] = filterRequirements(
            requirementClass,
            subcategory,
          );
          return acc;
        },
        {} as Record<string, string[]>,
      );
    });

    return result;
  };

  return {
    mandatory: buildRequirements(RequirementClassEnum.MANDATORY),
    optional: buildRequirements(RequirementClassEnum.OPTIONAL),
  };
};

export const RegisterGeneralInformationJobTitleModal: React.FC<
  RegisterGeneralInformationJobTitleModalProps
> = ({ isOpen, onClose, data, type }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [cboSearch, setCboSearch] = useState('');
  const methods = useForm<z.infer<typeof JobTitleFormSchema>>({
    ...(type === 'edit' && {
      defaultValues: {
        company:
          data?.managements.map(
            (management) => management.board.company.name,
          ) ?? [],
        title: data?.title ?? '',
        workStations:
          data?.workStations.map(
            (workStation) => workStation.workStation.name,
          ) ?? [],
        summary: data?.summary ?? '',
        observations: data?.observations ?? '',
        levels:
          data?.jobTitles.map((jobTitle) => ({
            id: jobTitle.id,
            level: jobTitle.seniorityLevel.name,
            experience: jobTitle.experience,
            objective: jobTitle.objective,
            rolesAndResponsibilities: jobTitle.responsibilities,
            skillsAndCompetencies: jobTitle.skillsAndCompetencies,
            requirements: getRequirements(jobTitle),
          })) ?? [],
        origemBoard:
          data?.managements
            .filter(
              (management) =>
                management.board.company.name === 'ORIGEM ENERGIA',
            )
            .map((management) => management.board.name) ?? [],
        origemManagement:
          data?.managements
            .filter(
              (management) =>
                management.board.company.name === 'ORIGEM ENERGIA',
            )
            .map((management) => management.name) ?? [],

        tamacBoard:
          data?.managements
            .filter((management) => management.board.company.name === 'TAMAC')
            .map((management) => management.board.name) ?? [],

        tamacManagement:
          data?.managements
            .filter((management) => management.board.company.name === 'TAMAC')
            .map((management) => management.name) ?? [],
        cbo: data?.cbo,
      },
    }),
  });
  const parseRequirementsByLevel = (
    data: z.infer<typeof JobTitleFormSchema>['levels'],
  ) => {
    const requirements = {
      mandatory: {
        educationalRequirements: [] as z.infer<
          typeof EducationalRequirementsSchema
        >[],
        professionalRequirements: [] as z.infer<
          typeof ProfessionalRequirementsSchema
        >[],
        otherRequirements: [] as z.infer<typeof OtherRequirementsSchema>[],
      },
      optional: {
        educationalRequirements: [] as z.infer<
          typeof EducationalRequirementsSchema
        >[],
        professionalRequirements: [] as z.infer<
          typeof ProfessionalRequirementsSchema
        >[],
        otherRequirements: [] as z.infer<typeof OtherRequirementsSchema>[],
      },
    };
    for (const level of data) {
      level.requirements.mandatory.educationalRequirements.level = level.level;
      level.requirements.mandatory.professionalRequirements.level = level.level;
      level.requirements.mandatory.otherRequirements.level = level.level;
      level.requirements.optional.educationalRequirements.level = level.level;
      level.requirements.optional.professionalRequirements.level = level.level;
      level.requirements.optional.otherRequirements.level = level.level;
    }
    for (const level of data) {
      requirements.mandatory.educationalRequirements.push(
        level.requirements.mandatory.educationalRequirements,
      );
      requirements.mandatory.professionalRequirements.push(
        level.requirements.mandatory.professionalRequirements,
      );
      requirements.mandatory.otherRequirements.push(
        level.requirements.mandatory.otherRequirements,
      );
      requirements.optional.educationalRequirements.push(
        level.requirements.optional.educationalRequirements,
      );
      requirements.optional.professionalRequirements.push(
        level.requirements.optional.professionalRequirements,
      );
      requirements.optional.otherRequirements.push(
        level.requirements.optional.otherRequirements,
      );
    }
    return requirements;
  };

  const onSubmit = async (formData: z.infer<typeof JobTitleFormSchema>) => {
    try {
      setIsSubmiting(true);
      const {
        origemBoard,
        tamacBoard,
        origemManagement,
        tamacManagement,
        ...remainingData
      } = formData;

      const board = [...(tamacBoard ?? []), ...(origemBoard ?? [])];
      const management = [
        ...(tamacManagement ?? []),
        ...(origemManagement ?? []),
      ];
      const levels = methods.getValues('levels'); // TODO - understand why it's not working with formData.levels
      const submitData = {
        ...remainingData,
        levels: levels.map(({ requirements, ...levelData }) => ({
          ...levelData,
          // level: level.level,
        })),
        requirements: parseRequirementsByLevel(levels),
        board,
        management,
        workStations: formData.workStations.map((workStation) => {
          const previousWorkStation = data?.workStations.find(
            (ws) => ws.workStation.name === workStation,
          );

          return {
            workStation,
            gheCode: previousWorkStation?.gheCode ?? null,
            epi: previousWorkStation?.epi ?? null,
            risks: previousWorkStation?.risks ?? null,
          };
        }),
      };
      if (type === 'create') {
        const response = await api.post('/job-title', submitData);
        toast.success(response.data, {
          theme: 'colored',
          toastId: 'success',
        });
      } else if (type === 'edit') {
        const response = await api.put(
          `/job-title/update/${data?.id}`,
          submitData,
        );
        toast.success(response.data, {
          theme: 'colored',
          toastId: 'success',
        });
      }
      queryClient.invalidateQueries(['jobTitle']);
      queryClient.invalidateQueries(['jobTitlesCount']);
      closeAndClear();
    } catch (error: any) {
      let errorMessage = `Erro ao ${type === 'create' ? 'criar' : type === 'edit' ? 'editar' : 'excluir'} cargo, tente novamente mais tarde!`;
      if (error.response.status !== 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    } finally {
      setIsSubmiting(false);
    }
  };

  const closeAndClear = () => {
    methods.reset();
    onClose();
  };

  const getCreationData = async () => {
    try {
      const response = await api.get<GeneralInformationJobTitleCreationData>(
        '/job-title/general-information-job-titles-creation-data',
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const getCbo = async () => {
    try {
      const response = await api.get('/job-title/cbo', {
        params: {
          search: cboSearch,
          size: 10,
          page: 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const { data: creationData, isLoading: isLoadingCompanies } = useQuery<
    GeneralInformationJobTitleCreationData | undefined
  >(['get-job-title-creation-data'], getCreationData);

  const { data: cboData } = useQuery(['cbo', cboSearch], getCbo);

  const companiesOptions =
    creationData?.companies.map((company) => ({
      value: company.name,
      label: company.name,
    })) ?? [];
  const companyWatch = methods.watch('company');
  const tamacBoardsWatch = methods.watch('tamacBoard');
  const origemBoardsWatch = methods.watch('origemBoard');

  const showTamacOptions = companyWatch?.includes('TAMAC');
  const showOrigemOptions = companyWatch?.includes('ORIGEM ENERGIA');
  const tamacBoardsOptions =
    creationData?.boards
      .filter((board) => board.company.name === 'TAMAC')
      .map((board) => ({
        value: board.name,
        label: board.name,
      })) ?? [];
  const origemBoardsOptions =
    creationData?.boards
      .filter((board) => board.company.name === 'ORIGEM ENERGIA')
      .map((board) => ({
        value: board.name,
        label: board.name,
      })) ?? [];

  const origemManagementsOptions =
    creationData?.managements
      .filter(
        (management) =>
          origemBoardsWatch?.includes(management.board.name) &&
          management.board.company.name === 'ORIGEM ENERGIA',
      )
      .map((management) => ({
        value: management.name,
        label: management.name,
      })) ?? [];
  const tamacManagementsOptions =
    creationData?.managements
      .filter(
        (management) =>
          tamacBoardsWatch?.includes(management.board.name) &&
          management.board.company.name === 'TAMAC',
      )
      .map((management) => ({
        value: management.name,
        label: management.name,
      })) ?? [];
  const workStationsOptions =
    creationData?.workStations.map((workStation) => ({
      value: workStation.name,
      label: workStation.name,
    })) ?? [];

  const cbosOptions =
    cboData?.map((cbo: { name: string }) => ({
      value: cbo.name,
      label: cbo.name,
    })) ?? [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={`${type === 'create' ? 'Criar novo cargo' : 'Editar cargo'}`}
      className="w-[64rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoadingCompanies ? (
            <div className="flex h-[19rem] w-full justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="w-full">
              <div className="mb-4 flex w-full justify-between">
                <UncontrolledInputText
                  title="Título do cargo"
                  required
                  width="61rem"
                  {...methods.register('title')}
                  upperCase
                />
              </div>
              <div className="mb-4 flex w-full gap-2">
                <ControlledSimpleSelect
                  title={'Empresa'}
                  required
                  control={methods.control}
                  name={`company`}
                  width="30rem"
                  values={companiesOptions}
                  multiple
                />
                <SearchSelect
                  {...methods.register('cbo')}
                  control={methods.control}
                  title="CBO"
                  options={cbosOptions}
                  required
                  className="w-[30rem]"
                  setSearch={(e) => setCboSearch(e)}
                />
              </div>
              {showOrigemOptions && (
                <div className="mb-4 flex w-full gap-2">
                  <ControlledSimpleSelect
                    title={'Diretoria - Origem Energia'}
                    required
                    control={methods.control}
                    name={`origemBoard`}
                    width="30rem"
                    values={origemBoardsOptions}
                    multiple
                  />
                  <ControlledSimpleSelect
                    title={'Gerência - Origem Energia'}
                    required
                    control={methods.control}
                    name={'origemManagement'}
                    width="30rem"
                    values={origemManagementsOptions}
                    multiple
                  />
                </div>
              )}
              {showTamacOptions && (
                <div className="mb-4 flex w-full gap-2">
                  <ControlledSimpleSelect
                    title={'Diretoria - TAMAC'}
                    required
                    control={methods.control}
                    name={`tamacBoard`}
                    width="30rem"
                    values={tamacBoardsOptions}
                    multiple
                  />
                  <ControlledSimpleSelect
                    title={'Gerência - TAMAC'}
                    required
                    control={methods.control}
                    name={'tamacManagement'}
                    width="30rem"
                    values={tamacManagementsOptions}
                    multiple
                  />
                </div>
              )}
              <div className="mb-4 flex w-full justify-between">
                <ControlledSimpleSelect
                  title={'Local de Trabalho'}
                  required
                  control={methods.control}
                  name={`workStations`}
                  width="61rem"
                  values={workStationsOptions}
                  multiple
                />
              </div>
              <div className="mb-4 flex w-full justify-between">
                <UncontrolledInputText
                  title="Resumo do cargo"
                  width="61rem"
                  {...methods.register('summary')}
                />
              </div>
              <div className="mb-4 flex w-full justify-between">
                <UncontrolledInputText
                  title="Observação para Ordem de Serviço"
                  width="61rem"
                  {...methods.register('observations')}
                />
              </div>
              {type === 'create' && (
                <div className="flex w-[90%] justify-between">
                  <div className="2 flex items-center gap-4 text-[12px] text-[#4A4A4A]">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        title="Criar cargo somente para a Estrutura Organizacional"
                        value="structureCreation"
                        defaultChecked
                        {...methods.register('creationType')}
                      />
                      <span className="ml-2">
                        Criar cargo somente para a Estrutura Organizacional
                      </span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value="sendToQsms"
                        {...methods.register('creationType')}
                      />
                      <span className="ml-2">
                        Enviar cargo para avaliação de GHE por QSMS
                      </span>
                    </label>
                  </div>
                </div>
              )}
              <RegisterJobTitle methods={methods} creationData={creationData} />
              <div className="my-2 mt-[1.5rem] flex w-full justify-between">
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className=" h-8 min-w-36"
                  isLoading={isSubmiting}
                >
                  Salvar
                </Button>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};
