import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { SyntheticEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import BasicTabs from '@/components/molecules/BasicTabs';
import EmployeeSearchCard from '@/components/molecules/EmployeeSearchCard';
import NewSideFilter from '@/components/molecules/NewSideFilter';
import { TurnstileAccessDownloadButton } from '@/components/molecules/TurnstileAccessDownloadButton';
import TurnstileAccessTab from '@/components/organisms/TurnstileAccessTab';
import { filters } from '@/constants/filters/TurnstileAccessFilter';
import {
  TurnstileAccessFilterOptions,
  TurnstileAccessFilterOptionsSchema,
  TurnstileAccessResponseSchema,
  TurnstileAccessType,
} from '@/constants/TurnstileAccessConstants';
import api from '@/services/apiSgft';
import { turnstileAccessFilterAtom } from '@/state/TurnstileAccessFilter.atom';
import { removeTimeZone } from '@/utils/formatDate';
import { isReadOnlyUser } from '@/utils/handleSavePermissions';

const TurnstileAccess = () => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useRecoilState(
    turnstileAccessFilterAtom,
  );

  const fetchTurnstileAccessFilters = async () => {
    try {
      const response = await api.get('turnstile-access/filters', {
        params: {
          type: filteredData.type,
          date: removeTimeZone(new Date()),
          management: filteredData.management,
          workstation: filteredData.workstation,
          board: filteredData.board,
          jobTitle: filteredData.jobTitle,
          coordination: filteredData.coordination,
          employee: filteredData.employee,
          training: filteredData.training,
        },
      });
      const parsedData = TurnstileAccessFilterOptionsSchema.parse(
        response.data,
      );
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: filterOptions, isLoading: isLoadingFilters } =
    useQuery<TurnstileAccessFilterOptions>(
      ['turnstile-access-filters', filteredData],
      fetchTurnstileAccessFilters,
    );

  const fetchTurnstileAccess = async ({ pageParam = 1 }) => {
    try {
      const response = await api.get('turnstile-access', {
        params: {
          type: TurnstileAccessType.Blocked,
          page: pageParam,
          size: 10,
          date: removeTimeZone(new Date()),
          search: searchInput,
          management: filteredData.management,
          workstation: filteredData.workstation,
          board: filteredData.board,
          jobTitle: filteredData.jobTitle,
          coordination: filteredData.coordination,
          employee: filteredData.employee,
          training: filteredData.training,
        },
      });
      const parsedData = TurnstileAccessResponseSchema.parse(response.data);
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const fetchInRiskTurnstileAccess = async ({ pageParam = 1 }) => {
    try {
      const response = await api.get('turnstile-access', {
        params: {
          type: TurnstileAccessType.InRisk,
          page: pageParam,
          size: 10,
          date: removeTimeZone(new Date()),
          search: searchInput,
          management: filteredData.management,
          workstation: filteredData.workstation,
          board: filteredData.board,
          jobTitle: filteredData.jobTitle,
          coordination: filteredData.coordination,
          employee: filteredData.employee,
          training: filteredData.training,
        },
      });
      const parsedData = TurnstileAccessResponseSchema.parse(response.data);
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data: blockedData,
    isLoading: isLoadingBlocked,
    fetchNextPage: fetchNextPageBlocked,
    isFetchingNextPage: isFetchingNextPageBlocked,
    hasNextPage: hasNextPageBlocked,
  } = useInfiniteQuery(
    ['blocked-turnstile-access', filteredData, searchInput],
    fetchTurnstileAccess,
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );

  const {
    data: inRiskData,
    isLoading: isLoadingInRisk,
    fetchNextPage: fetchNextPageInRisk,
    isFetchingNextPage: isFetchingNextPageInRisk,
    hasNextPage: hasNextPageInRisk,
  } = useInfiniteQuery(
    ['in-risk-turnstile-access', filteredData, searchInput],
    fetchInRiskTurnstileAccess,
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );

  const blockedEmployees = blockedData?.pages.flatMap((page) =>
    page?.turnstileAccess?.employees?.flat(),
  );
  const blockedCount = blockedData?.pages[0]?.count || 0;

  const inRiskEmployees = inRiskData?.pages.flatMap((page) =>
    page?.turnstileAccess?.employees?.flat(),
  );
  const inRiskCount = inRiskData?.pages[0]?.count || 0;

  const handleInputChange = (name: string) => {
    setSearchInput(name);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setFilteredData({
        ...filteredData,
        type: TurnstileAccessType.Blocked,
      });
    } else {
      setFilteredData({
        ...filteredData,
        type: TurnstileAccessType.InRisk,
      });
    }
  };

  if (isReadOnlyUser()) return <NoPermissionText />;

  return (
    <div className="relative flex h-full max-h-screen w-full flex-col items-start">
      <div className="absolute -top-10 right-20 flex items-center">
        <TurnstileAccessDownloadButton />
      </div>
      <NewSideFilter
        filters={filters}
        atom={turnstileAccessFilterAtom}
        options={filterOptions}
        isLoading={isLoadingFilters}
      />
      <div className="max-h-[85vh] w-full">
        <BasicTabs
          initialTab={filteredData.type === TurnstileAccessType.Blocked ? 0 : 1}
          background="transparent"
          tabsNames={['Bloqueados no Polo', 'Em risco de bloqueio no polo']}
          onChange={handleTabChange}
          tabsCounts={[blockedCount, inRiskCount]}
          tabs={[
            <div key={0} className="flex w-full min-w-min flex-col gap-2 pt-2">
              <EmployeeSearchCard
                searchInput={searchInput}
                handleInputChange={handleInputChange}
              />
              <TurnstileAccessTab
                isLoading={isLoadingBlocked}
                employees={blockedEmployees}
                isFetchingNextPage={isFetchingNextPageBlocked}
                hasNextPage={hasNextPageBlocked}
                fetchNextPage={fetchNextPageBlocked}
              />
            </div>,
            <div key={1} className="flex w-full min-w-min flex-col gap-2 pt-2">
              <EmployeeSearchCard
                searchInput={searchInput}
                handleInputChange={handleInputChange}
              />
              <TurnstileAccessTab
                isLoading={isLoadingInRisk}
                employees={inRiskEmployees}
                isFetchingNextPage={isFetchingNextPageInRisk}
                hasNextPage={hasNextPageInRisk}
                fetchNextPage={fetchNextPageInRisk}
              />
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

export default TurnstileAccess;
