/* eslint-disable array-callback-return */
import { ProcedureTrainingFilters } from '@/state/ProcedureTraining.atom';
import { Filter } from '@/types/Filter';

import api from '../../services/apiSgft';
import { TrainingTypeEnum } from '../trainingTypeOptions';

const fetchProcedureFilter = async (
  field: string,
  search: string,
  atom?: ProcedureTrainingFilters,
  positionType?: string,
) => {
  const response = await api.get(`${field}`, {
    params: {
      search,
      size: 10000,
      page: 1,
      locations: atom?.locations,
      positions: atom?.jobTitleIds,
      trainings: atom?.trainingNames,
      trainingType: [TrainingTypeEnum.PROCEDURE],
      management: positionType === 'management' ? [] : atom?.management,
      areaCoordination: atom?.coordinationIds,
      workstation: atom?.workStationIds,
      getAll: false,
    },
  });

  if (positionType === 'locations') {
    return [
      ...new Set(
        response.data.map((item: { locations: string }) =>
          JSON.stringify({
            label:
              item.locations === 'TAMAC'
                ? 'Terminal Aquaviáro de Maceió (TAMAC)'
                : item.locations,
            value: item.locations,
          }),
        ),
      ),
    ].map(
      (item) => JSON.parse(item as string) as { label: string; value: number },
    );
  }
  if (positionType === 'management') {
    const uniqueValuesSet = new Set(
      response.data.map((it: { name: string }) => it.name),
    );
    return Array.from(uniqueValuesSet).map((management) => ({
      label: management,
      value: management,
    }));
  }
  if (positionType === 'areaCoordination') {
    return [
      ...new Set(
        response.data.map(
          (item: { coordination: { id: number; name: string } }) =>
            JSON.stringify({
              label: item.coordination.name,
              value: item.coordination.id,
            }),
        ),
      ),
    ].map(
      (item) => JSON.parse(item as string) as { label: string; value: number },
    );
  }
  if (positionType === 'workstation') {
    return [
      ...new Set(
        response.data.map(
          (item: { workStation: { id: number; name: string } }) =>
            JSON.stringify({
              label: item.workStation.name,
              value: item.workStation.id,
            }),
        ),
      ),
    ].map(
      (item) => JSON.parse(item as string) as { label: string; value: number },
    );
  }
  if (positionType === 'position') {
    return [
      ...new Set(
        response.data.map((item: { jobTitle: { id: number; name: string } }) =>
          JSON.stringify({
            label: item.jobTitle.name,
            value: item.jobTitle.id,
          }),
        ),
      ),
    ].map(
      (item) => JSON.parse(item as string) as { label: string; value: number },
    );
  }

  return response.data
    .filter((it: { name: string }) => it.name !== 'TAMAC')
    .map((it: { name: string }) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
};

export const procedureFilters: Filter[] = [
  {
    key: 'locations',
    name: 'Polo',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter(
        'position/procedure-training-matrix-filter',
        search,
        atom,
        'locations',
      ),
    isDisabled: () => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter('new-management/filter', search, atom, 'management'),
    isDisabled: () => false,
  },
  {
    key: 'coordinationIds',
    name: 'Coordenação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter(
        'position/procedure-training-matrix-filter',
        search,
        atom,
        'areaCoordination',
      ),
    isDisabled: (atom: ProcedureTrainingFilters) =>
      atom.management.length === 0,
  },
  {
    key: 'workStationIds',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter(
        'position/procedure-training-matrix-filter',
        search,
        atom,
        'workstation',
      ),
    isDisabled: () => false,
  },
  {
    key: 'jobTitleIds',
    name: 'Função',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter(
        'position/procedure-training-matrix-filter',
        search,
        atom,
        'position',
      ),
    isDisabled: () => false,
  },
  {
    key: 'trainingNames',
    name: 'Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ProcedureTrainingFilters) =>
      fetchProcedureFilter('trainings/filter', search, atom),
    isDisabled: () => false,
  },
];
