import { format } from 'date-fns';

interface Props {
  date?: Date;
  className?: string;
  emptyValue?: string;
}

const TableCellDate = ({ date, className, emptyValue = '' }: Props) => {
  return (
    <div className="w-full">
      {date ? (
        <div className={`w-28 text-[12px] text-[#4A4A4A] ${className}`}>
          {format(date, 'dd/MM/yyyy')}
        </div>
      ) : (
        <div className={`w-28 text-center ${className}`}>{emptyValue}</div>
      )}
    </div>
  );
};

export default TableCellDate;
