import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { cn } from '@/utils/cn';

import Spinner from '../atoms/Spinner';

interface TableProps<T> {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  fetchNextPage: () => void;
  fetchNextHorizontalPage?: () => void;
  shouldFetchNextHorizontalPage?: boolean;
  columnVisibility?: Record<string, boolean>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  stickyRows?: number[];
  tableContainerClassName?: string;
  isWhiteTable?: boolean;
  hasDivider?: boolean;
  alignTextLeft?: boolean;
  isFirstColumn?: boolean;
  isEmployeeList?: boolean;
}

const InfiniteTable = <T,>({
  columns,
  data,
  fetchNextPage,
  fetchNextHorizontalPage,
  shouldFetchNextHorizontalPage = false,
  columnVisibility,
  isFetchingNextPage,
  hasNextPage,
  stickyRows,
  tableContainerClassName,
  hasDivider,
  isWhiteTable,
  alignTextLeft,
  isFirstColumn,
  isEmployeeList,
}: TableProps<T>) => {
  const [sorting, setSorting] = useState<any>([]);
  const alignText = alignTextLeft ? 'text-left' : 'text-center';

  const handleHorizontalScroll = (event: any) => {
    const limitToScroll = 5;
    if (shouldFetchNextHorizontalPage) {
      const { scrollLeft, scrollWidth, clientWidth } = event.target;
      const reachedTheEndOfTheScroll =
        Math.abs(scrollWidth - (scrollLeft + clientWidth)) < limitToScroll;
      if (reachedTheEndOfTheScroll) {
        fetchNextHorizontalPage && fetchNextHorizontalPage();
      }
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <div
      className={cn(
        'sticky top-0 flex w-full flex-col overflow-auto pb-5 scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full',
        tableContainerClassName,
      )}
      onScroll={handleHorizontalScroll}
    >
      <table className="w-full border-collapse">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="sticky top-0 z-50 h-10 bg-white font-graphie text-blue-800"
            >
              {headerGroup.headers.map((header) => {
                const headerColor =
                  header.column.columnDef.meta?.headerClassName ||
                  'text-blue-800';
                return (
                  <td
                    key={header.id}
                    className={cn(
                      isFirstColumn && 'px-3',
                      `${alignText} text-[13px] font-semibold ${headerColor}`,
                    )}
                  >
                    {header.column.getCanSort() ? (
                      <button
                        type="button"
                        onClick={header.column.getToggleSortingHandler()}
                        className={`h-14 hover:text-[${isEmployeeList ? '#C4C4C4' : '#4c4c73'}]`}
                      >
                        <div className="flex items-center">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getIsSorted() === 'asc' ? (
                            <NorthIcon
                              sx={{
                                color: isEmployeeList ? '#FFFFFF' : '#232121',
                                padding: '5px',
                              }}
                            />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <SouthIcon
                              sx={{
                                color: isEmployeeList ? '#FFFFFF' : '#232121',
                                padding: '5px',
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </button>
                    ) : typeof header.column.columnDef.header === 'function' ? (
                      header.column.columnDef.header({
                        column: header.column,
                        header,
                        table,
                      })
                    ) : (
                      (header.column.columnDef.header as React.ReactNode)
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={cn(
                `h-12 odd:bg-[#F1F3FA] even:bg-white ${hasDivider ? "border-b border-slate-200 after:absolute after:left-0 after:w-full after:border-b after:border-slate-200 after:content-[''] " : ''} ${
                  stickyRows && stickyRows.includes(index) && 'sticky z-20'
                }`,
              )}
              style={
                stickyRows && stickyRows.includes(index)
                  ? { top: `${50 + 42 * index}px` }
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn(
                    `${alignText} ${isWhiteTable && 'bg-white'} py-1 ${hasDivider ? 'border-x border-solid border-slate-200' : ''}`,
                    cell.column.columnDef.meta?.stickyClassName,
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {hasNextPage && (
        <div className="flex justify-center pb-5" ref={ref}>
          {isFetchingNextPage && <Spinner size={30} />}
        </div>
      )}
    </div>
  );
};

export default InfiniteTable;
