import { ColumnDef } from '@tanstack/react-table';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import TableCellBlockStatus from '@/components/atoms/TableCellBlockStatus';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import { trainingControlAtom } from '@/state/TrainingControl.atom';
import { removeTimeZone } from '@/utils/formatDate';

import {
  TurnstileAccessTraining,
  TurnstileAccessTrainingStatus,
  TurnstileAccessType,
} from '../TurnstileAccessConstants';

export const columns: (
  employeeId: number,
  type: TurnstileAccessType,
) => Array<ColumnDef<TurnstileAccessTraining>> = (employeeId, type) => [
  {
    accessorKey: 'trainingType',
    header: 'MOTIVO DO BLOQUEIO',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-52 items-center uppercase">
        <TableCellText text={`Treinamento ${getValue()}`} width="100%" />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'name',
    header: 'REQUISITO',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-52 items-center uppercase">
        <TableCellText text={getValue() as string} width="100%" />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-32 items-center">
        <TableCellBlockStatus
          status={getValue() as TurnstileAccessTrainingStatus}
          upperCase={true}
        />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey:
      type === TurnstileAccessType.Blocked
        ? 'initialBlockedDate'
        : 'blockDatePrediction',
    header:
      type === TurnstileAccessType.Blocked
        ? 'DATA DO BLOQUEIO'
        : 'PREVISÃO DE BLOQUEIO',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-32 items-center justify-between">
        <TableCellDate
          date={
            getValue() && getValue() !== '-'
              ? removeTimeZone(new Date(getValue() as string))
              : undefined
          }
          emptyValue="-"
          className="flex h-12 w-32 items-center justify-start"
        />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'validityDate',
    header: 'VALIDADE DO REQUISITO',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-32 items-center justify-between">
        <TableCellDate
          date={
            getValue() && getValue() !== '-'
              ? removeTimeZone(new Date(getValue() as string))
              : undefined
          }
          emptyValue="-"
          className="flex h-12 w-32 items-center justify-start"
        />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'nextTrainingDate',
    header: 'PRÓXIMO TREINAMENTO',
    cell: ({ getValue }) => (
      <div className="flex h-12 w-32 items-center justify-start">
        <TableCellDate
          date={
            getValue() && getValue() !== '-'
              ? removeTimeZone(new Date(getValue() as string))
              : undefined
          }
          emptyValue="-"
          className="flex h-12 w-32 items-center justify-start"
        />
      </div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'id',
    header: 'DETALHES',
    cell: ({ getValue }) => {
      const id = getValue() as number;
      const handleClick = () => {
        window.open(
          `/home/controle-treinamentos?treinamento=${id.toString()}&colaborador=${employeeId.toString()}`,
          '_blank',
        );
      };
      return (
        <div className="flex h-12 w-16 items-center justify-center">
          <button
            className="flex h-6 w-6 items-center justify-center rounded-full bg-primary hover:bg-primary/70"
            onClick={handleClick}
          >
            <FaArrowUpRightFromSquare color="white" size={12} />
          </button>
        </div>
      );
    },
    enableSorting: false,
  },
];
