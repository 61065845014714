import { Dialog, Transition } from '@headlessui/react';

import { CombinedJobTitle, JobTitle } from '@/types/JobTitle';

import {
  formatddMMyyyy,
  removeTimeZoneWithoutTimezone,
} from '../../utils/formatDate';
import CloseButton from '../atoms/CloseButton';

interface Props {
  modalIsOpen: boolean;
  handleClose: () => void;
  jobTitle: JobTitle;
}

export default function JobTitleModal({
  jobTitle,
  modalIsOpen,
  handleClose,
}: Props) {
  function convertToCombinedJobTitle(jobTitle: JobTitle): CombinedJobTitle {
    return {
      id: jobTitle.id,
      issueId: jobTitle.generalInformationJobTitle.issueId,
      name: jobTitle.generalInformationJobTitle.name,
      objective: jobTitle.objective,
      version: jobTitle.generalInformationJobTitle.version,
      seniorityLevelName: jobTitle.seniorityLevel.name,
      responsibilities: jobTitle.responsibilities,
      experience: jobTitle.experience,
      skillsAndCompetencies: jobTitle.skillsAndCompetencies,
      updated_at: jobTitle.seniorityLevel.updated_at,
      active: jobTitle.active,
      company: [
        ...new Set(
          jobTitle.generalInformationJobTitle?.managements?.map(
            (el) => el.management.board.company.name,
          ),
        ),
      ],
      board: [
        ...new Set(
          jobTitle.generalInformationJobTitle?.managements?.map(
            (el) => el.management.board.name,
          ),
        ),
      ],
      management: [
        ...new Set(
          jobTitle.generalInformationJobTitle?.managements?.map(
            (el) => el.management.name,
          ),
        ),
      ],
      workStations: jobTitle.generalInformationJobTitle?.workStations,
      creator: jobTitle.creator,
      revisor: jobTitle.revisor,
      firstValidator: jobTitle.firstValidator,
      approver: jobTitle.approver,
    };
  }

  const combinedJobTitle = convertToCombinedJobTitle(jobTitle);

  const separateRequirements = (jobTitle: JobTitle) => {
    const requirementsLists = {
      mandatoryEducationalRequirements: {
        title: 'Requisitos Educacionais Obrigatórios',
        list: jobTitle.formattedRequirements.mandatory.educational,
      },
      optionalEducationalRequirements: {
        title: 'Requisitos Educacionais Desejáveis',
        list: jobTitle.formattedRequirements.optional.educational,
      },
      mandatoryProfessionalRequirements: {
        title: 'Requisitos Profissionais Obrigatórios',
        list: jobTitle.formattedRequirements.mandatory.professional,
      },
      optionalProfessionalRequirements: {
        title: 'Requisitos Profissionais Desejáveis',
        list: jobTitle.formattedRequirements.optional.professional,
      },
      mandatoryOtherRequirements: {
        title: 'Outros Requisitos Obrigatórios',
        list: jobTitle.formattedRequirements.mandatory.other,
      },
      optionalOtherRequirements: {
        title: 'Outros Requisitos Desejáveis',
        list: jobTitle.formattedRequirements.optional.other,
      },
    };

    return requirementsLists;
  };

  const requirements = separateRequirements(jobTitle);

  return (
    <Transition appear show={modalIsOpen}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className={'w-full'}
          >
            <Dialog.Panel className="relative mx-auto h-[80vh] max-w-[1030px] flex-col overflow-auto rounded-lg bg-white p-5">
              <div className="flex h-auto items-center justify-between">
                <h3 className="text-2xl font-bold text-primary">
                  Detalhes do cargo
                </h3>
                <CloseButton onClick={handleClose} className="" />
              </div>
              <div className="mt-[22px] flex flex-col gap-[10px]">
                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/2 px-4 py-1">Título do Cargo</th>
                      <th className="w-1/2 px-4 py-1">Nível</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/2 px-4 py-3">
                        {combinedJobTitle.name}
                      </td>
                      <td className="w-1/2 border-l border-[#F1F3FA] px-4 py-3">
                        {combinedJobTitle.seniorityLevelName}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/2 px-4 py-1">Empresa</th>
                      <th className="w-1/2 px-4 py-1">Diretoria</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/2 px-4 py-3">
                        {combinedJobTitle.company.join('; ')}
                      </td>
                      <td className="w-1/2 border-l border-[#F1F3FA] px-4 py-3">
                        {combinedJobTitle.board.join('; ')}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/2 px-4 py-1">Gerência</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/2 px-4 py-3">
                        {combinedJobTitle.management.join('; ')}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="px-4 py-1">GHE por local de Trabalho</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <div className="py-4">
                        <table className="mx-auto bg-white">
                          <thead>
                            <tr>
                              <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                                Local de Trabalho
                              </th>
                              <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                                GHE
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {combinedJobTitle.workStations.map(
                              (workStation, index) => (
                                <tr key={index}>
                                  <td className="border border-gray-300 px-4 py-2 text-center">
                                    {workStation.workStation.name}
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2 text-center">
                                    {workStation.gheCode ?? 'N/A'}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="px-4 py-1">Objetivo do Cargo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="px-4 py-3">
                        {combinedJobTitle.objective}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/2 border-l border-[#F1F3FA] px-4 py-1">
                        Funções e responsabilidades
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/4 whitespace-pre-line border-l border-[#F1F3FA] px-4 py-3">
                        {combinedJobTitle.responsibilities}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <span className="bg-[#F1F3FA] px-4 py-1 text-left text-base font-semibold text-primary">
                  Requisitos do Cargo
                </span>
                <section className="flex flex-col gap-[3px]">
                  <>
                    {Object.entries(requirements).map(([key, value]) => {
                      if (value.list.length > 0) {
                        return (
                          <table key={key}>
                            <thead className="font-regular text-left text-sm text-[#4A4A4A]">
                              <tr className="py-10">
                                <th className="border border-[#F1F3FA] px-4 py-1">
                                  {value.title}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="font-regular border border-[#F1F3FA] text-sm text-[#5A5A5A]">
                                <td className="px-4 py-3">
                                  <ul>
                                    {value.list?.map((item, index) => (
                                      <li key={index}>{item}</li>
                                    ))}
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }
                      return null;
                    })}
                  </>
                </section>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="px-4 py-1">Habilidades e Competências</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular whitespace-pre-line text-sm text-[#5A5A5A]">
                      <td className="px-4 py-3">
                        {combinedJobTitle.skillsAndCompetencies}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/4 px-4 py-1">Versão</th>
                      <th className="w-1/4 px-4 py-1">Última Atualização</th>
                      <th className="w-1/4 px-4 py-1">Data de Criação</th>
                      <th className="w-1/4 px-4 py-1">Situação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/4 px-4 py-3">
                        {combinedJobTitle.version}
                      </td>
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {formatddMMyyyy(
                          removeTimeZoneWithoutTimezone(
                            jobTitle.updatedAt.toString(),
                          ),
                        )}
                      </td>
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {formatddMMyyyy(
                          removeTimeZoneWithoutTimezone(
                            jobTitle.createdAt.toString(),
                          ),
                        )}
                      </td>
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {jobTitle.active === true ? 'Ativo' : 'Inativo'}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="w-3/4 table-fixed border-collapse border border-[#F1F3FA]">
                  <thead className="bg-[#F1F3FA] text-left text-base font-semibold text-primary">
                    <tr className="py-10">
                      <th className="w-1/4 px-4 py-1">Criador</th>
                      <th className="w-1/4 px-4 py-1">Revisor</th>
                      <th className="w-1/4 px-4 py-1">Aprovador</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-regular text-sm text-[#5A5A5A]">
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {jobTitle.creator}
                      </td>
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {jobTitle.revisor}
                      </td>
                      <td className="w-1/4 border-l border-[#F1F3FA] px-4 py-3">
                        {jobTitle.approver}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
