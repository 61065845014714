/* eslint-disable no-unused-vars */
import Menu from '@mui/icons-material/Menu';
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { ShortTraining } from '@/types/Training';

import LightTooltip from '../atoms/LightTooltip';
import SimpleMenu from '../atoms/Menu';

interface ProcedureTrainingHeaderProps {
  training: ShortTraining;
  canSeeTraining: boolean | undefined;
  canSave: boolean | undefined;
  isEditMode: boolean;
  handleEditTraining: (trainingId: number) => void;
  handleDesactivateTraining: (trainingId: number) => void;
  handleDeleteTraining: (trainingId: number) => void;
  handleTransformTrainingName: (title: string, maxLength: number) => string;
  handleMapAllPositions: (trainingId: number) => Promise<void>;
  handleStartMappingMode: () => Promise<void>;
  handleSkipMapping: (trainingId: number) => Promise<void>;
  handleSaveMapping: () => Promise<void>;
}

const ProcedureTrainingHeader = forwardRef(
  (
    {
      training,
      canSeeTraining,
      canSave,
      isEditMode,
      handleEditTraining,
      handleDesactivateTraining,
      handleDeleteTraining,
      handleTransformTrainingName,
      handleMapAllPositions,
      handleStartMappingMode,
      handleSkipMapping,
      handleSaveMapping,
    }: ProcedureTrainingHeaderProps,
    ref,
  ) => {
    const location = useLocation();

    const handleEditClick = useCallback(() => {
      handleEditTraining(training.id as number);
    }, [training.id, handleEditTraining]);

    const handleDesactivateClick = useCallback(() => {
      handleDesactivateTraining(training.id as number);
    }, [training.id, handleDesactivateTraining]);

    const handleMapAllClick = useCallback(() => {
      handleMapAllPositions(training.id as number);
    }, [training.id, handleMapAllPositions]);

    const handleMappingModeClick = useCallback(() => {
      handleStartMappingMode();
    }, [training.id, handleStartMappingMode]);

    const handleSkipMappingClick = useCallback(() => {
      handleSkipMapping(training.id as number);
    }, [training.id, handleSkipMapping]);

    const handleSaveMappingClick = useCallback(() => {
      handleSaveMapping();
    }, [training.id, handleSaveMapping]);

    const handleSendMapping = useCallback(async () => {
      try {
        await api.post(`/trainings/mapping/${training.id}`);
        toast.success('Mapeamento enviado com sucesso', {
          toastId: 'success',
          theme: 'colored',
        });
      } catch (e: any) {
        toast.error(
          e?.response?.data?.message ?? 'Erro ao enviar para mapeamento',
          {
            toastId: 'error',
            theme: 'colored',
          },
        );
      }
    }, [training.id]);

    const options = useMemo(
      () => [
        {
          name: 'Cadastro do Treinamento',
          onClick: handleEditClick,
          disabled: false,
        },
        {
          name: 'Desativar Treinamento',
          onClick: handleDesactivateClick,
          disabled: !canSave,
        },
        {
          name: 'Enviar para mapeamento',
          onClick: handleSendMapping,
          disabled:
            !canSave || !!training.sentForMapping || !training.isReadyToMap,
        },
        {
          name: 'Mapear Todas as Funções',
          onClick: handleMapAllClick,
          disabled: !isEditMode,
        },
        ...(training.isResponsible
          ? [
              {
                name: 'Editar mapeamento',
                onClick: handleMappingModeClick,
                disabled: !!isEditMode,
              },
              {
                name: 'Salvar sem alteração no mapeamento',
                onClick: handleSkipMappingClick,
                disabled: !isEditMode,
              },
              {
                name: 'Salvar com alteração no mapeamento',
                onClick: handleSaveMappingClick,
                disabled: !isEditMode,
              },
            ]
          : []),
      ],
      [
        handleEditClick,
        handleDesactivateClick,
        handleMapAllClick,
        handleSkipMappingClick,
        handleSaveMappingClick,
        handleMappingModeClick,
        handleSendMapping,
        canSave,
        isEditMode,
        training.isResponsible,
        training.sentForMapping,
        training.isReadyToMap,
      ],
    );

    const transformedName = useMemo(
      () => handleTransformTrainingName(training.name, 35),
      [training.name, handleTransformTrainingName],
    );

    const menuIcon = useMemo(() => <Menu fontSize="inherit" />, []);

    useImperativeHandle(
      ref,
      () => ({
        triggerEdit: handleEditClick,
        triggerDesactivate: handleDesactivateClick,
        triggerDelete: () => handleDeleteTraining(training.id as number),
        triggerMapAllPositions: handleMapAllClick,
        triggerSkipMapping: handleSkipMappingClick,
      }),
      [
        handleEditClick,
        handleDesactivateClick,
        handleDeleteTraining,
        handleMapAllClick,
        handleSkipMappingClick,
        training.id,
      ],
    );

    return (
      <div className="relative flex h-12 items-center justify-between overflow-visible align-middle">
        <LightTooltip title={training.name} arrow placement="top-start">
          <div className="ml-2 line-clamp-2 block max-h-20 w-full overflow-hidden break-words text-center">
            {transformedName}
          </div>
        </LightTooltip>
        {canSeeTraining && (
          <SimpleMenu
            options={options}
            iconComponent={menuIcon}
            menuItemsClassName="right-0 left-auto"
          />
        )}
      </div>
    );
  },
);

ProcedureTrainingHeader.displayName = 'ProcedureTrainingHeader';

export default ProcedureTrainingHeader;
