/* eslint-disable @typescript-eslint/no-unused-vars */
import { it } from 'node:test';

import { useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { BiDownArrow } from 'react-icons/bi';
import { FaArrowDownLong, FaCircleArrowDown } from 'react-icons/fa6';
import { IoMdArrowDown } from 'react-icons/io';
import { z } from 'zod';

import { RequirementCategoryEnum } from '@/constants/enums/RequirementCategoryEnum';
import { JobTitleFormSchema } from '@/constants/formSchema/JobtitleFormSchema';
import { GeneralInformationJobTitleCreationData } from '@/types/JobTitleCreationData';

import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import TrashButton from '../atoms/TrashButton';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import ExpandableSubtitle from './ExpandableSubtitle';
interface RequirementOption {
  type: 'mandatory' | 'optional';
  text: string;
}
const educationalRequirements: RequirementOption[] = [
  { type: 'mandatory', text: 'Requisitos Educacionais Obrigatórios' },
  { type: 'optional', text: 'Requisitos Educacionais Desejáveis' },
];
const professionalRequirements: RequirementOption[] = [
  { type: 'mandatory', text: 'Requisitos Profissionais Obrigatórios' },
  { type: 'optional', text: 'Requisitos Profissionais Desejáveis' },
];
const otherRequirements: RequirementOption[] = [
  { type: 'mandatory', text: 'Outros Requisitos Obrigatórios' },
  { type: 'optional', text: 'Outros Requisitos Desejáveis' },
];

interface Props {
  methods: UseFormReturn<z.infer<typeof JobTitleFormSchema>>;
  creationData: GeneralInformationJobTitleCreationData | undefined;
}

export const RegisterJobTitle = ({ methods, creationData }: Props) => {
  const { fields, append, remove } = useFieldArray<
    z.infer<typeof JobTitleFormSchema>
  >({
    control: methods.control,
    name: 'levels',
  });
  const [levels, setLevels] = useState<string[]>(
    methods.getValues('levels')?.map((level) => level.level) ?? [],
  );
  const addJobTitle = () => {
    append({
      level: '',
      rolesAndResponsibilities: '',
      experience: '',
      skillsAndCompetencies: '',
      objective: '',
      requirements: {
        mandatory: {
          educationalRequirements: {
            levelOfEducation: [],
            technicalEducation: [],
            undergraduate: [],
            postgraduate: [],
          },
          professionalRequirements: {
            activeProfessionalRecords: [],
            certifications: [],
            softwares: [],
            knowledge: [],
            otherCourses: [],
          },
          otherRequirements: {
            languages: [],
            other: [],
          },
        },
        optional: {
          educationalRequirements: {
            levelOfEducation: [],
            technicalEducation: [],
            undergraduate: [],
            postgraduate: [],
          },
          professionalRequirements: {
            activeProfessionalRecords: [],
            certifications: [],
            softwares: [],
            knowledge: [],
            otherCourses: [],
          },
          otherRequirements: {
            languages: [],
            other: [],
          },
        },
      },
    });
  };
  const control = methods.control;
  const requirementsOptions = creationData?.requirements?.reduce(
    (
      acc: {
        levelOfEducation: { value: string; label: string }[];
        technicalEducation: { value: string; label: string }[];
        undergraduate: { value: string; label: string }[];
        postgraduate: { value: string; label: string }[];
        activeProfessionalRecords: { value: string; label: string }[];
        certifications: { value: string; label: string }[];
        softwares: { value: string; label: string }[];
        knowledge: { value: string; label: string }[];
        otherCourses: { value: string; label: string }[];
        languages: { value: string; label: string }[];
        other: { value: string; label: string }[];
      },
      requirement,
    ) => {
      const option = { value: requirement.name, label: requirement.name };
      switch (requirement.requirementCategory.name) {
        case RequirementCategoryEnum.levelOfEducation:
          acc.levelOfEducation.push(option);
          break;
        case RequirementCategoryEnum.technicalEducation:
          acc.technicalEducation.push(option);
          break;
        case RequirementCategoryEnum.undergraduate:
          acc.undergraduate.push(option);
          break;
        case RequirementCategoryEnum.postgraduate:
          acc.postgraduate.push(option);
          break;
        case RequirementCategoryEnum.activeProfessionalRecords:
          acc.activeProfessionalRecords.push(option);
          break;
        case RequirementCategoryEnum.certifications:
          acc.certifications.push(option);
          break;
        case RequirementCategoryEnum.softwares:
          acc.softwares.push(option);
          break;
        case RequirementCategoryEnum.knowledge:
          acc.knowledge.push(option);
          break;
        case RequirementCategoryEnum.otherCourses:
          acc.otherCourses.push(option);
          break;
        case RequirementCategoryEnum.languages:
          acc.languages.push(option);
          break;
        case RequirementCategoryEnum.other:
          acc.other.push(option);
          break;
        default:
          break;
      }
      return acc;
    },
    {
      levelOfEducation: [],
      technicalEducation: [],
      undergraduate: [],
      postgraduate: [],
      activeProfessionalRecords: [],
      certifications: [],
      softwares: [],
      knowledge: [],
      otherCourses: [],
      languages: [],
      other: [],
    },
  ) ?? {
    levelOfEducation: [],
    technicalEducation: [],
    undergraduate: [],
    postgraduate: [],
    activeProfessionalRecords: [],
    certifications: [],
    softwares: [],
    knowledge: [],
    otherCourses: [],
    languages: [],
    other: [],
  };
  const seniorityLevelsOptions =
    creationData?.seniorityLevels.map((seniorityLevel) => ({
      value: seniorityLevel.name,
      label: seniorityLevel.name,
    })) ?? [];

  const spreadFirstLevelData = (
    requirementType?:
      | 'educationalRequirements'
      | 'professionalRequirements'
      | 'otherRequirements',
    requirementClass?: 'mandatory' | 'optional',
  ) => {
    const firstLevel = methods.getValues('levels')[0];

    if (!firstLevel || fields.length <= 1) return;

    for (let i = 1; i < fields.length; i++) {
      // Copia os atributos comuns se não houver um requirementType específico
      if (!requirementType) {
        const commonFields = [
          'objective',
          'rolesAndResponsibilities',
          'experience',
          'skillsAndCompetencies',
        ];
        commonFields.forEach((field) => {
          methods.setValue(
            `levels.${i}.${field}` as keyof z.infer<typeof JobTitleFormSchema>,
            firstLevel[field as keyof typeof firstLevel] as string,
          );
        });
        continue;
      }

      // Se houver requirementType e requirementClass, copia os requisitos específicos
      if (requirementType && requirementClass) {
        const sourcePath =
          firstLevel.requirements?.[requirementClass]?.[requirementType];
        if (!sourcePath) continue;

        const targetPath = `levels.${i}.requirements.${requirementClass}.${requirementType}`;
        Object.keys(sourcePath).forEach((key) => {
          methods.setValue(
            `${targetPath}.${key}` as keyof z.infer<typeof JobTitleFormSchema>,
            sourcePath[key as keyof typeof sourcePath] as string,
          );
        });
      }
    }
  };

  return (
    <div>
      <div className="mt-1 flex justify-end">
        <button
          type="button"
          className="p-2 text-[13px] text-[#193CB9] underline"
          onClick={addJobTitle}
        >
          + Adicionar um novo nível
        </button>
      </div>
      {fields.length > 0 ? (
        <div className="flex w-full flex-col justify-between gap-2 py-2">
          <ExpandableSubtitle subtitle="Detalhamento do Cargo">
            <div className="custom-scrollbar flex w-full flex-col gap-2 overflow-x-auto overflow-y-hidden">
              {fields.map((el, index) => {
                const id = methods.getValues('levels')[index]?.id;

                return (
                  <div
                    className="flex w-[44rem] items-end justify-between gap-2 py-2"
                    key={index}
                  >
                    <ControlledSimpleSelect
                      title={'Nível do Cargo'}
                      required
                      control={control}
                      {...methods.register(`levels.${index}.level`)}
                      width="15rem"
                      values={seniorityLevelsOptions}
                      disabled={!!id}
                      onChange={(e) => {
                        const newLevels = [...levels];
                        newLevels[index] = e.target.value as string;
                        setLevels(newLevels);
                      }}
                    />
                    <UncontrolledInputText
                      title="Objetivo do cargo"
                      required
                      width="20rem"
                      multiline
                      {...methods.register(`levels.${index}.objective`)}
                    />
                    <UncontrolledInputText
                      title="Funções e Responsabilidades"
                      required
                      width="20rem"
                      multiline
                      {...methods.register(
                        `levels.${index}.rolesAndResponsibilities`,
                      )}
                    />
                    <UncontrolledInputText
                      title="Experiências"
                      required
                      width="20rem"
                      multiline
                      {...methods.register(`levels.${index}.experience`)}
                    />
                    <UncontrolledInputText
                      title="Habilidades e Competências"
                      required
                      width="20rem"
                      multiline
                      {...methods.register(
                        `levels.${index}.skillsAndCompetencies`,
                      )}
                    />
                    <div className="flex items-end gap-1">
                      <TrashButton
                        color={'action'}
                        disabled={!!id}
                        onClick={() => {
                          remove(index);
                          const newLevels = [...levels];
                          newLevels.splice(index, 1);
                          setLevels(newLevels);
                        }}
                      />

                      {index === 0 && (
                        <button
                          className="p-2 disabled:opacity-25"
                          onClick={() => spreadFirstLevelData()}
                          type="button"
                        >
                          <FaCircleArrowDown color="#7c7f85" size={24} />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </ExpandableSubtitle>
          {educationalRequirements.map((el, idx) => {
            return (
              <ExpandableSubtitle key={idx} subtitle={el.text}>
                <div className="custom-scrollbar flex w-full flex-col gap-2 overflow-x-auto overflow-y-hidden">
                  {fields.map((level, index) => (
                    <div
                      className="flex w-[44rem] items-end justify-between gap-2 py-2"
                      key={`${idx}-${index}`}
                    >
                      <UncontrolledInputText
                        title="Nível"
                        disabled
                        required
                        width="15rem"
                        value={levels[index]}
                        name={`levels.${index}.requirements.${el.type}.educationalRequirements.level`}
                      />
                      <ControlledSimpleSelect
                        title={'Grau de Escolaridade'}
                        control={control}
                        width="15rem"
                        required={el.type === 'mandatory'}
                        values={requirementsOptions.levelOfEducation}
                        multiple
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.educationalRequirements.levelOfEducation`,
                        )}
                      />
                      <ControlledSimpleSelect
                        title={'Ensino Técnico'}
                        control={control}
                        width="15rem"
                        values={requirementsOptions.technicalEducation}
                        multiple
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.educationalRequirements.technicalEducation`,
                        )}
                      />
                      <ControlledSimpleSelect
                        title={'Graduação'}
                        control={control}
                        width="15rem"
                        values={requirementsOptions.undergraduate}
                        multiple
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.educationalRequirements.undergraduate`,
                        )}
                      />
                      <ControlledSimpleSelect
                        title={'Pós-Graduação'}
                        control={control}
                        width="15rem"
                        values={requirementsOptions.postgraduate}
                        multiple
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.educationalRequirements.postgraduate`,
                        )}
                      />
                      {index === 0 && (
                        <button
                          className="p-2 disabled:opacity-25"
                          disabled={fields.length === 1}
                          onClick={() =>
                            spreadFirstLevelData(
                              'educationalRequirements',
                              el.type as 'mandatory' | 'optional',
                            )
                          }
                          type="button"
                        >
                          <FaCircleArrowDown color="#7c7f85" size={24} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </ExpandableSubtitle>
            );
          })}
          {professionalRequirements.map((el, idx) => {
            return (
              <ExpandableSubtitle key={idx} subtitle={el.text}>
                <div className="custom-scrollbar flex w-full flex-col gap-2 overflow-x-auto overflow-y-hidden">
                  {fields.map((level, index) => (
                    <div
                      className="flex w-[44rem] items-end justify-between gap-2 py-2"
                      key={`${idx}-${index}`}
                    >
                      <UncontrolledInputText
                        title="Nível"
                        disabled
                        required
                        width="15rem"
                        value={levels[index]}
                        name="level"
                      />
                      <ControlledSimpleSelect
                        title={'Registros Profissionais Ativos'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.professionalRequirements.activeProfessionalRecords`,
                        )}
                        width="15rem"
                        values={requirementsOptions.activeProfessionalRecords}
                        multiple
                      />
                      <ControlledSimpleSelect
                        title={'Certificados'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.professionalRequirements.certifications`,
                        )}
                        width="15rem"
                        values={requirementsOptions.certifications}
                        multiple
                      />
                      <ControlledSimpleSelect
                        title={'Softwares'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.professionalRequirements.softwares`,
                        )}
                        width="15rem"
                        values={requirementsOptions.softwares}
                        multiple
                      />
                      <ControlledSimpleSelect
                        title={'Conhecimentos'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.professionalRequirements.knowledge`,
                        )}
                        width="15rem"
                        values={requirementsOptions.knowledge}
                        multiple
                      />
                      <ControlledSimpleSelect
                        title={'Outros Cursos'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.professionalRequirements.otherCourses`,
                        )}
                        width="15rem"
                        values={requirementsOptions.otherCourses}
                        multiple
                      />
                      {index === 0 && (
                        <button
                          className="p-2 disabled:opacity-25"
                          disabled={fields.length === 1}
                          onClick={() =>
                            spreadFirstLevelData(
                              'professionalRequirements',
                              el.type as 'mandatory' | 'optional',
                            )
                          }
                          type="button"
                        >
                          <FaCircleArrowDown color="#7c7f85" size={24} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </ExpandableSubtitle>
            );
          })}
          {otherRequirements.map((el, idx) => {
            return (
              <ExpandableSubtitle key={idx} subtitle={el.text}>
                <div className="custom-scrollbar flex w-full flex-col gap-2 overflow-x-auto overflow-y-hidden">
                  {fields.map((level, index) => (
                    <div
                      className="flex w-[44rem] items-end justify-between gap-2 py-2"
                      key={`${idx}-${index}`}
                    >
                      <UncontrolledInputText
                        title="Nível"
                        disabled
                        required
                        width="15rem"
                        value={levels[index]}
                        name="level"
                      />
                      <ControlledSimpleSelect
                        title={'Idiomas'}
                        control={control}
                        width="15rem"
                        values={requirementsOptions.languages}
                        multiple
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.otherRequirements.languages`,
                        )}
                      />
                      <ControlledSimpleSelect
                        title={'Outros'}
                        control={control}
                        {...methods.register(
                          `levels.${index}.requirements.${el.type}.otherRequirements.other`,
                        )}
                        width="15rem"
                        values={requirementsOptions.other}
                        multiple
                      />
                      {index === 0 && (
                        <button
                          className="p-2 disabled:opacity-25"
                          disabled={fields.length === 1}
                          onClick={() =>
                            spreadFirstLevelData(
                              'otherRequirements',
                              el.type as 'mandatory' | 'optional',
                            )
                          }
                          type="button"
                        >
                          <FaCircleArrowDown color="#7c7f85" size={24} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </ExpandableSubtitle>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
