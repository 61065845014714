import { z } from 'zod';

export enum TurnstileAccessType {
  Blocked = 'bloqueado',
  InRisk = 'risco',
}

export enum TurnstileAccessTrainingStatus {
  Pendente = 'Pendente',
  Valido = 'Válido',
  Vazio = '',
  Vencido = 'Vencido',
  Invalido = 'Inválido',
  VenceEm15Dias = 'Vence em 15 dias',
  VenceEm30Dias = 'Vence em 30 dias',
  VenceEm45Dias = 'Vence em 45 dias',
}

export const greenStatus = [TurnstileAccessTrainingStatus.Valido];

export const darkOrangeStatus = [TurnstileAccessTrainingStatus.VenceEm15Dias];

export const lightOrangeStatus = [TurnstileAccessTrainingStatus.VenceEm30Dias];

export const yellowStatus = [TurnstileAccessTrainingStatus.VenceEm45Dias];

export const redStatus = [
  TurnstileAccessTrainingStatus.Vencido,
  TurnstileAccessTrainingStatus.Invalido,
  TurnstileAccessTrainingStatus.Pendente,
];

export const TurnstileAccessTrainingSchema = z.object({
  id: z.number(),
  name: z.string(),
  trainingControlId: z.number(),
  trainingType: z.string(),
  status: z.nativeEnum(TurnstileAccessTrainingStatus),
  initialBlockedDate: z.coerce.date().nullable(),
  blockDatePrediction: z.coerce.date().nullable(),
  validityDate: z.coerce.date().nullable(),
  nextTrainingDate: z.coerce.date().nullable(),
});

export const TurnstileAccessEmployeeSchema = z.object({
  id: z.number(),
  name: z.string(),
  alias: z.string().nullable(),
  employeeNumber: z.string(),
  jobTitle: z.string(),
  workStation: z.string(),
  board: z.string(),
  management: z.string(),
  coordination: z.string(),
  trainings: z.array(TurnstileAccessTrainingSchema),
});

export const TurnstileAccessSchema = z.object({
  date: z.coerce.date(),
  employees: z.array(TurnstileAccessEmployeeSchema),
});

export const TurnstileAccessResponseSchema = z.object({
  count: z.coerce.number(),
  nextPage: z.number().optional(),
  turnstileAccess: TurnstileAccessSchema,
});

export type TurnstileAccessTraining = z.infer<
  typeof TurnstileAccessTrainingSchema
>;
export type TurnstileAccessEmployee = z.infer<
  typeof TurnstileAccessEmployeeSchema
>;
export type TurnstileAccess = z.infer<typeof TurnstileAccessSchema>;

const TurnstileAccessFilterSingleOption = z.string().transform((value) => ({
  value,
  label: value,
}));

export const TurnstileAccessFilterOptionsSchema = z.object({
  management: z.array(TurnstileAccessFilterSingleOption).default([]),
  workstation: z.array(TurnstileAccessFilterSingleOption).default([]),
  board: z.array(TurnstileAccessFilterSingleOption).default([]),
  jobTitle: z.array(TurnstileAccessFilterSingleOption).default([]),
  coordination: z.array(TurnstileAccessFilterSingleOption).default([]),
  employee: z.array(TurnstileAccessFilterSingleOption).default([]),
  training: z.array(TurnstileAccessFilterSingleOption).default([]),
});

export type TurnstileAccessFilterOptions = z.infer<
  typeof TurnstileAccessFilterOptionsSchema
>;
