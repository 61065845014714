import { useState } from 'react';

import EmptyIcon from '@/assets/icons/empty.svg';
import { columns as trainingColumns } from '@/constants/tableColumns/TrainingControlGroupedColumns';
import { columns as employeeLeftColumns } from '@/constants/tableColumns/TrainingControlGroupedEmployeeLeftColumns';
import { columns as employeeRightColumns } from '@/constants/tableColumns/TrainingControlGroupedEmployeeRightColumns';
import { TrainingControlGrouped } from '@/types/TrainingControlGrouped';
import { cn } from '@/utils/cn';

import Table from './Table';

interface TrainingControlGroupedCardProps {
  employeeWithTrainingControls: TrainingControlGrouped;
}

const TrainingControlGroupedCard = ({
  employeeWithTrainingControls,
}: TrainingControlGroupedCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCard = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="flex w-full gap-2">
      <div
        className={cn(
          'sticky left-0 z-40 flex h-fit min-h-16 flex-col items-end justify-start rounded-lg bg-white text-xs text-gray-600 shadow-md ',
        )}
      >
        <div className="flex w-full items-center justify-start">
          <div className="min-h-2 w-full px-4">
            <Table
              columns={employeeLeftColumns(isOpen, handleToggleCard)}
              data={[
                {
                  id: employeeWithTrainingControls.employee.id,
                  name: employeeWithTrainingControls.employee.name,
                  alias: employeeWithTrainingControls.employee.alias,
                  employeeNumber:
                    employeeWithTrainingControls.employee.employeeNumber,
                },
              ]}
              showHeader={false}
              isWhiteTable={true}
              tBodyClassname="border-none"
              trBodyClassname="border-none flex items-center h-16 rounded-lg"
              tdHeaderClassname="bg-transparent text-blue-800"
              trHeaderClassname="bg-transparent my-4 rounded-md"
              containerClassname="border-separate border-spacing-y-4"
            />
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex min-h-16 grow flex-col items-end justify-start rounded-lg bg-white text-xs text-gray-600 shadow-md ',
        )}
      >
        <div className="flex w-full items-center justify-start">
          <div className="min-h-2 w-full px-4">
            <Table
              columns={employeeRightColumns}
              data={[
                {
                  ...employeeWithTrainingControls.employee,
                  trainingControlsCount:
                    employeeWithTrainingControls.trainingControlItems.length,
                },
              ]}
              showHeader={false}
              isWhiteTable={true}
              tBodyClassname="border-none"
              trBodyClassname="border-none flex items-center h-16 rounded-lg"
              tdHeaderClassname="bg-transparent text-blue-800"
              trHeaderClassname="bg-transparent my-4 rounded-md"
              containerClassname="border-separate border-spacing-y-4"
            />
          </div>
        </div>
        {isOpen &&
          (employeeWithTrainingControls.trainingControlItems.length > 0 ? (
            <div className="custom-scrollbar flex h-full flex-col items-center justify-end gap-2 bg-white px-4 py-2">
              <Table
                columns={trainingColumns(employeeWithTrainingControls.employee)}
                data={employeeWithTrainingControls.trainingControlItems}
                isWhiteTable={true}
                tBodyClassname="border-none"
                trBodyClassname="border-none"
                tdHeaderClassname="bg-transparent"
                trHeaderClassname="bg-white my-4 rounded-md"
                tdClassname="px-0 bg-background border-b-8 border-white"
                containerClassname="border-separate border-spacing-y-4 w-full h-[25rem] max-h-[25rem] overflow-scroll pb-6"
                stickyHeader={true}
              />
            </div>
          ) : (
            <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-b-md rounded-t-none bg-white pb-2">
              <img src={EmptyIcon} className="w-32" />
              <div className="w-full text-center text-xs">
                <span className="font-semibold text-blue-800">
                  Nenhum treinamento cadastrado para esse colaborador!
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TrainingControlGroupedCard;
