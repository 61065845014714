import { MouseEvent, useState } from 'react';
import { GoAlertFill } from 'react-icons/go';

import {
  darkOrangeStatus,
  greenStatus,
  lightOrangeStatus,
  redStatus,
  TurnstileAccessTrainingStatus,
  yellowStatus,
} from '@/constants/TurnstileAccessConstants';
import { cn } from '@/utils/cn';

import LightTooltip from './LightTooltip';

interface Props {
  status: TurnstileAccessTrainingStatus | '-';
  upperCase?: boolean;
  className?: string;
}

const TableCellBlockStatus = ({ status, upperCase, className }: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [alertTooltipEnabled, setAlertTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const handleShouldShowAlert = () => {
    setAlertTooltipEnabled(true);
  };

  const isValid = status === TurnstileAccessTrainingStatus.Valido;

  let bgColor = '';
  if (status === '-') {
    bgColor = 'bg-[#F0F0F0]';
  } else if (greenStatus.includes(status)) {
    bgColor = 'bg-[#2E7D32]';
  } else if (redStatus.includes(status)) {
    bgColor = 'bg-[#F4503A]';
  } else if (yellowStatus.includes(status)) {
    bgColor = 'bg-[#FFB800]';
  } else if (darkOrangeStatus.includes(status)) {
    bgColor = 'bg-[#FF6A00]';
  } else if (lightOrangeStatus.includes(status)) {
    bgColor = 'bg-[#FF9F04]';
  }

  return (
    <div className="flex items-center justify-center gap-2">
      <LightTooltip
        title={status}
        open={tooltipEnabled}
        onClose={() => setTooltipEnabled(false)}
        arrow
        placement="top-end"
      >
        {status !== '-' ? (
          <div
            onMouseEnter={handleShouldShow}
            className={cn(
              'flex min-w-[3rem] text-nowrap rounded p-[5px] text-[11px] tracking-widest text-white',
              bgColor,
              className,
            )}
          >
            {upperCase ? status.toUpperCase() : status}
          </div>
        ) : (
          <div className="w-28 text-center"></div>
        )}
      </LightTooltip>
      {isValid && (
        <LightTooltip
          title="Validado após sincronização com a Catraca"
          arrow
          placement="top"
          open={alertTooltipEnabled}
          onClose={() => setAlertTooltipEnabled(false)}
        >
          <div
            className="flex items-center justify-center"
            onMouseEnter={handleShouldShowAlert}
          >
            <GoAlertFill className="text-yellow-500" size={20} />
          </div>
        </LightTooltip>
      )}
    </div>
  );
};

export default TableCellBlockStatus;
