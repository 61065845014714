import { ColumnDef } from '@tanstack/react-table';

import TableCellText from '@/components/atoms/TableCellText';
import TableData from '@/components/atoms/TableData';

export const columns: Array<
  ColumnDef<{
    status: string;
    role: string;
    workStation: string;
    pole: string;
    management: string;
    areaCoordinator: string;
    trainingControlsCount: number;
  }>
> = [
  {
    accessorKey: 'status',
    accessorFn: (row) => row.status,
    header: 'Situação',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="ml-5 mr-5 flex items-center justify-start">
        <TableCellText
          text={'Situação:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableData
          tableValue={props.getValue() as string}
          className="ml-1 flex h-[32px] w-24 items-center justify-start whitespace-nowrap text-[11px] uppercase"
          title={props.getValue() ? (props.getValue() as string) : 'N/A'}
        />
      </div>
    ),
  },
  {
    accessorKey: 'role',
    accessorFn: (row) => row.role,
    header: 'Função',
    meta: {
      headerClassName: 'text-center',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Função:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'workStation',
    accessorFn: (row) => row.workStation,
    header: 'Local de Trabalho',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Local de Trabalho:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'pole',
    accessorFn: (row) => row.pole,
    header: 'Polo',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="ml-5 mr-5 flex items-center justify-start">
        <TableCellText
          text={'Polo:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableData
          tableValue={props.getValue() as string}
          className="ml-1 flex h-[32px] w-24 items-center justify-start whitespace-nowrap text-[11px] uppercase"
          title={props.getValue() ? (props.getValue() as string) : 'N/A'}
        />
      </div>
    ),
  },
  {
    accessorKey: 'management',
    accessorFn: (row) => row.management,
    header: 'Gerência',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Gerência:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit max-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'coordination',
    accessorFn: (row) => row.areaCoordinator,
    header: 'Coordenação',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Coordenação:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit font-bold uppercase text-primary"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
          className="ml-1"
        />
      </div>
    ),
  },
  {
    accessorKey: 'trainingControlsCount',
    accessorFn: (row) => row.trainingControlsCount,
    header: 'Total de treinamentos',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="m-auto flex min-w-2 items-center justify-start">
        <TableCellText
          text={'Total de treinamentos:'}
          align="center"
          textAlign="text-center"
          className="min-w-fit font-bold uppercase text-primary"
        />
        <p className="ml-2 rounded bg-[#D1E6FF] px-1.5 py-1 font-bold text-primary">
          {String(props.getValue()).padStart(3, '0')}
        </p>
      </div>
    ),
  },
];
