import { forwardRef, useImperativeHandle, useRef } from 'react';

import { cn } from '@/utils/cn';

interface CheckboxComponentProps {
  defaultChecked?: boolean;
  onChange: any;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  size?: 'default' | 'large';
  variant?: 'default' | 'blue';
}

interface CheckboxRef {
  isChecked: () => boolean;
  setChecked: (value: boolean) => void;
}

const Checkbox = forwardRef<CheckboxRef, CheckboxComponentProps>(
  (
    {
      onChange,
      disabled = false,
      checked,
      size = 'default',
      variant = 'default',
      className,
      ...rest
    },
    ref,
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      isChecked: () => checkboxRef.current?.checked || false,
      setChecked: (value: boolean) => {
        if (checkboxRef.current) {
          checkboxRef.current.checked = value;
        }
      },
    }));

    const sizeClasses = {
      default: 'w-4 h-4',
      large: 'w-5 h-5 cursor-pointer',
    };

    const variantClasses = {
      default: '',
      blue: '[&]:accent-[#193cb9] hover:[&]:accent-[#193cb9]/90',
    };

    return (
      <input
        type="checkbox"
        checked={checked || false}
        ref={checkboxRef}
        onChange={onChange}
        disabled={disabled}
        className={cn(
          sizeClasses[size],
          variantClasses[variant],
          disabled && 'cursor-not-allowed opacity-50',
          className,
        )}
        {...rest}
      />
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
