import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import SearchInput from './SearchInput';

interface EmployeeSearchCardProps {
  searchInput: string;
  handleInputChange: (value: string) => void;
}

const EmployeeSearchCard = ({
  searchInput = '',
  handleInputChange,
}: EmployeeSearchCardProps) => {
  const form = useForm<{
    name: string;
  }>({
    resolver: zodResolver(
      z.object({
        name: z.string(),
      }),
    ),
    defaultValues: {
      name: searchInput,
    },
  });

  useEffect(() => {
    const subscription = form.watch(
      (value, { name, type }) =>
        name === 'name' &&
        type === 'change' &&
        typeof value?.name === 'string' &&
        handleInputChange(value?.name),
    );
    return () => subscription.unsubscribe();
  }, [form, handleInputChange]);

  return (
    <div className="flex w-full items-center justify-center rounded-md bg-white p-4">
      <FormProvider {...form}>
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="w-full">
            <SearchInput
              placeholder="Pesquisar por colaborador"
              autoFocus
              {...form.register('name')}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EmployeeSearchCard;
