import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { TurnstileAccessEmployee } from '@/constants/TurnstileAccessConstants';

import EmptyIcon from '../../assets/icons/empty.svg';
import Spinner from '../atoms/Spinner';
import EmployeeBlockCard from '../molecules/TurnstileAccessCard';

interface TurnstileAccessTabProps {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  employees?: TurnstileAccessEmployee[];
}

const TurnstileAccessTab = ({
  employees,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
}: TurnstileAccessTabProps) => {
  const { ref, inView } = useInView();
  console.log(hasNextPage);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (isLoading) {
    return (
      <div className="h-[85vh] w-[92vw]">
        <div className="my-4 flex h-[100%] w-full items-center rounded-md bg-white">
          <Spinner size={40} />
        </div>
      </div>
    );
  }

  if (!employees || employees.length === 0) {
    return (
      <div className="w-full rounded-md bg-white">
        <div className="flex w-full flex-col items-center justify-center py-10">
          <img src={EmptyIcon} className="w-40" />
          <div className="flex flex-col items-center text-sm">
            <p>Nenhuma bloqueio encontrado com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Quando surgirem novos, poderá encontrá-las aqui!
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-w-full flex-col items-start justify-start rounded-md">
      {employees.map((employee) => (
        <div key={employee.id} className="min-w-full pb-2">
          <EmployeeBlockCard key={employee.id} employee={employee} />
        </div>
      ))}
      {hasNextPage && (
        <div className="flex min-h-8 w-full justify-center" ref={ref}>
          {isFetchingNextPage && <Spinner size={30} />}
        </div>
      )}
    </div>
  );
};

export default TurnstileAccessTab;
