import { RouteObject } from 'react-router-dom';

import Board from '@/pages/Board';
import Chart from '@/pages/Chart';
import { EmployeeTrainings } from '@/pages/EmployeeTrainings';
import FixedAllocation from '@/pages/FixedAllocation';
import ProceedingsTrainingPage from '@/pages/ProcedureTrainingPage';
import RegulatoryTrainingPage from '@/pages/RegulatoryTrainingPage';
import RequirementControlPage from '@/pages/RequirementControlPage';
import TrainingFiles from '@/pages/TrainingFiles';
import TrainingFileTeams from '@/pages/TrainingFileTeams';
import TrainingPlanningPage from '@/pages/TrainingPlanningPage';
import TurnstileBlock from '@/pages/TurnstileAccess';
import ViewJobTitles from '@/pages/ViewJobTitles';

import RootLayout from '../components/templates/RootLayout';
import Employees from '../pages/Employees';
import Home from '../pages/Home';
import HomeData from '../pages/HomeData';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Pendencies from '../pages/Pendencies';
import SuppliersView from '../pages/SuppliersView';
import TeamsEdit from '../pages/TeamsEdit';
import TeamsRegister from '../pages/TeamsRegister';
import TeamsRegisterPreview from '../pages/TeamsRegisterPreview';
import TrainingControlPage from '../pages/TrainingControlPage';
// TODO: DESCOMENTAR QUANDO SUBIR IDA EXTRA
// import ViewExtraTrips from '../pages/ViewExtraTrips';
import ViewSchedule from '../pages/ViewSchedule';

const pages: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/home',
        element: <HomeData />,
      },
      {
        path: '/home/planejamento-de-escalas-e-ferias',
        element: <ViewSchedule />,
      },
      {
        path: '/home/cargos-e-requisitos',
        element: <ViewJobTitles />,
      },
      {
        path: '/home/controle-de-requisitos',
        element: <RequirementControlPage />,
      },
      {
        path: '/home/visualizar-pendencias',
        element: <Pendencies />,
      },
      {
        path: '/home/colaboradores',
        element: <Employees />,
      },
      // {
      //   path: '/home/colaborador/:employeeId',
      //   element: <Employee />,
      // },
      {
        path: '/home/colaborador/:employeeId/historico-treinamentos',
        element: <EmployeeTrainings />,
      },
      {
        path: '/home/planejamento-de-escalas-e-ferias/cadastrar-estrutura-de-turma',
        element: <TeamsRegister />,
      },
      {
        path: '/home/planejamento-de-escalas-e-ferias/cadastrar-estrutura-de-turma/preview',
        element: <TeamsRegisterPreview />,
      },
      {
        path: '/home/planejamento-de-escalas-e-ferias/editar-estrutura-de-turma',
        element: <TeamsEdit />,
      },
      // {
      //   path: '/home/solicitar-realocacao',
      //   element: <AllocationRequestRegister />,
      // },
      // {
      //   path: '/home/solicitar-realocacao/:id',
      //   element: <AllocationRequestRegister />,
      // },
      // {
      //   path: '/home/realocacoes',
      //   element: <AllocationRequestHistory />,
      // },
      {
        path: '/home/matriz-treinamento-regulatorio',
        element: <RegulatoryTrainingPage />,
      },
      {
        path: '/home/matriz-treinamento-procedimento',
        element: <ProceedingsTrainingPage />,
      },
      {
        path: '/home/controle-treinamentos',
        element: <TrainingControlPage />,
      },
      {
        path: '/home/ficheiros/:trainingFileId/planejamento-de-turma',
        element: <TrainingPlanningPage />,
      },
      {
        path: '/home/ficheiros/:trainingFileId/planejamento-de-turma/:trainingPlanningId',
        element: <TrainingPlanningPage />,
      },
      // TODO: DESCOMENTAR QUANDO SUBIR IDA EXTRA
      // {
      //   path: '/home/idas-extras',
      //   element: <ViewExtraTrips />,
      // },
      {
        path: '/home/visualizar-fornecedores',
        element: <SuppliersView />,
      },
      {
        path: '/home/ficheiros',
        element: <TrainingFiles />,
      },
      {
        path: '/home/ficheiros/detalhamento/:id/',
        element: <TrainingFileTeams />,
      },
      {
        path: '/home/visualizar-estrutura-organograma',
        element: <Chart />,
      },
      {
        path: '/home/cadastro-alocacoes-fixas',
        element: <FixedAllocation />,
      },
      {
        path: '/home/cadastro-de-diretoria',
        element: <Board />,
      },
      {
        path: '/home/bloqueios-catraca',
        element: <TurnstileBlock />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
];

export default pages;
