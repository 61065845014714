import { CircularProgress, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import FileUploadComponent from '@/components/atoms/FileUpload';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellStatus from '@/components/atoms/TableCellStatus';
import TableCellText from '@/components/atoms/TableCellText';
import { ViewFiles } from '@/components/atoms/ViewFiles';
import TrainingControlGroupedModal from '@/components/organisms/TrainingControlGroupedModal';
import api from '@/services/apiSgft';
import { EvidenceFile } from '@/types/EvidenceFile';
import {
  TrainingControlEmployee,
  TrainingControlItem,
} from '@/types/TrainingControlGrouped';
import { ExpirationStatus } from '@/types/TrainingControlTypes';
import { removeTimeZone } from '@/utils/formatDate';

import { Modality } from '../TrainingConstants';

export const columns: (
  employeeName: TrainingControlEmployee,
) => Array<ColumnDef<TrainingControlItem>> = (
  employee: TrainingControlEmployee,
) => [
  editColumn(employee),
  trainingTypeColumn,
  trainingNameColumn,
  totalWorkloadColumn,
  validityColumn,
  modalityColumn,
  registerDateColumn,
  presencialCompletionDateColumn,
  onlineCompletionDateColumn,
  presencialCompletionStatusColumn,
  onlineCompletionStatusColumn,
  expirationStatusColumn,
  updatedAtColumn,
  presencialEquivalentHistoricIdColumn(employee.name),
  onlineEquivalentHistoricIdColumn(employee.name),
];

const editColumn: (
  employee: TrainingControlEmployee,
) => ColumnDef<TrainingControlItem> = (employee: TrainingControlEmployee) => {
  return {
    id: 'edit',
    meta: {
      headerClassName: 'sticky left-0 z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky left-0 z-10 bg-inherit',
    },
    header: '',
    cell: (value) => {
      const [isTrainingControlModalOpen, setIsTrainingControlModalOpen] =
        useState(false);

      const handleOpenModal = () => {
        setIsTrainingControlModalOpen(true);
      };

      return (
        <div className="w-16">
          <IconButton
            sx={{
              margin: 1,
              height: '1.8rem',
              width: '1.8rem',
              background: '#1F3FA8',
              borderRadius: '100%',
              '&:hover': {
                background: '#4c4c73',
              },
              '&:disabled': {
                background: '#9ca3af',
              },
            }}
            aria-label="export"
            onClick={handleOpenModal}
            disabled={isTrainingControlModalOpen}
          >
            <BsPencilSquare color="white" height={'7px'} width={'7px'} />
          </IconButton>
          {isTrainingControlModalOpen && (
            <TrainingControlGroupedModal
              employee={employee}
              trainingControlItem={value.row.original}
              modalIsOpen={isTrainingControlModalOpen}
              handleClose={() => {
                setIsTrainingControlModalOpen(false);
              }}
            />
          )}
        </div>
      );
    },
  };
};

const modalityColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.trainingModality,
  accessorKey: 'trainingModality',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      MODALIDADE
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center justify-center pl-2 pr-2 text-left">
      <TableCellText
        text={props.getValue() as string}
        width="12rem"
        className="uppercase"
        textAlign="text-left"
      />
    </div>
  ),
};

const registerDateColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.registerDate,
  accessorKey: 'registerDate',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      DATA DE REGISTRO
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => {
    const emissionDate =
      props.getValue() && props.getValue() !== '-'
        ? removeTimeZone(new Date(props.getValue() as string))
        : undefined;

    return (
      <div className="flex w-[10rem]">
        <TableCellDate date={emissionDate} className="flex w-full" />
      </div>
    );
  },
};

const presencialCompletionDateColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.presencialCompletionDate,
  accessorKey: 'presencialCompletionDate',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      CONCLUSÃO
      <br />
      (PRESENCIAL)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => {
    const presencialCompletionDate =
      props.getValue() && props.getValue() !== '-'
        ? removeTimeZone(new Date(props.getValue() as string))
        : undefined;

    return (
      <div className="flex w-[10rem]">
        <TableCellDate
          date={presencialCompletionDate}
          className="flex w-full"
        />
      </div>
    );
  },
};

const onlineCompletionDateColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.onlineCompletionDate,
  accessorKey: 'onlineCompletionDate',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      CONCLUSÃO
      <br />
      (ONLINE)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => {
    const onlineCompletionDate =
      props.getValue() && props.getValue() !== '-'
        ? removeTimeZone(new Date(props.getValue() as string))
        : undefined;

    return (
      <div className="flex w-[10rem]">
        <TableCellDate date={onlineCompletionDate} className="flex w-full" />
      </div>
    );
  },
};

const presencialCompletionStatusColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.presencialCompletionStatus,
  accessorKey: 'presencialCompletionStatus',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      SITUAÇÃO DE
      <br />
      TREINAMENTO
      <br />
      (PRESENCIAL)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center justify-center pl-2 pr-2 text-left">
      <TableCellText
        text={props.getValue() as string}
        width="12rem"
        className="uppercase"
        textAlign="text-left"
      />
    </div>
  ),
};

const onlineCompletionStatusColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.onlineCompletionStatus,
  accessorKey: 'onlineCompletionStatus',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      SITUAÇÃO DE
      <br />
      TREINAMENTO
      <br />
      (ONLINE)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center justify-center pl-2 pr-2 text-left">
      <TableCellText
        text={props.getValue() as string}
        width="12rem"
        className="uppercase"
        textAlign="text-left"
      />
    </div>
  ),
};

const expirationStatusColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.expirationStatus,
  accessorKey: 'expirationStatus',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      SITUAÇÃO DE
      <br />
      VALIDADE
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center pl-2 pr-2 text-left">
      <TableCellStatus
        status={props.getValue() as ExpirationStatus}
        upperCase={true}
      />
    </div>
  ),
};

const updatedAtColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.updatedAt,
  accessorKey: 'updatedAt',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      ÚLTIMA
      <br />
      MODIFICAÇÃO
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => {
    const updatedAt =
      props.getValue() && props.getValue() !== '-'
        ? removeTimeZone(new Date(props.getValue() as string))
        : undefined;

    return (
      <div className="flex w-[10rem] items-start text-left">
        <TableCellDate date={updatedAt} className="w-full" />
      </div>
    );
  },
};

const trainingNameColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.training.name,
  accessorKey: 'trainingName',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      TREINAMENTO
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[14rem] items-center justify-center pl-2 pr-2 text-left">
      <TableCellText
        text={props.getValue() as string}
        align="start"
        textAlign="text-left"
        width="14rem"
        className="uppercase"
      />
    </div>
  ),
};

const totalWorkloadColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.training.totalWorkload,
  accessorKey: 'totalWorkload',
  header: () => (
    <div
      style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
    >
      CARGA HORÁRIA (H)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center justify-center pl-2 pr-2 text-center">
      <TableCellText
        text={props.getValue() as string}
        width="15rem"
        textAlign="text-center"
      />
    </div>
  ),
};

const validityColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.training.validity,
  accessorKey: 'validity',
  header: () => (
    <div
      style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
    >
      VALIDADE (ANOS)
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[12rem] items-center justify-center pl-2 pr-2 text-center">
      <TableCellText
        text={props.getValue() as string}
        width="15rem"
        textAlign="text-center"
      />
    </div>
  ),
};

const trainingTypeColumn: ColumnDef<TrainingControlItem> = {
  accessorFn: (row) => row.training.trainingType,
  accessorKey: 'trainingType',
  header: () => (
    <div style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}>
      TIPO DE
      <br />
      TREINAMENTO
    </div>
  ),
  meta: {
    headerClassName: 'text-start text-black font-medium',
  },
  cell: (props) => (
    <div className="flex w-[10rem] items-center justify-center pl-2 pr-2 text-left">
      <TableCellText
        text={props.getValue() as string}
        width="10rem"
        className="uppercase"
        textAlign="text-left"
      />
    </div>
  ),
};

const presencialEquivalentHistoricIdColumn: (
  employeeName: string,
) => ColumnDef<TrainingControlItem> = (employeeName: string) => {
  return {
    accessorFn: (row) => row.presencialEquivalentHistoric?.id,
    accessorKey: 'presencialEquivalentHistoricId',
    header: () => (
      <div
        style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}
      >
        UPLOAD DE
        <br />
        EVIDÊNCIAS
        <br />
        (PRESENCIAL)
      </div>
    ),
    meta: {
      headerClassName: 'text-start text-black font-medium',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const historicId = props.row.original?.presencialEquivalentHistoric?.id;
      const fetchEvidences = async () => {
        if (!historicId) {
          return [];
        }

        try {
          const response = await api.get(
            `training-control/training-control-history/${historicId}/evidences/`,
          );

          return response.data.map((el: File) => {
            return {
              ...el,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };

      const { data: files, isFetching: isFetchingFiles } = useQuery<
        EvidenceFile[]
      >(['get-evidence-files', historicId], fetchEvidences, {
        retry: false,
        refetchOnMount: false,
      });

      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        try {
          await api.post(
            `/training-control/${props.row.original.id}/upload-evidence/${Modality.Presencial}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-control'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }

      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      if (props.row.original.presencialCompletionDate !== '-') {
        return (
          <div className="ml-1 flex w-[10rem] flex-row items-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <>
                <FileUploadComponent
                  onFilesSelected={(filesList: FileList | null) => {
                    const filesArray = filesList ? Array.from(filesList) : [];
                    handleFilesSelected(
                      filesArray,
                      String(props.row.original.id),
                    );
                  }}
                  rowId={`${String(props.row.original.id)}-presencial`}
                />
                <ViewFiles
                  files={files ?? []}
                  onDeleteFunction={handleDeleteEvidence}
                  deleteFileDescrption={`do histórico do colaborador ${employeeName} não poderá ser recuperado`}
                  isLoading={isFetchingFiles}
                />
              </>
            )}
          </div>
        );
      }
    },
  };
};

const onlineEquivalentHistoricIdColumn: (
  employeeName: string,
) => ColumnDef<TrainingControlItem> = (employeeName: string) => {
  return {
    accessorFn: (row) => row.onlineEquivalentHistoric?.id,
    accessorKey: 'onlineEquivalentHistoricId',
    header: () => (
      <div
        style={{ display: 'inline-block', textAlign: 'left', width: '100%' }}
      >
        UPLOAD DE
        <br />
        EVIDÊNCIAS
        <br />
        (ONLINE)
      </div>
    ),
    meta: {
      headerClassName: 'text-start text-black font-medium',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const historicId = props.row.original?.onlineEquivalentHistoric?.id;

      const fetchEvidences = async () => {
        if (!historicId) {
          return [];
        }

        try {
          const response = await api.get(
            `training-control/training-control-history/${historicId}/evidences/`,
          );

          return response.data.map((el: File) => {
            return {
              ...el,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };

      const { data: files, isFetching: isFetchingFiles } = useQuery<
        EvidenceFile[]
      >(['get-evidence-files', historicId], fetchEvidences, {
        retry: false,
        refetchOnMount: false,
      });

      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        try {
          await api.post(
            `/training-control/${props.row.original.id}/upload-evidence/${Modality.Online}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-control'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }

      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      if (props.row.original.presencialCompletionDate !== '-') {
        return (
          <div className="ml-1 flex w-[10rem] flex-row items-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <>
                <FileUploadComponent
                  onFilesSelected={(filesList: FileList | null) => {
                    const filesArray = filesList ? Array.from(filesList) : [];
                    handleFilesSelected(
                      filesArray,
                      String(props.row.original.id),
                    );
                  }}
                  rowId={`${String(props.row.original.id)}-presencial`}
                />
                <ViewFiles
                  files={files ?? []}
                  onDeleteFunction={handleDeleteEvidence}
                  deleteFileDescrption={`do histórico do colaborador ${employeeName} não poderá ser recuperado`}
                  isLoading={isFetchingFiles}
                  queryKey={['get-evidence-files', historicId]}
                />
              </>
            )}
          </div>
        );
      }
    },
  };
};
