import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { MassEvidenceExtractionButton } from '@/components/atoms/MassEvidenceExtractionButton';
import NoPermissionText from '@/components/atoms/NoPermissionText';
import Spinner from '@/components/atoms/Spinner';
import TrainingControlGroupedCard from '@/components/organisms/TrainingControlGroupedCard';
import { TrainingControlGrouped } from '@/types/TrainingControlGrouped';

import EmptyIcon from '../../assets/icons/empty.svg';
import Cards from '../../components/molecules/Cards';
import ExpandableSubtitle from '../../components/molecules/ExpandableSubtitle';
import SideFilter from '../../components/molecules/SideFilter';
import { TrainingControlDownloadButton } from '../../components/molecules/TrainingControlDownloadButton';
import { TrainingControlHistoryDownloadButton } from '../../components/molecules/TrainingControlHistoryDownloadButton';
import { TrainingControlUploadButton } from '../../components/molecules/TrainingControlUploadButton';
import ViewScheduleWrapper from '../../components/molecules/ViewScheduleWrapper';
import { filters } from '../../constants/filters/TrainingControlFilter';
import api from '../../services/apiSgft';
import { trainingControlAtom } from '../../state/TrainingControl.atom';
import { Indicators } from '../../types/TrainingControl';
import {
  userCanSeeTrainingControl,
  userCanSeeTrainingControlButtons,
} from '../../utils/handleSavePermissions';
import { TrainingControlTemplateButton } from './TrainingControlTemplateButton/TrainingControlTemplateButton';

const TrainingControlPage = () => {
  const [filterValues, setFilteredValues] = useRecoilState(trainingControlAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<Indicators>();
  const isRhOrAdmin = userCanSeeTrainingControlButtons();
  const { ref, inView } = useInView();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const training = searchParams.get('treinamento');
    const employee = searchParams.get('colaborador');

    setFilteredValues((prev) => ({
      ...prev,
      training: training ? [training] : prev.training,
      employee: employee ? [employee] : prev.employee,
    }));

    setFilteredData((prev) => ({
      ...prev,
      training: training ? [training] : prev.training,
      employee: employee ? [employee] : prev.employee,
    }));

    searchParams.delete('treinamento');
    searchParams.delete('colaborador');
    setSearchParams(searchParams);
  }, []);

  const fetchControl = async ({
    pageParam = 1,
  }): Promise<{
    count: number;
    nextPage: number | null;
    groupedTrainingControls: TrainingControlGrouped[];
  }> => {
    try {
      const response = await api.get(`training-control/grouped`, {
        params: {
          size: 20,
          page: pageParam,
          employee: filteredData.employee,
          role: filteredData.role,
          rmStatus: filteredData.rmStatus,
          training: filteredData.training,
          presencialCompletionStatus: filteredData.presencialCompletionStatus,
          onlineCompletionStatus: filteredData.onlineCompletionStatus,
          expirationStatus: filteredData.expirationStatus,
          management: filteredData.management,
          areaCoordinator: filteredData.areaCoordinator,
          pole: filteredData.pole,
          workstation: filteredData.workstation,
          trainingType: filteredData.trainingType,
          trainingPole: filteredData.trainingPole,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['training-control', filteredData], fetchControl, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        employee: [],
        role: [],
        rmStatus: [],
        training: [],
        presencialCompletionStatus: [],
        onlineCompletionStatus: [],
        expirationStatus: [],
        management: [],
        areaCoordinator: [],
        trainingType: [],
        trainingPole: [],
        pole: [],
        workstation: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  const groupedTrainingControls = data?.pages
    ? data.pages.flatMap((page) => page.groupedTrainingControls || [])
    : [];

  const filteredFilters = isRhOrAdmin
    ? filters
    : filters.filter((filter) => filter.key !== 'management');

  useEffect(() => {
    const getIndicators = async () => {
      try {
        const response = await api.get('training-control/indicators', {
          params: {
            employee: filteredData.employee,
            role: filteredData.role,
            rmStatus: filteredData.rmStatus,
            training: filteredData.training,
            presencialCompletionStatus: filteredData.presencialCompletionStatus,
            onlineCompletionStatus: filteredData.onlineCompletionStatus,
            expirationStatus: filteredData.expirationStatus,
            management: filteredData.management,
            areaCoordinator: filteredData.areaCoordinator,
            trainingType: filteredData.trainingType,
            trainingPole: filteredData.trainingPole,
            pole: filteredData.pole,
            workstation: filteredData.workstation,
          },
        });
        setIndicators(response.data);
      } catch (e) {
        toast.error('Erro ao carregar indicadores', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    };
    getIndicators();
  }, [filteredData]);

  if (!userCanSeeTrainingControl()) return <NoPermissionText />;

  return (
    <div className="relative flex h-full max-h-screen w-full flex-col items-start">
      {isRhOrAdmin && (
        <div className="absolute -top-10 right-20 flex items-center">
          <TrainingControlTemplateButton />
          <TrainingControlUploadButton />
          <TrainingControlDownloadButton filteredData={filteredData} />
          <TrainingControlHistoryDownloadButton filteredData={filteredData} />
          <MassEvidenceExtractionButton />
        </div>
      )}
      <SideFilter
        refetchOnChange
        filters={filteredFilters}
        atom={trainingControlAtom}
        applyChanges={handleApplyFilter}
      />
      <ViewScheduleWrapper
        isError={isError}
        isLoading={isLoading}
        className="max-w-[100%]"
      >
        <div className="w-full">
          {indicators?.topTrainings && (
            <ExpandableSubtitle
              subtitle={'Pendências por Treinamento'}
              hasTooltip
              tooltipText="Top 5 dos treinamentos com maiores quantidades de treinamentos com situação de vencimento igual a vencido, inválido ou pendente"
            >
              {indicators?.topTrainings.length > 0 ? (
                <Cards
                  cards={indicators?.topTrainings.map((el) => ({
                    value: el.count,
                    status: 'grey',
                    title: el.name,
                  }))}
                />
              ) : (
                <div className="p-2 font-graphie text-[15px] text-[#4A4A4A]">
                  Não existem treinamentos com pendências
                </div>
              )}
            </ExpandableSubtitle>
          )}
          {indicators?.statusCounts && (
            <ExpandableSubtitle subtitle={'Treinamentos por Situação'}>
              <Cards
                cards={[
                  {
                    value: indicators.statusCounts.Pendentes,
                    status: 'red',
                    title: 'Pendentes',
                  },
                  {
                    value: indicators.statusCounts.Invalidos,
                    status: 'red',
                    title: 'Invalidados',
                  },
                  {
                    value: indicators.statusCounts.Vencidos,
                    status: 'red',
                    title: 'Vencidos',
                  },
                  {
                    value: indicators.statusCounts.VenceEm1Mes,
                    status: '#dbdb00',
                    title: 'Vence em 1 mês',
                  },
                  {
                    value: indicators.statusCounts.VenceEm3Meses,
                    status: '#dbdb00',
                    title: 'Vence em 3 meses',
                  },
                  {
                    value: indicators.statusCounts.Validos,
                    status: 'green',
                    title: 'Válidos',
                  },
                ]}
              />
            </ExpandableSubtitle>
          )}
        </div>
        <div className="custom-scrollbar relative overflow-x-auto">
          {groupedTrainingControls?.length > 0 ? (
            <div className="mr-2 flex w-max min-w-full flex-col items-start justify-end rounded-md">
              {groupedTrainingControls?.map(
                (employeeWithTrainingControls: TrainingControlGrouped) => {
                  return (
                    <div
                      key={employeeWithTrainingControls.employee.id}
                      className="w-full pb-2 pr-2"
                    >
                      <TrainingControlGroupedCard
                        employeeWithTrainingControls={
                          employeeWithTrainingControls
                        }
                      />
                    </div>
                  );
                },
              )}
              {hasNextPage && (
                <div className="flex min-h-8 w-[92vw] justify-center" ref={ref}>
                  {isFetchingNextPage && <Spinner size={30} />}
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Nenhum treinamento foi encontrado com esses parâmetros</p>
                <span className="font-semibold text-blue-800">
                  Cadastre um ou altere os valores dos filtros para visualizar a
                  tabela!
                </span>
              </div>
            </div>
          )}
        </div>
      </ViewScheduleWrapper>
    </div>
  );
};

export default TrainingControlPage;
