import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';

export interface PositionTrainingMatrixFilters
  extends Record<string, string[] | number[]> {
  trainingType: string[];
  location: string[];
  position: string[] | number[];
  training: string[];
  management: string[];
  areaCoordination: string[];
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;
const stringOrNumberArrayChecker = r.or(
  r.array(r.string()),
  r.array(r.number()),
) as r.Checker<string[] | number[]>;

const positionTrainingMatrixFiltersAtom = atom<PositionTrainingMatrixFilters>({
  key: 'positionTrainingMatrixFilters',
  default: {
    trainingType: [TrainingTypeEnum.REGULATORY],
    location: [],
    management: [],
    areaCoordination: [],
    position: [],
    training: [],
  },

  effects: [
    urlSyncEffect<PositionTrainingMatrixFilters>({
      refine: r.object({
        trainingType: stringArrayChecker,
        location: stringArrayChecker,
        management: stringArrayChecker,
        areaCoordination: stringArrayChecker,
        position: stringOrNumberArrayChecker,
        training: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});

export { positionTrainingMatrixFiltersAtom };
