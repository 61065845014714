import { Team } from '../types/ViewSchedule';

interface RGB {
  r: number;
  g: number;
  b: number;
}

const colors: Record<Team, string> = {
  T1: 'bg-[#193DB8]',
  T1D: 'bg-[#193DB8]',
  T1N: 'bg-[#193DB8]',
  T2: 'bg-[#1FBCDC]',
  T2D: 'bg-[#1FBCDC]',
  T2N: 'bg-[#1FBCDC]',
  T3: 'bg-[#a4c2f4]',
  T3D: 'bg-[#a4c2f4]',
  T3N: 'bg-[#a4c2f4]',
  T4: 'bg-[#A28776]',
  T4D: 'bg-[#A28776]',
  T4N: 'bg-[#A28776]',
  ADM: 'bg-[#193DB8]',
  FE: 'bg-[#F9CF3E]',
  'FE !': 'bg-[#F9AA3E]',
  FO: 'bg-[#C1C1C1]',
  FD: 'bg-[#C1C1C1]',
  LC: 'bg-[#E2E2E2]',
  CL: 'bg-[#386E3A]',
  CFD: 'bg-[#45A347]',
  CFN: 'bg-[#45A347]',
  // FNJ: 'bg-[#E07E4C]',
  // ABN: 'bg-[#E07E4C]',
  TR: 'bg-[#CB67E3]',
  SA: 'bg-[#193db8]',
  'SA*': 'bg-[#193db8]',
  'T1*': 'bg-[#193DB8]',
  IE: 'bg-[#8A63C7]',
  'N/A': 'bg-transparent',
  '-': 'bg-transparent',
};

function extractHexColor(bgColorClass: string): string {
  return bgColorClass.replace('bg-[', '').replace(']', '');
}

function hexToRgb(hex: string): RGB {
  hex = hex.replace('#', '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function getBrightness({ r, g, b }: RGB): number {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

function getTextColorByBackgroundColor(hex: string): string {
  if (hex.indexOf('#') === -1) return 'text-black';
  const hexExtracted = extractHexColor(hex);
  const rgb = hexToRgb(hexExtracted);
  const brightness = getBrightness(rgb);
  return brightness > 128 ? 'text-black' : 'text-white';
}

export const getColorByTeamName = (teamName: Team, needTextColor?: boolean) => {
  if (Object.keys(colors).includes(teamName)) {
    const bgColor = colors[teamName];
    return needTextColor
      ? `${colors[teamName]} ${getTextColorByBackgroundColor(bgColor)}`
      : colors[teamName];
  }
  return needTextColor ? 'bg-white text-black' : 'bg-white';
};
