import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';

import { columns } from '@/constants/tableColumns/TurnstileAccessTrainingColumns';
import { TurnstileAccessEmployee } from '@/constants/TurnstileAccessConstants';
import { turnstileAccessFilterAtom } from '@/state/TurnstileAccessFilter.atom';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import LightTooltip from '../atoms/LightTooltip';
import { TurnstileAccessCardInfo } from '../atoms/TurnstileAccessCardInfo';
import Table from '../organisms/Table';

const TurnstileAccessCard = ({
  employee,
}: {
  employee: TurnstileAccessEmployee;
}) => {
  const filteredData = useRecoilValue(turnstileAccessFilterAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = () => {
    setTooltipEnabled(true);
  };

  const handleToggleCard = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="flex min-w-full gap-2">
      <div
        className={`flex w-[30rem] ${isOpen ? 'items-start pt-5' : 'items-center'} justify-start rounded-md bg-white p-4 shadow-md`}
      >
        <button onClick={handleToggleCard}>
          <FaChevronDown
            className={`mr-2 h-3 w-3 transition-transform ${isOpen && 'rotate-180'} text-primary`}
          />
        </button>
        <div className="flex gap-4 whitespace-nowrap text-sm text-gray-700">
          <LightTooltip
            title={employee.name}
            arrow
            placement="top"
            open={tooltipEnabled}
            onClose={() => setTooltipEnabled(false)}
          >
            <div onMouseEnter={handleShouldShow}>
              <TurnstileAccessCardInfo
                label="NOME"
                value={employee.alias || getFirstAndLastName(employee.name)}
              />
            </div>
          </LightTooltip>
          <TurnstileAccessCardInfo
            label="CHAPA"
            value={employee.employeeNumber}
          />
        </div>
      </div>
      <div className="flex-1 rounded-md bg-white shadow-md">
        <div className="flex items-center justify-start p-4">
          <div className="flex gap-4 overflow-x-auto whitespace-nowrap text-sm text-gray-700">
            <TurnstileAccessCardInfo
              label="BLOQUEIO"
              value="TREINAMENTO"
              valueClassName="w-24 lg:w-28"
            />
            <TurnstileAccessCardInfo
              label="TOTAL DE PENDÊNCIAS"
              value={employee.trainings.length.toString()}
              valueClassName="size-6 flex items-center justify-center rounded-md border bg-[#d1e6ff] p-1 font-bold text-primary lg:mr-8 lg:size-6"
            />
            <TurnstileAccessCardInfo label="FUNÇÃO" value={employee.jobTitle} />
            <TurnstileAccessCardInfo
              label="LOCAL DE TRABALHO"
              value={employee.workStation}
            />
            <TurnstileAccessCardInfo label="DIRETORIA" value={employee.board} />
            <TurnstileAccessCardInfo
              label="GERÊNCIA"
              value={employee.management}
            />
            <TurnstileAccessCardInfo
              label="COORDENAÇÃO"
              value={employee.coordination}
            />
          </div>
        </div>
        {isOpen && (
          <div className="min-h-24 px-4 py-2">
            <Table
              columns={columns(employee.id, filteredData.type)}
              data={employee.trainings}
              tBodyClassname="border-none "
              trBodyClassname="border-none"
              tdHeaderClassname="bg-transparent text-[#4B4B4B]"
              trHeaderClassname="bg-white my-4 rounded-md"
              tdClassname="px-0 bg-background border-b-8 border-white"
              containerClassname="border-separate border-spacing-y-4"
              isWhiteTable
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TurnstileAccessCard;
