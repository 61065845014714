import { ColumnDef } from '@tanstack/react-table';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import TableCellText from '@/components/atoms/TableCellText';
import TableData from '@/components/atoms/TableData';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

export const columns: (
  isOpen: boolean,
  handleToggleCard: () => void,
) => Array<
  ColumnDef<{
    id: number;
    name: string;
    alias?: string;
    employeeNumber: string;
  }>
> = (isOpen: boolean, handleToggleCard: () => void) => [
  {
    accessorKey: 'chevron',
    accessorFn: () => undefined,
    header: '',
    meta: {
      headerClassName: 'bg-white text-start px-4',
    },
    cell: () => {
      return (
        <button
          onClick={handleToggleCard}
          className="sticky left-4 z-[100] flex w-[40px] items-center justify-center bg-white pt-[2px]"
        >
          <FaChevronDown
            className={`fill-primary transition-all ${isOpen && 'rotate-180'}`}
          />
        </button>
      );
    },
  },
  {
    accessorKey: 'name',
    accessorFn: (row) => row.alias ?? row.name,
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-start',
    },
    cell: (props) => {
      return (
        <div className={`flex w-full items-center justify-start`}>
          <TableCellText
            text={'Nome:'}
            align="start"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="5rem"
          />
          <Link
            to={`/home/colaborador/${props.row.original.id.toString()}/historico-treinamentos`}
            className="cursor-pointer"
          >
            <TableData
              tableValue={getFirstAndLastName(props.getValue() as string)}
              className="flex h-[32px] w-36 items-center justify-start whitespace-nowrap text-[11px] font-semibold underline"
              title={props.getValue() as string}
            />
          </Link>
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    accessorFn: (row) => row.employeeNumber,
    header: 'Chapa',
    meta: {
      headerClassName: 'bg-white text-start',
    },
    cell: (props) => {
      return (
        <div className={`flex items-center justify-start`}>
          <TableCellText
            text={'Chapa:'}
            align="center"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="4rem"
          />
          <TableCellText
            text={props.getValue() as string}
            align="start"
            textAlign="text-left"
            width="5rem"
          />
        </div>
      );
    },
  },
];
