import { useInfiniteQuery } from '@tanstack/react-query';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ExtractProcedureTrainingMatrixButton } from '@/components/atoms/ExtractProcedureTraningMatrixButton';
import Spinner from '@/components/atoms/Spinner';
import { UploadProcedureTrainingMatrixButton } from '@/components/atoms/UploadProcedureTrainingMatrixButton';
import Cards from '@/components/molecules/Cards';
import ExpandableSubtitle from '@/components/molecules/ExpandableSubtitle';
import ProcedureTrainingMatrix from '@/components/organisms/ProcedureTrainingMatrix';
import { procedureFilters } from '@/constants/filters/ProcedureTrainingMatrixFilter';
import { TrainingType } from '@/constants/TrainingPlanning';
import {
  ProcedureTrainingFilters,
  procedureTrainingFiltersAtom,
} from '@/state/ProcedureTraining.atom';
import {
  PendingBlockProcedureMatrixChanges,
  PendingProcedureMatrixChanges,
  ProcedureIndicators,
  ProcedureTrainingMatrix as ProcedureTrainingMatrixType,
} from '@/types/Training';

import { Button } from '../../components/atoms/Button';
import SideFilter from '../../components/molecules/SideFilter';
import TrainingModal from '../../components/organisms/TrainingModal';
import api from '../../services/apiSgft';
import { userCanSaveTraining } from '../../utils/handleSavePermissions';

const ProcedureTrainingPage = () => {
  const [data, setData] = useState<ProcedureTrainingMatrixType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilteredValues] = useRecoilState(
    procedureTrainingFiltersAtom,
  );
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<ProcedureIndicators>();
  const [matrixRefetch, setMatrixRefetch] = useState(false);
  const [isNewTrainingModalOpen, setIsNewTraininigModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMappingMode, setIsMappingMode] = useState(false);
  const [pendingMatrixChanges, setPendingMatrixChanges] =
    useState<PendingProcedureMatrixChanges>({ manualChanges: {} });
  const [pendingBlockMatrixChanges, setPendingBlockMatrixChanges] =
    useState<PendingBlockProcedureMatrixChanges>({});
  const [isPositionFilteringIndicator, setIsPositionFilteringIndicator] =
    useState(false);
  const [isTrainingFilteringIndicator, setIsTrainingFilteringIndicator] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMatrixChanging, setIsMatrixChanging] = useState(false);

  useEffect(() => {
    const location = searchParams.get('polo');
    const training = searchParams.get('treinamento');
    const jobTitle = searchParams.get('funcao');

    setFilteredValues((prev) => ({
      ...prev,
      locations: location ? [location] : prev.locations,
      trainingNames: training ? [training] : prev.trainingNames,
      jobTitleIds: jobTitle ? [Number(jobTitle)] : prev.jobTitleIds,
    }));
    setFilteredData((prev) => ({
      ...prev,
      locations: location ? [location] : prev.locations,
      trainingNames: training ? [training] : prev.trainingNames,
      jobTitleIds: jobTitle ? [Number(jobTitle)] : prev.jobTitleIds,
    }));
    searchParams.delete('polo');
    searchParams.delete('treinamento');
    searchParams.delete('funcao');
    setSearchParams(searchParams);
  }, []);
  const resetFilters = useResetRecoilState(procedureTrainingFiltersAtom);

  const [modifiedCheckboxesMap, setModifiedCheckboxesMap] = useState<
    Map<string, boolean>
  >(new Map());
  const [modifiedBlockCheckboxesMap, setModifiedBlockCheckboxesMap] = useState<
    Map<string, boolean>
  >(new Map());

  const toggleEditMode = async () => {
    if (isEditMode) {
      const hasMassiveChanges =
        pendingMatrixChanges?.massiveChecked &&
        Object.keys(pendingMatrixChanges.massiveChecked).length > 0;
      const hasManualChanges =
        pendingMatrixChanges?.manualChanges &&
        Object.keys(pendingMatrixChanges.manualChanges).length > 0;

      if (hasMassiveChanges || hasManualChanges) {
        await handleSendMappingChanges();
      }

      if (Object.keys(pendingBlockMatrixChanges).length > 0) {
        await handleSendBlockMappingChanges();
      }
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  };

  const fetchTrainingMatrix = async ({
    pageParam = 1,
  }): Promise<{
    data: ProcedureTrainingMatrixType;
    count: number;
    nextPage: number | null;
  }> => {
    if (pageParam > 1) {
      setIsMatrixChanging(true);
    }
    try {
      const response = await api.get(
        `procedure-training-matrix/matrix?size=20&page=${pageParam}`,
        {
          params: {
            jobTitleIds: filteredData.jobTitleIds,
            trainingNames: filteredData.trainingNames,
            workStationIds: filteredData.workStationIds,
            coordinationIds: filteredData.coordinationIds,
            management: filteredData.management,
            locations: filteredData.locations,
            onlyUnmappedPositions: isPositionFilteringIndicator,
            onlyUnmappedTrainings: isTrainingFilteringIndicator,
          },
        },
      );
      fetchIndicators(filterValues);
      return response.data;
    } catch (error) {
      if (pageParam > 1) {
        setIsMatrixChanging(false);
      }
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-${'procedure-training-matrix/matrix'}`,
      });
      throw error;
    }
  };

  const {
    isLoading: isLoadingTrainingsMatrix,
    isError: isErrorTrainingsMatrix,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['procedure-trainings-matrix', filteredData, matrixRefetch],
    fetchTrainingMatrix,
    {
      retry: false,
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
      onSuccess: (queryData) => {
        const processedData = queryData.pages
          .flatMap((page) => page.data)
          .reduce(
            (acc, curr) => {
              if (curr?.matrix?.length > 0) {
                const validIndexes = curr.matrix
                  .map((position, index) =>
                    position.coordination !== null &&
                    position.management !== null &&
                    position.jobTitle !== null
                      ? index
                      : -1,
                  )
                  .filter((index) => index !== -1);

                const filteredMatrix = validIndexes.map(
                  (index) => curr.matrix[index],
                );

                acc.matrix = [...new Set([...acc.matrix, ...filteredMatrix])];

                acc.trainings = Object.fromEntries(
                  Object.entries(curr.trainings)
                    .filter(([, training]) => training)
                    .map(([key, training]) => [key, training]),
                );
              }
              return acc;
            },
            { matrix: [], trainings: {} },
          );
        handleData(processedData);
      },
    },
  );

  const handleData = (processedData: ProcedureTrainingMatrixType) => {
    setData(processedData);
    if (processedData?.matrix && pendingMatrixChanges?.massiveChecked) {
      updateCheckboxStates(processedData);
    } else {
      setIsMatrixChanging(false);
    }
  };

  const updateCheckboxStates = (processedData: ProcedureTrainingMatrixType) => {
    try {
      if (!processedData?.matrix || !pendingMatrixChanges?.massiveChecked)
        return;
      const newCheckboxStates = new Map<string, boolean>();
      const massiveChecked = pendingMatrixChanges.massiveChecked;

      for (const [trainingId, { isAllChecked }] of Object.entries(
        massiveChecked,
      )) {
        if (isAllChecked !== undefined) {
          processedData.matrix.forEach((position) => {
            const key = `${position.jobTitleId}-${position.coordinationId}-${position.workStationId}-${trainingId}`;
            newCheckboxStates.set(key, isAllChecked);
          });
        }
      }
      setModifiedCheckboxesMap((prevMap) => {
        return new Map([...prevMap, ...newCheckboxStates]);
      });
    } finally {
      setIsMatrixChanging(false);
    }
  };

  const isLoading = isLoadingTrainingsMatrix;
  const isError = isErrorTrainingsMatrix;

  async function fetchIndicators(filteredData: ProcedureTrainingFilters) {
    try {
      const response = await api.get('procedure-training-matrix/indicators', {
        params: {
          jobTitleIds: filteredData.jobTitleIds,
          trainingNames: filteredData.trainingNames,
          workStationIds: filteredData.workStationIds,
          coordinationIds: filteredData.coordinationIds,
          management: filteredData.management,
          locations: filteredData.locations,
        },
      });
      setIndicators(response.data);
    } catch (e) {
      toast.error('Erro ao carregar indicadores', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting) {
      setFilteredData(() => ({
        jobTitleIds: [],
        trainingNames: [],
        workStationIds: [],
        coordinationIds: [],
        management: [],
        locations: [],
      }));
      setIndicators(undefined);
      handleMatrixRefetch();
    } else {
      setFilteredData(() => ({
        ...filterValues,
      }));
      fetchIndicators(filterValues);
    }
  };

  const handleMatrixRefetch = async () => {
    setMatrixRefetch((prevState) => !prevState);
  };

  const handleResetFilter = async () => {
    procedureFilters.forEach((filter) => {
      if (filter.asyncFn) {
        filter
          .asyncFn('', procedureTrainingFiltersAtom)
          .then(() => resetFilters());
      }
    });
  };

  const handleClickPositionsPendencies = async () => {
    if (indicators?.unmappedPositionKeys) {
      if (isPositionFilteringIndicator) {
        setIsPositionFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          jobTitleIds: [],
        }));
        handleMatrixRefetch();
      } else {
        setIsPositionFilteringIndicator(true);
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          trainingIds: [],
        }));
        handleMatrixRefetch();
      }
    }
  };

  const handleClickTrainingsPendencies = async () => {
    if (indicators?.unmappedTrainings) {
      if (isTrainingFilteringIndicator) {
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          trainingIds: [],
        }));
        handleMatrixRefetch();
      } else {
        setIsTrainingFilteringIndicator(true);
        setIsPositionFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          jobTitleIds: [],
        }));
        handleMatrixRefetch();
      }
    }
  };

  const handleMappingChange = useCallback(
    (
      trainingId: number,
      jobTitleId: number,
      coordinationId: number,
      workStationId: number,
      checked: boolean,
    ) => {
      setIsMatrixChanging(true);

      const key = `${jobTitleId}-${coordinationId}-${workStationId}-${trainingId}`;

      // Batch state updates
      ReactDOM.unstable_batchedUpdates(() => {
        setModifiedCheckboxesMap((prevState) => {
          const newMap = new Map(prevState);
          newMap.set(key, !prevState.get(key));
          return newMap;
        });

        setPendingMatrixChanges((prevState) => {
          // Ensure that the nested structure exists
          const newState = { ...prevState };
          if (!newState.manualChanges) {
            newState.manualChanges = {};
          }
          newState.manualChanges[
            `${jobTitleId}-${coordinationId}-${workStationId}`
          ] =
            newState.manualChanges[
              `${jobTitleId}-${coordinationId}-${workStationId}`
            ] || {};
          newState.manualChanges[
            `${jobTitleId}-${coordinationId}-${workStationId}`
          ][trainingId] = newState.manualChanges[
            `${jobTitleId}-${coordinationId}-${workStationId}`
          ][trainingId] || {
            initialState: !checked,
          };

          // Check if the current state matches the initial state
          if (
            checked ===
            prevState?.manualChanges?.[
              `${jobTitleId}-${coordinationId}-${workStationId}`
            ]?.[trainingId]?.initialState
          ) {
            // Remove the specific trainingId entry
            delete prevState.manualChanges[
              `${jobTitleId}-${coordinationId}-${workStationId}`
            ][trainingId];

            // If the `${jobTitleId}-${coordinationId}-${workStationId}` no longer has any trainingId entries, remove it
            if (
              Object.keys(
                prevState.manualChanges[
                  `${jobTitleId}-${coordinationId}-${workStationId}`
                ],
              ).length === 0
            ) {
              delete prevState.manualChanges[
                `${jobTitleId}-${coordinationId}-${workStationId}`
              ];
            }

            return prevState;
          }
          // If the state does not match the initial state, update the checked property
          if (
            prevState?.manualChanges?.[
              `${jobTitleId}-${coordinationId}-${workStationId}`
            ]?.[trainingId]
          ) {
            prevState.manualChanges[
              `${jobTitleId}-${coordinationId}-${workStationId}`
            ][trainingId].checked = checked;
          }

          // Return the updated state
          return prevState;
        });
      });

      setIsMatrixChanging(false);
    },
    [],
  );

  const handleBlockMappingChange = (
    trainingId: number,
    positionId: number,
    coordinationId: number,
    workStationId: number,
    checked: boolean,
  ) => {
    const key = `${positionId}-${coordinationId}-${workStationId}-${trainingId}`;
    setModifiedBlockCheckboxesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.set(key, !prevState.get(key));
      return newMap;
    });

    setPendingBlockMatrixChanges((prevState) => {
      // Ensure that the nested structure exists
      prevState[`${positionId}-${coordinationId}-${workStationId}`] =
        prevState[`${positionId}-${coordinationId}-${workStationId}`] || {};
      prevState[`${positionId}-${coordinationId}-${workStationId}`][
        trainingId
      ] = prevState[`${positionId}-${coordinationId}-${workStationId}`][
        trainingId
      ] || {
        initialState: !checked,
      };

      // Check if the current state matches the initial state
      if (
        checked ===
        prevState[`${positionId}-${coordinationId}-${workStationId}`][
          trainingId
        ].initialState
      ) {
        // Remove the specific trainingId entry
        delete prevState[`${positionId}-${coordinationId}-${workStationId}`][
          trainingId
        ];

        // If the `${positionId}-${coordinationId}-${workStationId}` no longer has any trainingId entries, remove it
        if (
          Object.keys(
            prevState[`${positionId}-${coordinationId}-${workStationId}`],
          ).length === 0
        ) {
          delete prevState[`${positionId}-${coordinationId}-${workStationId}`];
        }

        return prevState;
      }

      // If the state does not match the initial state, update the checked property
      prevState[`${positionId}-${coordinationId}-${workStationId}`][
        trainingId
      ].checked = checked;

      // Return the updated state
      return prevState;
    });
  };

  const handleMapAllPositions = useCallback(
    async (trainingId: number) => {
      if (!data) return;

      try {
        setIsMatrixChanging(true);

        setPendingMatrixChanges((prevState) => {
          const newState = { ...prevState };
          if (!newState.massiveChecked) {
            newState.massiveChecked = {};
          }
          const currentValue =
            newState.massiveChecked[trainingId]?.isAllChecked;
          newState.massiveChecked[trainingId] = {
            isAllChecked: currentValue === undefined ? true : !currentValue,
          };
          return newState;
        });
        const newCheckboxStates = new Map<string, boolean>();
        const isAllChecked =
          !pendingMatrixChanges.massiveChecked?.[trainingId]?.isAllChecked;

        data.matrix.forEach((position) => {
          const key = `${position.jobTitleId}-${position.coordinationId}-${position.workStationId}-${trainingId}`;
          newCheckboxStates.set(key, isAllChecked);
        });

        setModifiedCheckboxesMap(
          (prevMap) => new Map([...prevMap, ...newCheckboxStates]),
        );
      } catch (error) {
        toast.error('Erro ao mapear todas as funções', {
          theme: 'colored',
          toastId: `error-mapping-all`,
        });
      } finally {
        setIsMatrixChanging(false);
      }
    },
    [data, pendingMatrixChanges.massiveChecked],
  );

  const handleSendMappingChanges = async () => {
    setIsMatrixChanging(true);
    const previousMap = new Map(modifiedCheckboxesMap);

    try {
      const changes = Object.entries(pendingMatrixChanges.manualChanges || {});

      for (const [key, trainings] of changes) {
        for (const [trainingId, trainingData] of Object.entries(trainings)) {
          const newKey = `${key}-${trainingId}`;
          const newValue = trainingData.checked;
          setModifiedCheckboxesMap((prevState) => {
            const newMap = new Map(prevState);
            newMap.set(newKey, newValue);
            return newMap;
          });
        }
      }
      const response = await api.post(
        '/procedure-training-matrix',
        pendingMatrixChanges,
      );

      if (response.status !== 201) {
        throw new Error('Network response was not ok');
      }

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
      fetchIndicators(filterValues);
    } catch (error) {
      if (isAxiosError(error)) {
        if (
          error.response?.status === HttpStatusCode.BadRequest &&
          Array.isArray(error.response.data.message)
        ) {
          const errors: {
            trainingId: number;
            positionId: number;
            message: string;
          }[] = error.response.data.message;

          for (const mappingError of errors) {
            const { positionId, trainingId, message } = mappingError;

            const key = `${positionId}-${trainingId}`;
            setModifiedCheckboxesMap((prevState) => {
              const newMap = new Map(prevState);
              newMap.set(key, previousMap.get(key) || false);
              return newMap;
            });

            toast.error(`Erro ao salvar mapeamento: ${message}`, {
              theme: 'colored',
              toastId: `error-trainings-${key}`,
            });
          }
        } else {
          setModifiedCheckboxesMap(previousMap);
          console.log(error.response?.data);
          if (error.response?.data.message === undefined) {
            toast.warning(
              'A resposta do servidor está vazia. Provávelmente a quantidade de dados foi maior do que o esperado pela aplicação. Tente ralizar mapeamentos menores ou se for uma necessidade contate o suporte.',
              {
                theme: 'colored',
                toastId: 'warning-undefined-response',
              },
            );
          } else {
            toast.error(
              `Não foi possível salvar o mapeamento: ${error.response?.data.message}`,
              {
                theme: 'colored',
                toastId: `error-trainings`,
              },
            );
          }
        }
      }

      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings`,
      });
      throw error;
    } finally {
      setIsMatrixChanging(false);
    }

    setPendingMatrixChanges({ manualChanges: {} });
  };

  const handleStartMappingMode = async () => {
    setIsMappingMode(true);
    setIsEditMode(true);
  };

  const handleSendBlockMappingChanges = async () => {
    const previousMap = new Map(modifiedBlockCheckboxesMap);

    try {
      const changes = Object.entries(pendingBlockMatrixChanges);

      for (const [combinationId, trainings] of changes) {
        for (const [trainingId, trainingData] of Object.entries(trainings)) {
          const newKey = `${combinationId}-${trainingId}`;

          const newValue = trainingData.checked;

          setModifiedBlockCheckboxesMap((prevState) => {
            const newMap = new Map(prevState);
            newMap.set(newKey, newValue);
            return newMap;
          });
        }
      }
      const response = await api.post(
        '/procedure-training-matrix/block',
        pendingBlockMatrixChanges,
      );

      if (response.status !== 201) {
        throw new Error('Network response was not ok');
      }

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
      fetchIndicators(filterValues);
    } catch (error) {
      if (isAxiosError(error)) {
        if (
          error.response?.status === HttpStatusCode.BadRequest &&
          Array.isArray(error.response.data.message)
        ) {
          const errors: {
            trainingId: number;
            positionId: number;
            message: string;
          }[] = error.response.data.message;

          for (const mappingError of errors) {
            const { positionId, trainingId, message } = mappingError;

            const key = `${positionId}-${trainingId}`;
            setModifiedBlockCheckboxesMap((prevState) => {
              const newMap = new Map(prevState);
              newMap.set(key, previousMap.get(key) || false);
              return newMap;
            });

            toast.error(`Erro ao salvar mapeamento: ${message}`, {
              theme: 'colored',
              toastId: `error-trainings-${key}`,
            });
          }
        } else {
          setModifiedBlockCheckboxesMap(previousMap);
          toast.error(
            `Não foi possível salvar o mapeamento: ${error.response?.data.message}`,
            {
              theme: 'colored',
              toastId: `error-trainings`,
            },
          );
        }
      }

      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings`,
      });
      throw error;
    } finally {
      setIsMatrixChanging(false);
    }

    setPendingBlockMatrixChanges({});
  };

  const handleSkipMapping = async (trainingId: number) => {
    try {
      await api.post(`/trainings/skip-mapping/${trainingId}`);
      toast.success('Mapeamento finalizado com sucesso', {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (error) {
      toast.error('Erro ao finalizar mapeamento', {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    }
    setIsEditMode(false);
  };

  const handleSaveMapping = async () => {
    try {
      toggleEditMode();
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? 'Erro ao enviar para mapeamento',
        {
          toastId: 'error',
          theme: 'colored',
        },
      );
    }
    setIsMappingMode(false);
    setIsEditMode(false);
  };

  const canSave: boolean | undefined = userCanSaveTraining(
    TrainingType.Procedimento,
  );

  return (
    <div
      className="relative flex w-full flex-col items-start"
      style={{ height: 'calc(100% - 40px)' }}
    >
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size={50} />
        </div>
      ) : (
        <>
          {isMatrixChanging && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black/20"
              style={{ width: '100vw', height: '100vh' }}
            >
              <div className="flex items-center gap-3 rounded-lg bg-white px-6 py-4 shadow-lg">
                <Spinner size={24} />
                <span className="text-sm font-medium text-gray-700">
                  Atualizando a matriz...
                </span>
              </div>
            </div>
          )}
          <div className="absolute -top-10 right-20 flex items-center">
            {canSave && (
              <Button
                className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
                onClick={() => setIsNewTraininigModalOpen(true)}
                disabled={!canSave}
              >
                Cadastrar Treinamento
              </Button>
            )}
            {canSave && data && Object.keys(data.trainings)?.length > 0 && (
              <Button
                className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
                onClick={toggleEditMode}
                disabled={!canSave}
              >
                {isEditMode ? 'Salvar' : 'Editar'} Matriz
              </Button>
            )}
            {canSave && (
              <ExtractProcedureTrainingMatrixButton
                filteredData={filteredData}
                isPositionFilteringIndicator={isPositionFilteringIndicator}
                isTrainingFilteringIndicator={isTrainingFilteringIndicator}
              />
            )}
            {canSave && (
              <UploadProcedureTrainingMatrixButton
                handleResetFilter={handleResetFilter}
                handleMatrixRefetch={handleMatrixRefetch}
              />
            )}
          </div>
          {!isMappingMode ? (
            <SideFilter
              refetchOnChange
              filters={procedureFilters}
              atom={procedureTrainingFiltersAtom}
              applyChanges={handleApplyFilter}
            />
          ) : (
            <></>
          )}
          <div className="w-[92vw] pt-4">
            {indicators &&
              indicators.totalTrainings !== undefined &&
              indicators.totalTrainings !== null && (
                <ExpandableSubtitle subtitle={'Indicadores'}>
                  <Cards
                    cards={[
                      {
                        value: indicators.totalTrainings ?? 0,
                        status: 'green',
                        title: 'Treinamentos Cadastrados',
                        width: '16rem',
                      },
                      {
                        value: indicators.unmappedPositionKeys ?? 0,
                        status: 'red',
                        title: 'Funções não mapeadas',
                        onClick: handleClickPositionsPendencies,
                        width: '16rem',
                        borderColor: isPositionFilteringIndicator
                          ? 'red'
                          : undefined,
                      },
                      {
                        value: indicators.unmappedTrainings ?? 0,
                        status: 'red',
                        title: 'Treinamentos não mapeados',
                        onClick: handleClickTrainingsPendencies,
                        width: '16rem',
                        borderColor: isTrainingFilteringIndicator
                          ? 'red'
                          : undefined,
                      },
                    ]}
                  />
                </ExpandableSubtitle>
              )}
          </div>
          <ProcedureTrainingMatrix
            trainingsMatrixData={data}
            isLoading={isLoading}
            isError={isError}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            filteredData={filteredData}
            needsToRefetch={matrixRefetch}
            setNeedsToRefetch={setMatrixRefetch}
            handleMatrixRefetch={handleMatrixRefetch}
            isModalOpen={isNewTrainingModalOpen}
            setIsModalOpen={setIsNewTraininigModalOpen}
            canSave={canSave}
            isEditMode={isEditMode}
            modifiedCheckboxes={modifiedCheckboxesMap}
            onMappingChange={handleMappingChange}
            handleMapAllPositions={handleMapAllPositions}
            handleStartMappingMode={handleStartMappingMode}
            handleSkipMapping={handleSkipMapping}
            handleSaveMapping={handleSaveMapping}
            modifiedBlockCheckboxes={modifiedBlockCheckboxesMap}
            onBlockMappingChange={handleBlockMappingChange}
          />
          <TrainingModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            matrixRefetch={handleMatrixRefetch}
            trainingType={TrainingType.Procedimento}
          />
        </>
      )}
    </div>
  );
};

export default ProcedureTrainingPage;
