import { useEffect, useRef, useState } from 'react';

import { cn } from '@/utils/cn';

import LightTooltip from './LightTooltip';

interface TurnstileAccessCardInfoProps {
  label: string;
  value: string;
  labelClassName?: string;
  valueClassName?: string;
}

export const TurnstileAccessCardInfo = ({
  label,
  value,
  labelClassName,
  valueClassName,
}: TurnstileAccessCardInfoProps) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [value]);

  return (
    <div className="flex min-w-max items-center gap-2">
      <span className={cn('text-xs font-bold text-primary', labelClassName)}>
        {label}:
      </span>
      {isTruncated ? (
        <LightTooltip title={value} arrow placement="top">
          <span
            ref={textRef}
            className={cn(
              'w-48 flex-shrink-0 overflow-hidden truncate text-xs lg:w-56',
              valueClassName,
            )}
          >
            {value}
          </span>
        </LightTooltip>
      ) : (
        <span
          ref={textRef}
          className={cn(
            'w-48 flex-shrink-0 overflow-hidden truncate text-xs lg:w-56',
            valueClassName,
          )}
        >
          {value}
        </span>
      )}
    </div>
  );
};
