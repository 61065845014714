import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TrainingType } from '@/constants/TrainingPlanning';
import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { PositionTrainingMatrixFilters } from '@/state/PositionTrainingMatrixFilter.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import api from '../../services/apiSgft';
import {
  ProcedureTraining,
  RegulatoryTrainingMatrix,
  Training,
} from '../../types/Training';
import Line from '../atoms/Line';
import Spinner from '../atoms/Spinner';
import { TooltipString } from '../atoms/TooltipString';
import ModalConfirmation from '../molecules/ConfirmationModal';
import RegulatoryTrainingHeader from '../molecules/RegulatoryTrainingHeader';
import RegulatoryTrainingMatrixCell from '../molecules/RegulatoryTrainingMatrixCell';
import TraniningWrapper from '../molecules/TrainingWrapper';
import DesactivateTrainingModal from './DesactivateTrainingModal';
import InfiniteTable from './InfiniteTable';
import TrainingModal from './TrainingModal';

interface Props {
  trainingsMatrixData?: RegulatoryTrainingMatrix;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  filteredData: PositionTrainingMatrixFilters;
  needsToRefetch: boolean;
  setNeedsToRefetch: Dispatch<SetStateAction<boolean>>;
  handleMatrixRefetch: () => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  canSave?: boolean;
  isEditMode: boolean;
  modifiedCheckboxes: Map<string, boolean>;
  onMappingChange: (
    trainingId: number,
    positionId: number,
    checked: boolean,
  ) => void;
  handleMapAllPositions: (trainingId: number) => Promise<void>;
  modifiedBlockCheckboxes: Map<string, boolean>;
  onBlockMappingChange: (
    trainingId: number,
    positionId: number,
    checked: boolean,
  ) => void;
}

const PositionTrainingMatrix: FC<Props> = ({
  trainingsMatrixData,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  filteredData,
  needsToRefetch,
  setNeedsToRefetch,
  handleMatrixRefetch,
  isModalOpen,
  setIsModalOpen,
  canSave,
  isEditMode,
  modifiedCheckboxes,
  onMappingChange,
  handleMapAllPositions,
  modifiedBlockCheckboxes,
  onBlockMappingChange,
}) => {
  const [selectedTraining, setSelectedTraining] = useState<
    Training | undefined
  >(undefined);
  const [selectedProcedure, setSelectedProcedure] = useState<
    ProcedureTraining | undefined
  >(undefined);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [isDesactivateModalOpen, setIsDesactivateModalOpen] = useState(false);
  const [desactivateTrainingId, setDesactivateTrainingId] = useState(-1);
  const [size, setSize] = useState(20);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTraining(undefined);
    setSelectedProcedure(undefined);
  };

  const NoMappings = () => {
    return (
      <div className="flex h-[80vh] w-full flex-col items-center justify-center py-10">
        <img src={EmptyIcon} className="w-40" />
        <div className="flex flex-col items-center text-sm">
          <p>{`Nenhum mapeamento foi encontrado com esses parâmetros`}</p>
          <span className="font-semibold text-blue-800">
            {`Cadastre um ou altere os valores dos filtros para visualizar a matriz!`}
          </span>
        </div>
      </div>
    );
  };

  const handleCloseDesactivateModal = () => {
    setIsDesactivateModalOpen(false);
  };

  const handleTransformTrainingName = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    } else {
      return title;
    }
  };

  const handleEditTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`);
        setSelectedTraining(response.data);

        if (response.data?.trainingType === TrainingType.Procedimento) {
          const response = await api.get(`procedure/${trainingId}`);
          setSelectedProcedure(response.data);
        }
        setIsModalOpen(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [setIsModalOpen],
  );

  useEffect(() => {
    const trainingId = searchParams.get('id');
    if (trainingId) {
      handleEditTraining(Number(trainingId));
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, []);

  const handleDesactivateTraining = useCallback(async (trainingId: number) => {
    setDesactivateTrainingId(trainingId);
    setIsDesactivateModalOpen(true);
  }, []);

  const handleDeleteTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`, {
          params: { location: filteredData.location },
        });
        setSelectedTraining(response.data);
        setDeleteConfirmation(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [filteredData.location],
  );

  const trainingColumns = trainingsMatrixData?.trainings.map(
    (training, index: number) => ({
      accessorKey: `training_${training.id}`,
      meta: {
        headerClassName: 'pr-2 text-xs bg-blue-200 min-w-[12rem] uppercase',
        stickyClassName: 'pr-2',
      },
      enableSorting: false,
      header: () => (
        <RegulatoryTrainingHeader
          training={training}
          canSeeTraining={true}
          canSave={canSave}
          isEditMode={isEditMode}
          handleEditTraining={handleEditTraining}
          handleDesactivateTraining={handleDesactivateTraining}
          handleDeleteTraining={handleDeleteTraining}
          handleTransformTrainingName={handleTransformTrainingName}
          handleMapAllPositions={handleMapAllPositions}
        />
      ),
      cell: ({ row }: { row: any }) => {
        /*
          TODO: Number of header rows before the job titles rows start. Important to ensure
          the correct index is passed to the cell component and the job titles are
          correctly aligned with the training columns.
        */
        const POSITION_GAP = 4;

        return (
          <RegulatoryTrainingMatrixCell
            isEditMode={isEditMode}
            rowIndex={row.index}
            colIndex={index}
            trainingsMatrixData={trainingsMatrixData}
            positionId={
              trainingsMatrixData?.positions[row.index - POSITION_GAP]?.id || 0
            }
            trainingId={training.id as number}
            totalWorkload={training.totalWorkload}
            validity={training.validity}
            recyclingWorkload={training.recyclingMonths}
            modifiedCheckboxes={modifiedCheckboxes}
            canSave={canSave}
            isResponsible={training.isResponsible}
            onChangeCallback={(checked) => {
              onMappingChange(
                training.id as number,
                trainingsMatrixData?.positions[row.index - POSITION_GAP]?.id ||
                  0,
                checked,
              );
            }}
            positionGap={POSITION_GAP}
            modifiedBlockCheckboxes={modifiedBlockCheckboxes}
            onBlockChangeCallback={(checked) => {
              onBlockMappingChange(
                training.id as number,
                trainingsMatrixData?.positions[row.index - POSITION_GAP]?.id ||
                  0,
                checked,
              );
            }}
          />
        );
      },
    }),
  );

  const specialRows = [
    { isSpecial: true, type: 'validity' },
    { isSpecial: true, type: 'totalWorkload' },
    { isSpecial: true, type: 'recyclingMonths' },
    { isSpecial: true, type: 'functions' },
  ];

  const formatPositionName = (name: string) => {
    if (!name) return name;
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const positionsData = trainingsMatrixData?.positions
    ? [...specialRows, ...trainingsMatrixData.positions].map((position) => {
        if ('name' in position) {
          return {
            ...{
              ...position,
              name: formatPositionName(position.name),
            },
          };
        }

        return {
          ...position,
        };
      })
    : [...specialRows];

  const createRow = (text: string) => (
    <div className="flex h-12 items-center justify-center bg-blue-700 px-2 font-graphie text-[12px] text-white">
      {text}
    </div>
  );

  const ValidityRow = createRow('VALIDADE (anos)');
  const TotalWorkloadRow = createRow('CARGA HORÁRIA TOTAL (H)');
  const RecycleTotalWorkloadRow = createRow('CARGA HORÁRIA RECICLAGEM (H)');

  const positionColumn = [
    {
      accessorKey: 'management',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'w-[12rem] bg-white z-20 sticky left-0',
      },
      header: '',
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return <div className="-ml-1 size-[104%] bg-white"></div>;
          case 1:
            return <div className="-ml-1 size-[104%] bg-white"></div>;
          case 2:
            return <div className="-ml-1 size-[104%] bg-white"></div>;
          case 3:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                GERÊNCIA
              </div>
            );

          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'areaCoordination',
      meta: {
        stickyClassName: 'sticky left-[12rem] bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem] bg-white z-20 sticky left-[12rem]',
      },
      header: '',
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return <div className="-mb-1 h-full bg-white"></div>;
          case 1:
            return <div className="-mb-1 h-full bg-white"></div>;
          case 2:
            return '';
          case 3:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                COORDENAÇÃO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'name',
      meta: {
        stickyClassName: 'sticky left-[24rem] bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem] sticky left-[24rem] z-20 bg-white',
      },
      header: () => {
        return (
          <div className="flex h-8 items-center justify-center font-graphie text-[13px]">
            <span>FUNÇÃO / CURSO</span>
          </div>
        );
      },
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return ValidityRow;
          case 1:
            return TotalWorkloadRow;
          case 2:
            return RecycleTotalWorkloadRow;
          case 3:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                FUNÇÃO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
  ];

  const submitDelete = async () => {
    try {
      setIsLoadingTrainings(true);
      const response = await api.delete(`trainings/${selectedTraining?.id}`, {
        params: {
          trainingType: filteredData.trainingType[0],
        },
      });
      setSelectedTraining(response.data);
      setDeleteConfirmation(true);
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings/${selectedTraining?.id}`,
      });
      throw error;
    } finally {
      setIsLoadingTrainings(false);
      setSelectedTraining(undefined);
      setDeleteConfirmation(false);
      setNeedsToRefetch(!needsToRefetch);
    }
  };

  const columns =
    trainingsMatrixData?.trainings && trainingColumns
      ? [...positionColumn, ...trainingColumns]
      : [...positionColumn];

  if (
    !trainingsMatrixData ||
    Object.keys(trainingsMatrixData).length === 0 ||
    trainingsMatrixData.trainings.length === 0 ||
    trainingsMatrixData.positions.length === 0
  ) {
    return (
      <TraniningWrapper isLoading={isLoading} isError={isError}>
        <div className="flex h-full w-full rounded-md bg-white p-5">
          {NoMappings()}
        </div>
        <TrainingModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          matrixRefetch={handleMatrixRefetch}
          trainingData={selectedTraining}
          trainingType={TrainingType.Regulatorio}
        />
      </TraniningWrapper>
    );
  }

  const handleLocationName = (location: string) => {
    const lowerCaseLocation = location.toLowerCase();

    if (lowerCaseLocation === 'matriz') {
      return 'Matriz';
    }
    if (lowerCaseLocation === 'tamac') {
      return 'Terminal Aquaviáro de Maceió (TAMAC)';
    }

    return location;
  };

  const fetchNextHorizontalPage = () => {
    setSize((prevState) => prevState + 10);
  };
  const stickyRowForTrainingType = [0, 1, 2, 3];

  return (
    <TraniningWrapper isLoading={isLoading} isError={isError}>
      {(isLoading || isLoadingTrainings) && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size={50} />
        </div>
      )}

      <div className="flex h-full w-full flex-col rounded-md bg-white p-5">
        <div>
          <div className="align-center flex w-full font-graphie text-[20px] font-bold text-blue-800">{`${handleLocationName(filteredData.location[0].toLocaleUpperCase())}`}</div>
          <Line />
        </div>

        <InfiniteTable
          hasNextPage={hasNextPage}
          data={positionsData}
          columns={columns.filter(
            (el, index) => index < Math.min(size, columns.length),
          )}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextHorizontalPage={fetchNextHorizontalPage}
          hasDivider
          isWhiteTable
          shouldFetchNextHorizontalPage
          stickyRows={stickyRowForTrainingType}
        />
      </div>
      <TrainingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        matrixRefetch={handleMatrixRefetch}
        trainingData={selectedTraining}
        procedureData={selectedProcedure}
        trainingType={TrainingType.Regulatorio}
      />

      <ModalConfirmation
        isOpen={deleteConfirmation}
        title="Deseja confirmar a remoção do treinamento para todos os polos?"
        description="Cuidado, esta ação não poderá ser desfeita!"
        confirmAction={submitDelete}
        discartAction={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
        onClose={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
      />

      <DesactivateTrainingModal
        trainingId={desactivateTrainingId}
        isOpen={isDesactivateModalOpen}
        onClose={handleCloseDesactivateModal}
        matrixRefetch={handleMatrixRefetch}
        trainingType={filteredData.trainingType[0] as TrainingTypeEnum}
      />
    </TraniningWrapper>
  );
};

export default PositionTrainingMatrix;
