/* eslint-disable no-unused-vars */
import Menu from '@mui/icons-material/Menu';
import { forwardRef, useImperativeHandle } from 'react';

import { ShortTraining } from '@/types/Training';

import LightTooltip from '../atoms/LightTooltip';
import SimpleMenu from '../atoms/Menu';

interface RegulatoryTrainingHeaderProps {
  training: ShortTraining;
  canSeeTraining: boolean | undefined;
  canSave: boolean | undefined;
  isEditMode: boolean;
  handleEditTraining: (trainingId: number) => void;
  handleDesactivateTraining: (trainingId: number) => void;
  handleDeleteTraining: (trainingId: number) => void;
  handleTransformTrainingName: (title: string, maxLength: number) => string;
  handleMapAllPositions: (trainingId: number) => Promise<void>;
}

const RegulatoryTrainingHeader = forwardRef(
  (
    {
      training,
      canSeeTraining,
      canSave,
      isEditMode,
      handleEditTraining,
      handleDesactivateTraining,
      handleDeleteTraining,
      handleTransformTrainingName,
      handleMapAllPositions,
    }: RegulatoryTrainingHeaderProps,
    ref,
  ) => {
    const options = [
      {
        name: 'Cadastro do Treinamento',
        onClick: () => handleEditTraining(training.id as number),
        disabled: false,
      },
      {
        name: 'Desativar Treinamento',
        onClick: () => handleDesactivateTraining(training.id as number),
        disabled: !canSave,
      },
      {
        name: 'Mapear Todas as Funções',
        onClick: () => handleMapAllPositions(training.id as number),
        disabled: !isEditMode,
      },
    ];
    useImperativeHandle(ref, () => ({
      triggerEdit: () => handleEditTraining(training.id as number),
      triggerDesactivate: () =>
        handleDesactivateTraining(training.id as number),
      triggerDelete: () => handleDeleteTraining(training.id as number),
      triggerMapAllPositions: () =>
        handleMapAllPositions(training.id as number),
    }));

    return (
      <div className="relative flex h-12 items-center justify-between overflow-visible align-middle">
        <LightTooltip title={training.name} arrow placement="top-start">
          <div className="ml-2 line-clamp-2 block max-h-20 w-full overflow-hidden break-words text-center">
            {handleTransformTrainingName(training.name, 35)}
          </div>
        </LightTooltip>
        {canSeeTraining && (
          <SimpleMenu
            options={options}
            iconComponent={<Menu fontSize="inherit" />}
            menuItemsClassName="right-0 left-auto"
          />
        )}
      </div>
    );
  },
);

RegulatoryTrainingHeader.displayName = 'RegulatoryTrainingHeader';

export default RegulatoryTrainingHeader;
