import { DialogActions, Tooltip } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { TrainingType } from '@/constants/TrainingPlanning';
import { ProcedureInstructor } from '@/types/Employee';
import { TrainingTransformedData } from '@/types/TrainingModal';
import { trackEvent } from '@/utils/trackEvent';

import { PermissionMessages } from '../../constants/SGFTUserTypes';
import api from '../../services/apiSgft';
import { jobTrainingMatrixFiltersAtom } from '../../state/JobTraningMatrixFilter.atom';
import { ProcedureTraining, Training } from '../../types/Training';
import { userCanSaveTraining } from '../../utils/handleSavePermissions';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Line from '../atoms/Line';
import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import ProcedureTrainingFormFields from '../molecules/ProcedureTrainingFormFields';
import RegulatoryTrainingFormFields from '../molecules/TrainingFormFields';

interface TrainingModalProps {
  isOpen: boolean;
  onClose: () => void;
  matrixRefetch: () => void;
  trainingType: TrainingType;
  trainingData?: Training;
  procedureData?: ProcedureTraining;
}

const TrainingModal: FC<TrainingModalProps> = ({
  isOpen,
  onClose,
  matrixRefetch,
  trainingData,
  procedureData,
  trainingType = TrainingType.Regulatorio,
}) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const filterValues = useRecoilValue(jobTrainingMatrixFiltersAtom);
  const [regulatoryTrainings, setRegulatoryTrainings] = useState([]);
  const [employees, setEmployees] = useState<ProcedureInstructor[]>([]);
  const [procedureTrainings, setProcedureTrainings] = useState([]);
  const [equivalencesProcedureTrainings, setEquivalencesProcedureTrainings] =
    useState([]);
  const canSave: boolean | undefined = userCanSaveTraining(trainingType);

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);
    if (!canSave && trainingData?.isResponsible) {
      try {
        const response = await api.put(
          `trainings/workload/${trainingData.id}`,
          {
            totalWorkload: data.totalWorkload,
          },
        );
        toast.success(`${response.data.message}`, {
          theme: 'colored',
          toastId: 'success',
        });
      } catch (error: any) {
        toast.error(
          `Erro ao editar carga horária do  Treinamento: ${error?.response?.data?.message}`,
          {
            theme: 'colored',
            toastId: `error-training`,
          },
        );
        throw error;
      }
      setIsSubmiting(false);
      matrixRefetch();
      methods.reset();
      onClose();
      return;
    }
    const needsRecycling: boolean = data.needsRecycling === 'true';

    let transformedData: TrainingTransformedData =
      {} as TrainingTransformedData;
    if (trainingType === TrainingType.Regulatorio) {
      transformedData = {
        name: data.name.toUpperCase(),
        locations: data.locations,
        modalities: data.modalities,
        totalWorkload: Number(data.totalWorkload),
        onlineWorkload: Number(data.onlineWorkload),
        inPersonWorkload: Number(data.inPersonWorkload),
        validity: Number(data.validity),
        needsRecycling,
        recyclingMonths: needsRecycling ? Number(data.recyclingMonths) : null,
        preRequisites: data.preRequisites,
        equivalences: data.equivalences,
        trainingType: TrainingType.Regulatorio,
      };
    } else if (trainingType === TrainingType.Procedimento) {
      transformedData = {
        name: `${data.code} (REVISÃO ${data.revision})`,
        locations: data.locations,
        modalities: data.modalities,
        needsRecycling: false,
        totalWorkload: data.totalWorkload
          ? Number(data.totalWorkload)
          : undefined,
        daysToCompletionDeadline: Number(data.daysToCompletionDeadline),
        validity: Number(data.validity),
        isCritical: data.isCritial?.toLowerCase() === 'sim' || false,
        mappingResponsible: data?.mappingResponsible
          ? [data?.mappingResponsible]
          : [],
        equivalences: data.equivalences,
        trainingType: TrainingType.Procedimento,
        procedureId: data.procedureId ? data.procedureId : undefined,
      };
    }

    try {
      let response;

      if (trainingData?.id) {
        response = await api.put(
          `trainings/${trainingData.id}`,
          transformedData,
        );
      } else {
        response = await api.post('trainings', transformedData);
      }

      setIsSubmiting(false);
      toast.success(`${response.data.message}`, {
        theme: 'colored',
        toastId: 'success',
      });
      matrixRefetch();
      methods.reset();

      trackEvent(
        transformedData?.locations.join(', '),
        'generate_training',
        transformedData?.modalities.join(', '),
      );
      onClose();
    } catch (error: any) {
      setIsSubmiting(false);
      toast.error(
        `Erro ao ${trainingData?.id ? 'editar' : 'criar'} Treinamento: ${error.response.data.message}`,
        {
          theme: 'colored',
          toastId: `error-training`,
        },
      );
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('trainings/', {
          params: {
            locations: filterValues.location,
            getAll: true,
            trainingType: [TrainingType.Regulatorio],
          },
        });
        setRegulatoryTrainings(response.data);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-${'trainings/'}`,
        });
        throw error;
      }
    };

    const fetchProcedureData = async () => {
      try {
        const response = await api.get('procedure/');
        setProcedureTrainings(response.data);

        const equivalencesResponse = await api.get(
          'trainings/equivalence-procedure',
        );
        setEquivalencesProcedureTrainings(equivalencesResponse.data);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-${'trainings/'}`,
        });
        throw error;
      }
    };

    const fetchEmployees = async () => {
      try {
        const response = await api.get(`/employees`, {
          params: {
            size: 100000,
            page: 1,
          },
        });

        if (response.data?.employees) {
          setEmployees(response.data.employees);
        }
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    };

    const loadData = async () => {
      setIsLoading(true);
      try {
        if (trainingType === TrainingType.Regulatorio) {
          await fetchData();
        } else if (trainingType === TrainingType.Procedimento) {
          await Promise.all([fetchProcedureData(), fetchEmployees()]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      loadData();
    }
  }, [isOpen, filterValues.location]);

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title="Cadastro de Treinamento"
      className="w-[50rem]"
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <div className="max-h-[85vh] w-[50rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
            <div className="flex h-auto items-center justify-between px-4 pt-3">
              <SubtitleText
                className="text-[19px]"
                subtitle={`Cadastro de Treinamento`}
              />
              <CloseButton
                onClick={handleCloseModal}
                className="mt-[-0.5rem]"
              />
            </div>
            <Line />
            <div className={`w-full p-5 pt-0 ${trainingType && 'pb-0'}`}>
              <div className="flex h-auto items-center justify-between">
                <div className="flex justify-between">
                  <SubtitleText
                    className="text-[20px]"
                    subtitle={`Tipo de Treinamento`}
                  />
                </div>
              </div>
              <TextComponent title={''} width="46rem" value={trainingType} />
            </div>
            {isLoading ? (
              <div className="my-4 flex h-full w-full items-center rounded-md bg-white">
                <Spinner size={40} />
              </div>
            ) : trainingType === TrainingType.Regulatorio ? (
              <>
                <FormProvider {...methods}>
                  <form
                    className="space-between flex h-[90%] flex-col pt-5"
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <RegulatoryTrainingFormFields
                      trainingData={trainingData}
                      regulatoryTrainings={regulatoryTrainings}
                      canSave={canSave}
                    />
                    <DialogActions
                      sx={{
                        width: 'full',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '25px',
                        marginX: '10px',
                      }}
                    >
                      <Button
                        type="button"
                        className="min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </Button>
                      <Tooltip
                        title={PermissionMessages.NoPermission}
                        disableHoverListener={canSave}
                      >
                        <Button
                          type="submit"
                          className="min-w-36"
                          isLoading={isSubmiting}
                          disabled={!canSave}
                        >
                          Confirmar
                        </Button>
                      </Tooltip>
                    </DialogActions>
                  </form>
                </FormProvider>
              </>
            ) : trainingType === TrainingType.Procedimento ? (
              <>
                <FormProvider {...methods}>
                  <form
                    className="space-between flex h-[90%] flex-col pt-5"
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <ProcedureTrainingFormFields
                      managers={employees}
                      trainingData={trainingData}
                      procedureData={procedureData}
                      procedureTrainings={procedureTrainings}
                      equivalencesProcedureTrainings={
                        equivalencesProcedureTrainings
                      }
                      canSave={canSave}
                    />
                    <DialogActions
                      sx={{
                        width: 'full',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '25px',
                        marginX: '10px',
                      }}
                    >
                      <Button
                        type="button"
                        className="min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </Button>
                      <Tooltip
                        title={PermissionMessages.NoPermission}
                        disableHoverListener={
                          canSave || trainingData?.isResponsible
                        }
                      >
                        <Button
                          type="submit"
                          className="min-w-36"
                          isLoading={isSubmiting}
                          disabled={!canSave && !trainingData?.isResponsible}
                        >
                          Confirmar
                        </Button>
                      </Tooltip>
                    </DialogActions>
                  </form>
                </FormProvider>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrainingModal;
