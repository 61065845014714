import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import { cn } from '../../utils/cn';
import ModalConfirmation from '../molecules/ConfirmationModal';

interface TableProps<T> {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  stickyHeader?: boolean;
  columnVisibility?: Record<string, boolean>;
  isWhiteTable?: boolean;
  containerClassname?: string;
  trHeaderClassname?: string;
  tdHeaderClassname?: string;
  trBodyClassname?: string;
  tBodyClassname?: string;
  tdClassname?: string;
  showHeader?: boolean;
  onEditTableRow?: (data: T) => void;
  onDeleteTableRow?: (data: T) => void;
  deleteMessage?: {
    title: string;
    description: string;
  };
}
const defaultDeleteMessage = {
  title: 'Excluir dado',
  description: 'Tem certeza que deseja excluir esse dado?',
};

const Table = <T,>({
  columns,
  data,
  stickyHeader,
  columnVisibility,
  isWhiteTable,
  containerClassname,
  trHeaderClassname,
  tdHeaderClassname,
  trBodyClassname,
  tBodyClassname,
  tdClassname,
  onEditTableRow,
  onDeleteTableRow,
  showHeader = true,
  deleteMessage = defaultDeleteMessage,
}: TableProps<T>) => {
  const [sorting, setSorting] = useState<any>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState<T | null>(null);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });
  const handleOpenDeleteModal = (row: any) => {
    setRowToBeDeleted(row);
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setRowToBeDeleted(null);
  };
  const handleDeleteRow = () => {
    handleCloseDeleteModal();
    if (rowToBeDeleted && onDeleteTableRow) onDeleteTableRow(rowToBeDeleted);
  };
  return (
    <div
      className={cn(
        'custom-scrollbar max-w-full flex-1 border-separate border-spacing-y-4',
        containerClassname,
      )}
    >
      <ModalConfirmation
        title={deleteMessage.title}
        description={deleteMessage.description}
        confirmAction={handleDeleteRow}
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        discartAction={handleCloseDeleteModal}
      />
      <table className="relative w-full flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
        {showHeader ? (
          <thead className={`${stickyHeader ? 'sticky top-0 z-30' : ''}`}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={cn(
                  'bg-white font-graphie',
                  isWhiteTable ? 'h-10 text-[#747474]' : 'h-14 text-blue-800',
                  trHeaderClassname ?? '',
                )}
              >
                {headerGroup.headers.map((header) => {
                  const isSticky =
                    header.column.columnDef.meta?.stickyClassName || '';
                  const headerClassName =
                    header.column.columnDef.meta?.headerClassName || '';
                  const headerContentClassName =
                    header.column.columnDef.meta?.headerContentClassName || '';
                  return (
                    <td
                      key={header.id}
                      className={cn(
                        'bg-white',
                        `${isWhiteTable ? 'text-[#747474]' : 'text-blue-800'} text-[13px] font-bold`,
                        tdHeaderClassname,
                        isSticky,
                        headerClassName,
                      )}
                    >
                      {header.column.getCanSort() ? (
                        <button
                          type="button"
                          onClick={header.column.getToggleSortingHandler()}
                          className={cn(
                            'h-14 w-full hover:text-[#4c4c73]',
                            headerContentClassName,
                          )}
                        >
                          <div className="flex items-center uppercase text-nowrap px-3">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getIsSorted() === 'asc' ? (
                              <NorthIcon
                                sx={{ color: '#232121', padding: '5px' }}
                              />
                            ) : header.column.getIsSorted() === 'desc' ? (
                              <SouthIcon
                                sx={{ color: '#232121', padding: '5px' }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </button>
                      ) : typeof header.column.columnDef.header ===
                        'function' ? (
                        header.column.columnDef.header({
                          column: header.column,
                          header,
                          table,
                        })
                      ) : (
                        (header.column.columnDef.header as React.ReactNode)
                      )}
                    </td>
                  );
                })}
                {(onEditTableRow || onEditTableRow) && <td></td>}
              </tr>
            ))}
          </thead>
        ) : (
          <></>
        )}
        <tbody
          className={cn(
            `${isWhiteTable ? 'border-t-[1px] border-[#D9D9D9]' : ''}`,
            `${tBodyClassname}`,
          )}
        >
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={cn(
                `${isWhiteTable ? 'border-b-[1px] border-[#D9D9D9]' : 'h-12'}`,
                trBodyClassname,
                index % 2 || isWhiteTable ? 'bg-white' : 'bg-[#F1F3FA]',
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={`${cell.id}-${cell.getValue()}`}
                  className={cn(
                    'py-1',
                    index % 2 || isWhiteTable ? 'bg-white' : 'bg-[#F1F3FA]',
                    cell.column.columnDef.meta?.stickyClassName,
                    tdClassname,
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              {(onEditTableRow || onDeleteTableRow) && (
                <td className="space-x-3">
                  {onEditTableRow && (
                    <button onClick={() => onEditTableRow(row.original)}>
                      <FaPencilAlt color="blue" />
                    </button>
                  )}
                  {onDeleteTableRow && (
                    <button onClick={() => handleOpenDeleteModal(row.original)}>
                      <FaTrashAlt color="blue" />
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
