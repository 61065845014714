import api from '@/services/apiSgft';

const defaultFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const downloadFile = async (
  apiRoute: string,
  filename: string,
  fileType: string = defaultFileType,
) => {
  try {
    const response = await api.get(apiRoute, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: fileType,
      }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
