/* eslint-disable react/display-name */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { BsClipboard2CheckFill, BsLockFill } from 'react-icons/bs';

import { RegulatoryTrainingMatrix } from '@/types/Training';

import Check from '../../assets/icons/check.svg';
import Checkbox from '../atoms/Checkbox';
import LightTooltip from '../atoms/LightTooltip';

interface RegulatoryTrainingMatrixCellProps {
  isEditMode: boolean;
  rowIndex: number;
  colIndex: number;
  positionId: number;
  trainingId: number;
  totalWorkload: number | null;
  validity: number | null;
  recyclingWorkload: number | null;
  trainingsMatrixData: RegulatoryTrainingMatrix;
  modifiedCheckboxes: Map<string, boolean>;
  canSave?: boolean;
  isResponsible?: boolean;
  onChangeCallback: (checked: boolean) => void;
  positionGap: number;
  modifiedBlockCheckboxes: Map<string, boolean>;
  onBlockChangeCallback: (checked: boolean) => void;
}

const RegulatoryTrainingMatrixCell = ({
  isEditMode,
  rowIndex,
  colIndex,
  trainingId,
  totalWorkload,
  validity,
  recyclingWorkload,
  positionId,
  trainingsMatrixData,
  modifiedCheckboxes,
  canSave,
  isResponsible,
  onChangeCallback,
  positionGap,
  modifiedBlockCheckboxes,
  onBlockChangeCallback,
}: RegulatoryTrainingMatrixCellProps) => {
  const checkboxRef = useRef(null);
  const blockCheckboxRef = useRef(null);
  const positionIndex = rowIndex - positionGap;
  const trainingIndex = colIndex;

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isBlockChecked, setIsBlockChecked] = useState<boolean>(false);

  useEffect(() => {
    const updateIsChecked = () => {
      if (
        trainingsMatrixData?.matrix &&
        trainingsMatrixData?.matrix.length > positionIndex &&
        trainingsMatrixData?.matrix[positionIndex]?.length > trainingIndex
      ) {
        if (modifiedCheckboxes.has(`${positionId}-${trainingId}`)) {
          return modifiedCheckboxes.get(`${positionId}-${trainingId}`) || false;
        } else {
          return trainingsMatrixData?.matrix[positionIndex][trainingIndex];
        }
      }

      return false;
    };

    const updateIsBlockChecked = () => {
      if (
        trainingsMatrixData?.blockMatrix &&
        trainingsMatrixData?.blockMatrix.length > positionIndex &&
        trainingsMatrixData?.blockMatrix[positionIndex]?.length > trainingIndex
      ) {
        if (modifiedBlockCheckboxes.has(`${positionId}-${trainingId}`)) {
          return (
            modifiedBlockCheckboxes.get(`${positionId}-${trainingId}`) || false
          );
        } else {
          return trainingsMatrixData?.blockMatrix[positionIndex][trainingIndex];
        }
      }

      return false;
    };

    setIsChecked(updateIsChecked());
    setIsBlockChecked(updateIsBlockChecked());
  }, [
    modifiedCheckboxes,
    modifiedBlockCheckboxes,
    positionId,
    trainingId,
    trainingsMatrixData?.matrix,
    trainingsMatrixData?.blockMatrix,
    positionIndex,
    trainingIndex,
  ]);

  const handleCheckboxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target?.checked;
    if (!checked && isBlockChecked) {
      return;
    }
    return onChangeCallback(checked);
  };

  const handleBlockCheckboxChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.target?.checked;
    if (checked && !isChecked) {
      return;
    }
    return onBlockChangeCallback(checked);
  };

  if (rowIndex === 0) {
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${validity || ''}`}</div>
      </div>
    );
  } else if (rowIndex === 1) {
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${totalWorkload || ''}`}</div>
      </div>
    );
  } else if (rowIndex === 2) {
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${recyclingWorkload || ''}`}</div>
      </div>
    );
  } else if (rowIndex === 3) {
    return <div></div>;
  } else {
    return (
      <div className="flex items-center justify-center gap-4">
        {isEditMode ? (
          <div className="flex items-center gap-4">
            <div className="flex min-w-[64px] items-center justify-center gap-2">
              <Checkbox
                className={`${isBlockChecked && 'cursor-not-allowed opacity-50'}`}
                checked={isChecked}
                defaultChecked={isChecked}
                ref={checkboxRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleCheckboxChange(e);
                }}
                disabled={!canSave && !isResponsible && isBlockChecked}
                size="large"
                variant="blue"
              />
              <BsClipboard2CheckFill size={18} color="#00d692" />
            </div>

            <div className="flex min-w-[64px] items-center justify-center gap-2">
              <Checkbox
                className={`${!isChecked && 'cursor-not-allowed opacity-50'}`}
                checked={isBlockChecked}
                defaultChecked={isBlockChecked}
                ref={blockCheckboxRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleBlockCheckboxChange(e);
                }}
                disabled={!canSave && !isResponsible && !isChecked}
                size="large"
                variant="blue"
              />
              <LightTooltip
                title="Sem este treinamento, o acesso pela catraca será bloqueado."
                arrow
              >
                <div>
                  <BsLockFill size={18} color="#f4503a" />
                </div>
              </LightTooltip>
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <div className="flex min-w-[64px] items-center justify-center gap-2">
              {isChecked && (
                <>
                  <img src={Check} className="size-6 flex-none" />
                  <BsClipboard2CheckFill size={18} color="#00d692" />
                </>
              )}
            </div>

            <div className="flex min-w-[64px] items-center justify-center gap-2">
              {isBlockChecked && (
                <>
                  <img src={Check} className="size-6 flex-none" />
                  <BsLockFill size={18} color="#f4503a" />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default RegulatoryTrainingMatrixCell;
