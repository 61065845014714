import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { JobTitle } from '@/types/JobTitle';
import { downloadFile } from '@/utils/downloadFile';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';

interface ServiceOrderModalProps {
  jobTitle: JobTitle;
  isOpen: boolean;
  handleClose: () => void;
}

export const ServiceOrderModal = ({
  isOpen,
  handleClose,
  jobTitle,
}: ServiceOrderModalProps) => {
  const [isFetchingServiceOrder, setIsFetchingServiceOrder] = useState(false);
  const form = useForm<{
    workStation: string;
  }>();

  const handleGetServiceOrder = async (data: { workStation: string }) => {
    const workStation = data.workStation;
    if (!workStation) {
      return;
    }
    setIsFetchingServiceOrder(true);
    await downloadFile(
      `job-title/service-order-pdf-generation/${jobTitle.id}?workStationId=${workStation}`,
      'ordem-de-servico',
      'application/pdf',
    );
    setIsFetchingServiceOrder(false);
    handleClose();
  };

  const workStationsOptions =
    jobTitle.generalInformationJobTitle.workStations.map((workStation) => ({
      label: workStation.workStation.name,
      value: String(workStation.workStation.id),
    }));
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Ordem de Serviço"
      className="w-[36rem] items-center pb-4"
    >
      <FormProvider {...form} handleSubmit={form.handleSubmit}>
        <form onSubmit={form.handleSubmit(handleGetServiceOrder)}>
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col items-center gap-4">
              <p className="text-lg font-medium">
                Selecione o Local de Trabalho para a Ordem de Serviço
              </p>
              <ControlledSimpleSelect
                title="Local de Trabalho"
                control={form.control}
                label="Local de Trabalho"
                values={workStationsOptions}
                {...form.register('workStation')}
              />
            </div>
            <div className="flex w-full items-center justify-center gap-4">
              <Button
                onClick={handleClose}
                variant="outline"
                className="w-40 rounded-full text-primary"
                type="button"
              >
                Cancelar
              </Button>
              <Button
                isLoading={isFetchingServiceOrder}
                className="w-40 rounded-full text-white"
              >
                Continuar
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
