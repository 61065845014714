import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TrainingType } from '@/constants/TrainingPlanning';
import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { ProcedureTrainingFilters } from '@/state/ProcedureTraining.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import api from '../../services/apiSgft';
import {
  ProcedureTraining,
  ProcedureTrainingMatrix as ProcedureTrainingMatrixType,
  Training,
} from '../../types/Training';
import Line from '../atoms/Line';
import Spinner from '../atoms/Spinner';
import { TooltipString } from '../atoms/TooltipString';
import ModalConfirmation from '../molecules/ConfirmationModal';
import ProcedureTrainingHeader from '../molecules/ProcedureTrainingHeader';
import ProcedureTrainingMatrixCell from '../molecules/ProcedureTrainingMatrixCell';
import TraniningWrapper from '../molecules/TrainingWrapper';
import DesactivateTrainingModal from './DesactivateTrainingModal';
import InfiniteTable from './InfiniteTable';
import TrainingModal from './TrainingModal';

interface Props {
  trainingsMatrixData?: ProcedureTrainingMatrixType;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  filteredData: ProcedureTrainingFilters;
  needsToRefetch: boolean;
  setNeedsToRefetch: Dispatch<SetStateAction<boolean>>;
  handleMatrixRefetch: () => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  canSave?: boolean;
  isEditMode: boolean;
  modifiedCheckboxes: Map<string, boolean>;
  onMappingChange: (
    trainingId: number,
    jobTitleId: number,
    coordinationId: number,
    workStationId: number,
    checked: boolean,
  ) => void;
  handleMapAllPositions: (trainingId: number) => Promise<void>;
  handleStartMappingMode: () => Promise<void>;
  handleSkipMapping: (trainingId: number) => Promise<void>;
  handleSaveMapping: () => Promise<void>;
  modifiedBlockCheckboxes: Map<string, boolean>;
  onBlockMappingChange: (
    trainingId: number,
    positionId: number,
    coordinationId: number,
    workStationId: number,
    checked: boolean,
  ) => void;
}

const ProcedureTrainingMatrix: FC<Props> = ({
  trainingsMatrixData,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  filteredData,
  needsToRefetch,
  setNeedsToRefetch,
  handleMatrixRefetch,
  isModalOpen,
  setIsModalOpen,
  canSave,
  isEditMode,
  modifiedCheckboxes,
  onMappingChange,
  handleMapAllPositions,
  handleStartMappingMode,
  handleSkipMapping,
  handleSaveMapping,
  modifiedBlockCheckboxes,
  onBlockMappingChange,
}) => {
  const [selectedTraining, setSelectedTraining] = useState<
    Training | undefined
  >(undefined);
  const [selectedProcedure, setSelectedProcedure] = useState<
    ProcedureTraining | undefined
  >(undefined);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [isDesactivateModalOpen, setIsDesactivateModalOpen] = useState(false);
  const [desactivateTrainingId, setDesactivateTrainingId] = useState(-1);
  const [size, setSize] = useState(20);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTraining(undefined);
    setSelectedProcedure(undefined);
  };

  const NoTrainings = () => {
    return (
      <div className="flex h-[80vh] w-full flex-col items-center justify-center py-10">
        <img src={EmptyIcon} className="w-40" />
        <div className="flex flex-col items-center text-sm">
          <p>{`Nenhum treinamento foi encontrado com esses parâmetros`}</p>
          <span className="font-semibold text-blue-800">
            {`Cadastre um ou altere os valores dos filtros para visualizar a matriz!`}
          </span>
        </div>
      </div>
    );
  };

  const handleCloseDesactivateModal = () => {
    setIsDesactivateModalOpen(false);
  };

  const handleTransformTrainingName = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    } else {
      return title;
    }
  };

  const handleEditTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`);
        setSelectedTraining(response.data);

        if (response.data?.trainingType === TrainingType.Procedimento) {
          const response = await api.get(`procedure/${trainingId}`);
          setSelectedProcedure(response.data);
        }
        setIsModalOpen(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [setIsModalOpen],
  );

  useEffect(() => {
    const trainingId = searchParams.get('id');
    if (trainingId) {
      handleEditTraining(Number(trainingId));
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, []);

  const handleDesactivateTraining = useCallback(async (trainingId: number) => {
    setDesactivateTrainingId(trainingId);
    setIsDesactivateModalOpen(true);
  }, []);

  const handleDeleteTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`, {
          params: { location: filteredData.locations },
        });
        setSelectedTraining(response.data);
        setDeleteConfirmation(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [filteredData.locations],
  );

  const specialRows = [
    { isSpecial: true, type: 'isCritical' },
    { isSpecial: true, type: 'totalWorkload' },
    { isSpecial: true, type: 'functions' },
  ];

  const trainingColumns =
    trainingsMatrixData?.trainings &&
    Object.keys(trainingsMatrixData?.trainings).map((trainingId) => ({
      accessorKey: `training_${trainingId}`,
      meta: {
        headerClassName: 'pr-2 text-xs bg-blue-200 min-w-[12rem] uppercase',
        stickyClassName: 'pr-2',
      },
      enableSorting: false,
      header: () => (
        <ProcedureTrainingHeader
          training={{
            id: Number(trainingId),
            ...trainingsMatrixData?.trainings[trainingId],
          }}
          canSeeTraining={true}
          canSave={canSave}
          isEditMode={isEditMode}
          handleEditTraining={handleEditTraining}
          handleDesactivateTraining={handleDesactivateTraining}
          handleDeleteTraining={handleDeleteTraining}
          handleTransformTrainingName={handleTransformTrainingName}
          handleMapAllPositions={handleMapAllPositions}
          handleStartMappingMode={handleStartMappingMode}
          handleSkipMapping={handleSkipMapping}
          handleSaveMapping={handleSaveMapping}
        />
      ),
      cell: ({ row }: { row: any }) => {
        const POSITION_GAP = 3;
        return (
          <ProcedureTrainingMatrixCell
            isEditMode={isEditMode}
            rowIndex={row.index}
            trainingsMatrixData={trainingsMatrixData}
            trainingId={trainingId}
            isCritical={trainingsMatrixData?.trainings[trainingId].isCritical}
            totalWorkload={
              trainingsMatrixData?.trainings[trainingId].totalWorkload
            }
            modifiedCheckboxes={modifiedCheckboxes}
            canSave={canSave}
            isResponsible={
              trainingsMatrixData?.trainings[trainingId].isResponsible
            }
            onChangeCallback={(checked) => {
              onMappingChange(
                Number(trainingId),
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.jobTitleId || 0,
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.coordinationId || 0,
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.workStationId || 0,
                checked,
              );
            }}
            positionGap={POSITION_GAP}
            jobTitleId={
              trainingsMatrixData?.matrix[row.index - POSITION_GAP]?.jobTitleId
            }
            coordinationId={
              trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                ?.coordinationId
            }
            workStationId={
              trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                ?.workStationId
            }
            modifiedBlockCheckboxes={modifiedBlockCheckboxes}
            onBlockChangeCallback={(checked) => {
              onBlockMappingChange(
                Number(trainingId),
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.jobTitleId || 0,
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.coordinationId || 0,
                trainingsMatrixData?.matrix[row.index - POSITION_GAP]
                  ?.workStationId || 0,
                checked,
              );
            }}
          />
        );
      },
    }));

  const isCorporativo = filteredData.locations[0] === 'Corporativo';

  const formatPositionName = (name: string) => {
    if (!name) return name;
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const positionsData = trainingsMatrixData?.matrix
    ? [...specialRows, ...trainingsMatrixData.matrix].map((row) => {
        if ('jobTitle' in row) {
          return {
            ...{
              locations: row.pole,
              management: row.management,
              areaCoordination: row.coordination,
              workstation: row.workStation,
              name: formatPositionName(row.jobTitle),
            },
          };
        }

        return {
          ...row,
        };
      })
    : [...specialRows];

  const createRow = (text: string) => (
    <div className="flex h-12 items-center justify-center bg-blue-700 px-2 font-graphie text-[12px] text-white">
      {text}
    </div>
  );

  const CriticalityRow = createRow('CRITICIDADE');
  const TotalWorkloadRow = createRow('CARGA HORÁRIA TOTAL (H)');

  const positionColumn = [
    {
      accessorKey: 'locations',
      meta: {
        stickyClassName: `sticky ${isCorporativo ? 'left-[12rem]' : 'left-0'} bg-white z-10 py-0 h-12`,
        headerClassName: `w-[12rem] bg-white z-20 sticky ${isCorporativo ? 'left-[12rem]' : 'left-0'}`,
      },
      header: '',
      cell: (info: any) => {
        const value: string = info.getValue();
        switch (info.row.index) {
          case 0:
            return <div className="-mb-1 -ml-1 h-full bg-white"></div>;
          case 1:
            return <div className="-mb-1 -ml-1 h-full bg-white"></div>;
          case 2:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                POLO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={value ? value.toUpperCase() : ''}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'management',
      meta: {
        stickyClassName: `sticky ${isCorporativo ? 'left-[24rem]' : 'left-[12rem]'} bg-white z-10 py-0 h-12`,
        headerClassName: `w-[12rem] bg-white z-20 sticky ${isCorporativo ? 'left-[24rem]' : 'left-[12rem]'}`,
      },
      header: '',
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return <div className="-ml-1 size-[104%] bg-white"></div>;
          case 1:
            return <div className="-ml-1 size-[104%] bg-white"></div>;
          case 2:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                GERÊNCIA
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'areaCoordination',
      meta: {
        stickyClassName: `sticky ${isCorporativo ? 'left-[36rem]' : 'left-[24rem]'} bg-white z-10 py-0 h-12`,
        headerClassName: `min-w-[12rem] sticky bg-white z-20 ${isCorporativo ? 'left-[36rem]' : 'left-[24rem]'}`,
      },
      header: '',
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return <div className="-mb-1 h-full bg-white"></div>;
          case 1:
            return <div className="-mb-1 h-full bg-white"></div>;
          case 2:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                COORDENAÇÃO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'workstation',
      meta: {
        stickyClassName: `sticky ${isCorporativo ? 'left-[48rem]' : 'left-[36rem]'} bg-white z-10 py-0 h-12`,
        headerClassName: `min-w-[12rem] sticky ${isCorporativo ? 'left-[48rem]' : 'left-[36rem]'} z-20 bg-white`,
      },
      header: '',
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return <div className="-mb-1 -ml-1 h-full bg-white"></div>;
          case 1:
            return <div className="-mb-1 -ml-1 h-full bg-white"></div>;
          case 2:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                LOCAL DE TRABALHO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'name',
      meta: {
        stickyClassName: `sticky ${isCorporativo ? 'left-[60rem]' : 'left-[48rem]'} bg-white z-10 py-0 h-12`,
        headerClassName: `min-w-[12rem] sticky ${isCorporativo ? 'left-[60rem]' : 'left-[48rem]'} z-20 bg-white`,
      },
      header: () => {
        return (
          <div className="flex h-12 items-center justify-center font-graphie text-[13px]">
            <span>FUNÇÃO / CURSO</span>
          </div>
        );
      },
      cell: (info: any) => {
        switch (info.row.index) {
          case 0:
            return CriticalityRow;
          case 1:
            return TotalWorkloadRow;
          case 2:
            return (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                FUNÇÃO
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
  ];

  const submitDelete = async () => {
    try {
      setIsLoadingTrainings(true);
      const response = await api.delete(`trainings/${selectedTraining?.id}`, {
        params: {
          trainingType: TrainingTypeEnum.PROCEDURE,
        },
      });
      setSelectedTraining(response.data);
      setDeleteConfirmation(true);
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings/${selectedTraining?.id}`,
      });
      throw error;
    } finally {
      setIsLoadingTrainings(false);
      setSelectedTraining(undefined);
      setDeleteConfirmation(false);
      setNeedsToRefetch(!needsToRefetch);
    }
  };

  const handlePositionColumn = () => {
    return positionColumn;
  };

  const columns =
    trainingsMatrixData?.trainings && trainingColumns
      ? [...handlePositionColumn(), ...trainingColumns]
      : [...handlePositionColumn()];

  if (
    !trainingsMatrixData ||
    Object.keys(trainingsMatrixData).length === 0 ||
    Object.keys(trainingsMatrixData.trainings).length === 0
  ) {
    return (
      <TraniningWrapper isLoading={isLoading} isError={isError}>
        <div className="flex h-full w-full rounded-md bg-white p-5">
          {NoTrainings()}
        </div>
        <TrainingModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          matrixRefetch={handleMatrixRefetch}
          trainingData={selectedTraining}
          trainingType={TrainingType.Procedimento}
        />
      </TraniningWrapper>
    );
  }

  const handleLocationName = (location: string) => {
    const lowerCaseLocation = location?.toLowerCase();
    if (lowerCaseLocation === 'tamac') {
      return 'Terminal Aquaviáro de Maceió (TAMAC)';
    }

    return location || '';
  };

  const fetchNextHorizontalPage = () => {
    setSize((prevState) => prevState + 10);
  };

  return (
    <TraniningWrapper isLoading={isLoading} isError={isError}>
      {(isLoading || isLoadingTrainings) && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size={50} />
        </div>
      )}

      <div className="flex h-[90%] w-full flex-col rounded-md bg-white p-5">
        <div>
          <div className="align-center flex w-full font-graphie text-[20px] font-bold text-blue-800">{`${handleLocationName(filteredData?.locations[0]?.toLocaleUpperCase())}`}</div>
          <Line />
        </div>
        <InfiniteTable
          hasNextPage={hasNextPage}
          data={positionsData}
          columns={columns.filter(
            (el, index) => index < Math.min(size, columns.length),
          )}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextHorizontalPage={fetchNextHorizontalPage}
          hasDivider
          isWhiteTable
          shouldFetchNextHorizontalPage
          stickyRows={[0, 1, 2]}
        />
      </div>
      <TrainingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        matrixRefetch={handleMatrixRefetch}
        trainingData={selectedTraining}
        procedureData={selectedProcedure}
        trainingType={TrainingType.Procedimento}
      />

      <ModalConfirmation
        isOpen={deleteConfirmation}
        title="Deseja confirmar a remoção do treinamento para todos os polos?"
        description="Cuidado, esta ação não poderá ser desfeita!"
        confirmAction={submitDelete}
        discartAction={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
        onClose={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
      />

      <DesactivateTrainingModal
        trainingId={desactivateTrainingId}
        isOpen={isDesactivateModalOpen}
        onClose={handleCloseDesactivateModal}
        matrixRefetch={handleMatrixRefetch}
        trainingType={TrainingTypeEnum.PROCEDURE}
      />
    </TraniningWrapper>
  );
};

export default ProcedureTrainingMatrix;
