import { useInfiniteQuery } from '@tanstack/react-query';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ExtractRegulatoryTrainingMatrixButton } from '@/components/atoms/ExtractRegulatoryTraningMatrixButton';
import Cards from '@/components/molecules/Cards';
import ExpandableSubtitle from '@/components/molecules/ExpandableSubtitle';
import PositionTrainingMatrix from '@/components/organisms/PositionTraningMatrix'; // TODO: should be renamed to RegulatoryTrainingMatrix
import { TrainingType } from '@/constants/TrainingPlanning';
import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import {
  PositionTrainingMatrixFilters, // TODO: should be renamed to RegulatoryTrainingMatrixFilters
  positionTrainingMatrixFiltersAtom, // TODO: should be renamed to regulatoryTrainingMatrixFiltersAtom
} from '@/state/PositionTrainingMatrixFilter.atom';
import { PositionTrainingMatrixType } from '@/types/JobTitleFromRmTrainingMatrix'; // TODO: should be renamed to RegulatoryTrainingMatrixType

import { Button } from '../../components/atoms/Button';
import SideFilter from '../../components/molecules/SideFilter';
import TrainingModal from '../../components/organisms/TrainingModal';
import { regulatoryFilters } from '../../constants/filters/JobTrainingMatrixFilter';
import api from '../../services/apiSgft';
import {
  PendingRegulatoryMatrixChanges,
  RegulatoryIndicators,
  RegulatoryTrainingMatrix,
  ShortTraining,
} from '../../types/Training';
import {
  userCanExtractRegulatoryTraining,
  userCanSaveRegulatoryTraining,
} from '../../utils/handleSavePermissions';

const RegulatoryTrainingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilteredValues] = useRecoilState(
    positionTrainingMatrixFiltersAtom,
  );
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<RegulatoryIndicators>();
  const [matrixRefetch, setMatrixRefetch] = useState(false);
  const [isNewTrainingModalOpen, setIsNewTraininigModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [pendingMatrixChanges, setPendingMatrixChanges] =
    useState<PendingRegulatoryMatrixChanges>({});
  const [pendingBlockMatrixChanges, setPendingBlockMatrixChanges] =
    useState<PendingRegulatoryMatrixChanges>({});
  const [isPositionFilteringIndicator, setIsPositionFilteringIndicator] =
    useState(false);
  const [isTrainingFilteringIndicator, setIsTrainingFilteringIndicator] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const trainingType = searchParams.get('tipo');
    const location = searchParams.get('polo');
    const training = searchParams.get('treinamento');
    const position = searchParams.get('funcao');

    setFilteredValues((prev) => ({
      ...prev,
      trainingType: trainingType ? [trainingType] : prev.trainingType,
      location: location ? [location] : prev.location,
      training: training ? [training] : prev.training,
      position: position ? [position] : prev.position,
    }));

    setFilteredData((prev) => ({
      ...prev,
      trainingType: trainingType ? [trainingType] : prev.trainingType,
      location: location ? [location] : prev.location,
      training: training ? [training] : prev.training,
      position: position ? [position] : prev.position,
    }));

    searchParams.delete('tipo');
    searchParams.delete('polo');
    searchParams.delete('treinamento');
    searchParams.delete('funcao');
    setSearchParams(searchParams);
  }, []);

  const filtersData = useRecoilValue(positionTrainingMatrixFiltersAtom);

  const [modifiedCheckboxesMap, setModifiedCheckboxesMap] = useState<
    Map<string, boolean>
  >(new Map());

  const [modifiedBlockCheckboxesMap, setModifiedBlockCheckboxesMap] = useState<
    Map<string, boolean>
  >(new Map());

  const toggleEditMode = () => {
    if (isEditMode && Object.keys(pendingMatrixChanges).length > 0) {
      handleSendMappingChanges();
    }
    if (isEditMode && Object.keys(pendingBlockMatrixChanges).length > 0) {
      handleSendBlockMappingChanges();
    }
    setIsEditMode(!isEditMode);
  };

  const fetchTrainingMatrix = async ({
    pageParam = 1,
  }): Promise<{
    trainingMatrix: RegulatoryTrainingMatrix;
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(
        `position-regulatory-training-link/matrix?size=20&page=${pageParam}`,
        {
          params: {
            positions: filteredData.position,
            locations: filteredData.location,
            // TODO: Remove trainingType from queries and filter
            trainingType: [TrainingTypeEnum.REGULATORY],
            management: filteredData.management,
            areaCoordination: filteredData.areaCoordination,
            trainings: filteredData.training,
            onlyUnmappedPositions: isPositionFilteringIndicator,
            onlyUnmappedTrainings: isTrainingFilteringIndicator,
          },
        },
      );
      return response.data;
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-${'position-regulatory-training-link/matrix'}`,
      });
      throw error;
    }
  };

  const {
    data: trainingsMatrixData,
    isLoading: isLoadingTrainingsMatrix,
    isError: isErrorTrainingsMatrix,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['trainings-matrix', filteredData, matrixRefetch],
    fetchTrainingMatrix,
    {
      retry: false,
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    },
  );

  const trainingsData = trainingsMatrixData?.pages
    .flatMap((page) => page.trainingMatrix)
    .reduce(
      (acc, curr) => {
        const validIndexes = curr.positions
          .map((position, index) =>
            position.areaCoordination !== null &&
            position.management !== null &&
            position.name !== null
              ? index
              : -1,
          )
          .filter((index) => index !== -1);

        curr.positions = validIndexes.map((index) => curr.positions[index]);
        curr.matrix = validIndexes.map((index) => curr.matrix[index]);
        curr.blockMatrix = validIndexes.map((index) => curr.blockMatrix[index]);

        acc.positions.push(...curr.positions);
        const trainingSet = new Set<ShortTraining>([...curr.trainings]);
        acc.trainings = [...trainingSet];
        acc.matrix.push(...curr.matrix);
        acc.blockMatrix.push(...curr.blockMatrix);

        return acc;
      },
      {
        positions: [],
        trainings: [],
        matrix: [],
        blockMatrix: [],
      },
    );

  const isLoading = isLoadingTrainingsMatrix;
  const isError = isErrorTrainingsMatrix;

  async function fetchIndicators(filters: PositionTrainingMatrixFilters) {
    try {
      const response = await api.get('trainings/regulatory-indicators', {
        params: {
          locations: filters.location,
          trainings: filters.training,
          positions: filters.position,
          // TODO: remove training type
          trainingType: [TrainingTypeEnum.REGULATORY],
          management: filters.management,
          areaCoordination: filters.areaCoordination,
        },
      });
      setIndicators(response.data);
    } catch (e) {
      toast.error('Erro ao carregar indicadores', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        trainingType: [TrainingTypeEnum.REGULATORY],
        location: [],
        position: [],
        training: [],
        management: [],
        areaCoordination: [],
        workstation: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
      fetchIndicators(filterValues);
    }
  };

  const handleMatrixRefetch = async () => {
    setMatrixRefetch((prevState) => !prevState);
  };

  const handleClickPositionsPendencies = async () => {
    if (indicators?.unmappedPositions) {
      if (isPositionFilteringIndicator) {
        setIsPositionFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          position: [],
        }));
        handleMatrixRefetch();
      } else {
        setIsPositionFilteringIndicator(true);
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          training: [],
        }));
        handleMatrixRefetch();
      }
    }
  };

  const handleClickTrainingsPendencies = async () => {
    if (indicators?.unmappedTrainings) {
      if (isTrainingFilteringIndicator) {
        setIsTrainingFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          training: [],
        }));
        handleMatrixRefetch();
      } else {
        setIsTrainingFilteringIndicator(true);
        setIsPositionFilteringIndicator(false);
        setFilteredData((prevFilters) => ({
          ...prevFilters,
          position: [],
        }));
        handleMatrixRefetch();
      }
    }
  };

  const handleMappingChange = (
    trainingId: number,
    positionId: number,
    checked: boolean,
  ) => {
    const key = `${positionId}-${trainingId}`;
    setModifiedCheckboxesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.set(key, !prevState.get(key));
      return newMap;
    });

    setPendingMatrixChanges((prevState) => {
      // Ensure that the nested structure exists
      prevState[positionId] = prevState[positionId] || {};
      prevState[positionId][trainingId] = prevState[positionId][trainingId] || {
        initialState: !checked,
      };

      // Check if the current state matches the initial state
      if (checked === prevState[positionId][trainingId].initialState) {
        // Remove the specific trainingId entry
        delete prevState[positionId][trainingId];

        // If the positionId no longer has any trainingId entries, remove it
        if (Object.keys(prevState[positionId]).length === 0) {
          delete prevState[positionId];
        }

        return prevState;
      }

      // If the state does not match the initial state, update the checked property
      prevState[positionId][trainingId].checked = checked;

      // Return the updated state
      return prevState;
    });
  };

  const handleBlockMappingChange = (
    trainingId: number,
    positionId: number,
    checked: boolean,
  ) => {
    const key = `${positionId}-${trainingId}`;
    setModifiedBlockCheckboxesMap((prevState) => {
      const newMap = new Map(prevState);
      newMap.set(key, !prevState.get(key));
      return newMap;
    });

    setPendingBlockMatrixChanges((prevState) => {
      // Ensure that the nested structure exists
      prevState[positionId] = prevState[positionId] || {};
      prevState[positionId][trainingId] = prevState[positionId][trainingId] || {
        initialState: !checked,
      };

      // Check if the current state matches the initial state
      if (checked === prevState[positionId][trainingId].initialState) {
        // Remove the specific trainingId entry
        delete prevState[positionId][trainingId];

        // If the positionId no longer has any trainingId entries, remove it
        if (Object.keys(prevState[positionId]).length === 0) {
          delete prevState[positionId];
        }

        return prevState;
      }

      // If the state does not match the initial state, update the checked property
      prevState[positionId][trainingId].checked = checked;

      // Return the updated state
      return prevState;
    });
  };

  const handleMapAllPositions = async (trainingId: number) => {
    if (!trainingsData) return;

    try {
      const data = await fetchPositionFromTraining();
      const positions = data.positions;

      const allToggled = positions.every((position) => {
        const positionId = position.id;
        const key = `${positionId}-${trainingId}`;
        return modifiedCheckboxesMap.get(key);
      });

      positions.forEach((position) => {
        const positionId = position.id;
        const key = `${positionId}-${trainingId}`;
        const isChecked = modifiedCheckboxesMap.get(key);

        if (allToggled || !isChecked) {
          handleMappingChange(trainingId, positionId, !allToggled);
        }
      });
    } catch (error) {
      toast.error('Erro ao mapear todas as funções', {
        theme: 'colored',
        toastId: `error-${'job-title-from-rm-training-link/matrix'}`,
      });
    }
  };

  const fetchPositionFromTraining = async (): Promise<{
    count: number;
    nextPage: number | undefined;
    positions: PositionTrainingMatrixType[];
  }> => {
    try {
      const response = await api.get('position/', {
        params: {
          positions: filteredData.position,
          locations: filteredData.location,
          management: filteredData.management,
          areaCoordination: filteredData.areaCoordination,
          workstation: filteredData.workstation,
          getAll: true,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSendMappingChanges = async () => {
    const previousMap = new Map(modifiedCheckboxesMap);

    try {
      const changes = Object.entries(pendingMatrixChanges)[0][1];

      for (const positionId in changes) {
        for (const trainingId in changes[positionId]) {
          const newKey = `${positionId}-${trainingId}`;
          const newValue = changes[positionId][trainingId].checked;

          setModifiedCheckboxesMap((prevState) => {
            const newMap = new Map(prevState);
            newMap.set(newKey, newValue);
            return newMap;
          });
        }
      }

      const response = await api.post(
        '/position-regulatory-training-link',
        pendingMatrixChanges,
      );

      if (response.status !== 201) {
        throw new Error('Network response was not ok');
      }

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (error) {
      if (isAxiosError(error)) {
        if (
          error.response?.status === HttpStatusCode.BadRequest &&
          Array.isArray(error.response.data.message)
        ) {
          const errors: {
            trainingId: number;
            positionId: number;
            message: string;
          }[] = error.response.data.message;

          for (const mappingError of errors) {
            const { positionId, trainingId, message } = mappingError;

            const key = `${positionId}-${trainingId}`;
            setModifiedCheckboxesMap((prevState) => {
              const newMap = new Map(prevState);
              newMap.set(key, previousMap.get(key) || false);
              return newMap;
            });

            toast.error(`Erro ao salvar mapeamento: ${message}`, {
              theme: 'colored',
              toastId: `error-trainings-${key}`,
            });
          }
        } else {
          setModifiedCheckboxesMap(previousMap);
          toast.error(
            `Não foi possível salvar o mapeamento: ${error.response?.data.message}`,
            {
              theme: 'colored',
              toastId: `error-trainings`,
            },
          );
        }
      }

      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings`,
      });
      throw error;
    }

    setPendingMatrixChanges({});
  };

  const handleSendBlockMappingChanges = async () => {
    const previousMap = new Map(modifiedBlockCheckboxesMap);

    try {
      const changes = Object.entries(pendingBlockMatrixChanges)[0][1];

      for (const positionId in changes) {
        for (const trainingId in changes[positionId]) {
          const newKey = `${positionId}-${trainingId}`;
          const newValue = changes[positionId][trainingId].checked;

          setModifiedBlockCheckboxesMap((prevState) => {
            const newMap = new Map(prevState);
            newMap.set(newKey, newValue);
            return newMap;
          });
        }
      }

      const response = await api.post(
        '/position-regulatory-training-link/block',
        pendingBlockMatrixChanges,
      );

      if (response.status !== 201) {
        throw new Error('Network response was not ok');
      }

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (error) {
      if (isAxiosError(error)) {
        if (
          error.response?.status === HttpStatusCode.BadRequest &&
          Array.isArray(error.response.data.message)
        ) {
          const errors: {
            trainingId: number;
            positionId: number;
            message: string;
          }[] = error.response.data.message;

          for (const mappingError of errors) {
            const { positionId, trainingId, message } = mappingError;

            const key = `${positionId}-${trainingId}`;
            setModifiedBlockCheckboxesMap((prevState) => {
              const newMap = new Map(prevState);
              newMap.set(key, previousMap.get(key) || false);
              return newMap;
            });

            toast.error(`Erro ao salvar bloqueio: ${message}`, {
              theme: 'colored',
              toastId: `error-trainings-${key}`,
            });
          }
        } else {
          setModifiedBlockCheckboxesMap(previousMap);
          toast.error(
            `Não foi possível salvar o bloqueio: ${error.response?.data.message}`,
            {
              theme: 'colored',
              toastId: `error-trainings`,
            },
          );
        }
      }

      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings`,
      });
      throw error;
    }

    setPendingBlockMatrixChanges({});
  };

  const canSave: boolean | undefined = userCanSaveRegulatoryTraining();
  const canExtract: boolean | undefined = userCanExtractRegulatoryTraining();

  return (
    <div
      className="relative flex w-full flex-col items-start"
      style={{ height: 'calc(100% - 40px)' }}
    >
      <div className="absolute -top-10 right-20 flex items-center">
        <Button
          className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
          onClick={() => setIsNewTraininigModalOpen(true)}
          disabled={!canSave}
        >
          Cadastrar Treinamento
        </Button>
        {trainingsData && trainingsData.trainings?.length > 0 && (
          <Button
            className="mx-5 h-7 rounded-[1rem] text-xs font-medium"
            onClick={toggleEditMode}
            disabled={
              !canSave &&
              !trainingsData.trainings.some((it) => it.isResponsible)
            }
          >
            {isEditMode ? 'Salvar' : 'Editar'} Matriz
          </Button>
        )}
        {canExtract && (
          <ExtractRegulatoryTrainingMatrixButton filteredData={filteredData} />
        )}
      </div>
      <SideFilter
        refetchOnChange
        filters={regulatoryFilters}
        atom={positionTrainingMatrixFiltersAtom}
        applyChanges={handleApplyFilter}
        disabled={filtersData.location.length === 0}
      />
      <div className="w-[92vw] pt-4">
        {indicators &&
          indicators.totalTrainings !== undefined &&
          indicators.totalTrainings !== null && (
            <ExpandableSubtitle subtitle={'Indicadores'}>
              <Cards
                cards={[
                  {
                    value: indicators.totalTrainings,
                    status: 'green',
                    title: 'Treinamentos Cadastrados',
                    width: '16rem',
                  },
                  {
                    value: indicators.unmappedPositions,
                    status: 'red',
                    title: 'Funções não mapeadas',
                    onClick: handleClickPositionsPendencies,
                    width: '16rem',
                    borderColor: isPositionFilteringIndicator
                      ? 'red'
                      : undefined,
                  },
                  {
                    value: indicators.unmappedTrainings,
                    status: 'red',
                    title: 'Treinamentos não mapeados',
                    onClick: handleClickTrainingsPendencies,
                    width: '16rem',
                    borderColor: isTrainingFilteringIndicator
                      ? 'red'
                      : undefined,
                  },
                ]}
              />
            </ExpandableSubtitle>
          )}
      </div>
      <PositionTrainingMatrix
        trainingsMatrixData={trainingsData}
        isLoading={isLoading}
        isError={isError}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        filteredData={filteredData}
        needsToRefetch={matrixRefetch}
        setNeedsToRefetch={setMatrixRefetch}
        handleMatrixRefetch={handleMatrixRefetch}
        isModalOpen={isNewTrainingModalOpen}
        setIsModalOpen={setIsNewTraininigModalOpen}
        canSave={canSave}
        isEditMode={isEditMode}
        modifiedCheckboxes={modifiedCheckboxesMap}
        onMappingChange={handleMappingChange}
        handleMapAllPositions={handleMapAllPositions}
        modifiedBlockCheckboxes={modifiedBlockCheckboxesMap}
        onBlockMappingChange={handleBlockMappingChange}
      />
      <TrainingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        matrixRefetch={handleMatrixRefetch}
        trainingType={TrainingType.Regulatorio}
      />
    </div>
  );
};

export default RegulatoryTrainingPage;
