import { Box } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useDebounce } from 'use-debounce';

import { JobTitleLine } from '@/components/atoms/JobTitleLine';
import Spinner from '@/components/atoms/Spinner';
import api from '@/services/apiSgft';
import { jobTitleAtom } from '@/state/JobTitle.atom';
import { GroupedJobTitle, JobTitle, JobTitleStatus } from '@/types/JobTitle';

import DownloadIcon from '../../../assets/icons/download.svg';
import EmptyIcon from '../../../assets/icons/empty.svg';
import { JobTitleAccordion } from '../JobTitleAccordion/JobTitleAccordion';

interface JobTitlesWrapperProps {
  jobTitleStatus: JobTitleStatus;
  count?: number;
}

function JobTitlesWrapper({ jobTitleStatus, count }: JobTitlesWrapperProps) {
  const filterValues = useRecoilValue(jobTitleAtom);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState<number[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [exportSelectedLoading, setExportSelectedLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 500);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const observerRef = useRef<HTMLDivElement | null>(null); // Ref para o observer do último elemento

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleExportClick = () => {
    setShowExportOptions((prev) => !prev);
  };

  const handleExportSelected = async () => {
    setExportSelectedLoading(true);
    const payload = {
      jobTitleIds: selectedJobTitleIds,
      allActiveJobs: selectAllChecked,
    };
    try {
      const response = await api.post(
        'job-title/multiple-pdf-generation',
        payload,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'JobDescriptions.zip';
      link.click();
      window.URL.revokeObjectURL(url);

      toast.success('PDFs exportados com sucesso!', { theme: 'colored' });
    } catch (error) {
      toast.error('Erro ao exportar os PDFs. Tente novamente.', {
        theme: 'colored',
      });
      console.error(error);
    } finally {
      setExportSelectedLoading(false);
    }
  };

  const handleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    if (selectAllChecked) {
      setSelectedJobTitleIds([]);
    } else {
      const allJobTitleIds = groupedJobTitles.flatMap((group) =>
        group.jobTitles.map((jobTitle) => jobTitle.id),
      );
      setSelectedJobTitleIds(allJobTitleIds);
    }
  };
  const fetchJobTitles = async ({
    pageParam = 1,
  }): Promise<{
    data: GroupedJobTitle[];
    nextPage: number | undefined;
    hasMore: boolean;
  }> => {
    try {
      const response = await api.get('/job-title/grouped', {
        params: {
          title: searchTerm,
          seniorityLevels: filterValues?.seniorityLevels,
          companies: filterValues?.companies,
          boards: filterValues?.boards,
          managements: filterValues?.managements,
          workStations: filterValues?.workStations,
          status: jobTitleStatus,
          page: pageParam,
        },
      });

      const objResults = response.data;
      const hasMore = objResults.length > 0;

      return {
        data: objResults,
        nextPage: hasMore ? pageParam + 1 : undefined,
        hasMore,
      };
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['jobTitle', filterValues, searchTermDebounced, jobTitleStatus],
      fetchJobTitles,
      {
        getNextPageParam: (lastPage) => lastPage.nextPage,
      },
    );

  const groupedJobTitles = data?.pages.flatMap((page) => page.data) || [];
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 1.0,
    });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [observerCallback]);

  return (
    <div>
      <div className="my-2 mb-1 flex w-full items-center justify-between bg-white p-2">
        {showExportOptions && jobTitleStatus === 'active' && (
          <div className="flex items-center pr-4">
            <label className="mr-4 flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4 align-middle"
                onChange={handleSelectAll}
                checked={
                  selectedJobTitleIds.length ===
                  groupedJobTitles.flatMap((group) =>
                    group.jobTitles.map((jobTitle) => jobTitle.id),
                  ).length
                }
              />
              <span className="text-sm font-bold text-primary">
                Selecionar todos
              </span>
            </label>
            <span className="mr-4 text-sm font-bold text-primary">
              Cargos Selecionados:
              <Box
                component="span"
                className="ml-1 rounded bg-[#CAD5FF] px-1 text-primary"
              >
                <span className="text-sm font-normal">
                  {selectAllChecked ? count : selectedJobTitleIds.length}
                </span>
              </Box>
            </span>
            <button
              type="button"
              className={`rounded border-2 border-[#193CB9] bg-primary px-3 py-0.5 text-sm font-bold text-white hover:bg-primary/90 ${
                exportSelectedLoading ? 'cursor-not-allowed opacity-50' : ''
              }`}
              onClick={handleExportSelected}
              disabled={exportSelectedLoading}
            >
              {exportSelectedLoading ? (
                <Spinner size={20} color="white" />
              ) : (
                'Exportar selecionados'
              )}
            </button>
          </div>
        )}

        <div className="ml-auto flex items-center">
          <div className="flex h-[2rem] w-[18rem] items-center rounded-lg bg-background px-3 text-[12px]">
            <input
              className="h-full w-full rounded-lg bg-background px-3 text-[12px] focus:outline-none"
              type="text"
              placeholder="Pesquisar por cargo"
              value={searchTerm}
              onChange={handleOnchange}
            />
            <IoIosSearch
              className="ml-2 cursor-pointer"
              color="#193db8"
              size={'16px'}
            />
          </div>

          {jobTitleStatus === 'active' && (
            <button
              type="button"
              className="ml-2 flex h-[2rem] items-center justify-center rounded-full border-2 border-[#193CB9] bg-white px-3 text-[12px] text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={handleExportClick}
            >
              {!showExportOptions && (
                <img
                  src={DownloadIcon}
                  alt="Ícone de download"
                  className="mr-2 w-[15px] flex-none"
                />
              )}
              {showExportOptions ? 'Cancelar' : 'Exportar'}
            </button>
          )}
        </div>
      </div>

      {groupedJobTitles && groupedJobTitles.length > 0 ? (
        <div className="mt-[10px] flex h-full flex-col items-start gap-[10px]">
          {groupedJobTitles.map((groupedJobTitle) => (
            <JobTitleAccordion
              subJobTitleIds={groupedJobTitle.jobTitles.map(
                (jobTitle) => jobTitle.id,
              )}
              jobTitleStatus={jobTitleStatus}
              selectedJobTitleIds={selectedJobTitleIds}
              setSelectedJobTitleIds={setSelectedJobTitleIds}
              selectedAllChecked={selectAllChecked}
              setSelectedAllChecked={setSelectAllChecked}
              key={groupedJobTitle.id}
              groupedJobTitle={groupedJobTitle}
              exportChecked={showExportOptions && jobTitleStatus === 'active'}
            >
              {groupedJobTitle.jobTitles.map((jobTitle: JobTitle) => {
                return (
                  <div key={jobTitle.id} className="relative w-full">
                    <JobTitleLine
                      jobTitle={jobTitle}
                      exportChecked={
                        showExportOptions && jobTitleStatus === 'active'
                      }
                      selectedJobTitleIds={selectedJobTitleIds}
                      setSelectedJobTitleIds={setSelectedJobTitleIds}
                      selectedAllChecked={selectAllChecked}
                      setSelectedAllChecked={setSelectAllChecked}
                    />
                  </div>
                );
              })}
            </JobTitleAccordion>
          ))}

          <div ref={observerRef} className="block h-2 w-full ">
            &nbsp;
          </div>
          {isLoading && (
            <div className="flex w-full justify-center p-4">
              <Spinner />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-[10px] flex w-full flex-col items-center justify-center rounded-md bg-white pb-8">
          <img src={EmptyIcon} className="w-40" />
          <div className="w-full text-center text-sm">
            <p>Nenhum cargo cadastrado com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Cadastre um ou altere os valores dos filtros para visualizar os
              cargos!
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobTitlesWrapper;
