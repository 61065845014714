import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

import { TurnstileAccessType } from '@/constants/TurnstileAccessConstants';

export interface TurnstileAccessFilterAtom {
  type: TurnstileAccessType;
  employee: string[];
  training: string[];
  jobTitle: string[];
  coordination: string[];
  management: string[];
  workstation: string[];
  board: string[];
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

export const turnstileAccessFilterAtom = atom<TurnstileAccessFilterAtom>({
  key: 'turnstileAccessFilterAtom',
  default: {
    type: TurnstileAccessType.Blocked,
    employee: [],
    training: [],
    jobTitle: [],
    coordination: [],
    management: [],
    workstation: [],
    board: [],
  },
  effects: [
    urlSyncEffect<TurnstileAccessFilterAtom>({
      refine: r.object({
        type: r.custom((value) => {
          if (value === TurnstileAccessType.Blocked) {
            return value;
          }
          return TurnstileAccessType.Blocked;
        }),
        employee: stringArrayChecker,
        training: stringArrayChecker,
        jobTitle: stringArrayChecker,
        coordination: stringArrayChecker,
        management: stringArrayChecker,
        workstation: stringArrayChecker,
        board: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
