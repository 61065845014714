import { NewFilter } from '@/types/NewFilter';

export const filters: NewFilter[] = [
  {
    key: 'employee',
    name: 'Colaborador',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'board',
    name: 'Direção',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'coordination',
    name: 'Coordenação',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'jobTitle',
    name: 'Cargo',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'workstation',
    name: 'Local de Trabalho',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'training',
    name: 'Treinamento',
    isMulti: true,
    isDisabled: () => false,
  },
];
